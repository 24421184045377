import React from 'react';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { blue, green, yellow, red } from '@material-ui/core/colors';

import NotificationHistoryDialog from './NotificationHistoryDialog';

import { stateSelectors } from '../../store/entities/state';
import Accreditation from '../../store/entities/accreditation/type';
import Notification from '../../store/entities/notification/type';
import Seat from '../../store/entities/seat/type';
import State from '../../store/entities/state/type';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '400px',
        },
        variableWidthContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        sent: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: theme.palette.getContrastText(green[600]),
            backgroundColor: green[600],
            marginRight: '4px',
            '&:hover': {
                backgroundColor: green[800],
            },
        },
        pending: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: theme.palette.getContrastText(yellow[700]),
            backgroundColor: yellow[700],
            marginRight: '4px',
            '&:hover': {
                backgroundColor: yellow[900],
            },
        },
        error: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: theme.palette.getContrastText(red[600]),
            backgroundColor: red[600],
            marginRight: '4px',
            '&:hover': {
                backgroundColor: red[800],
            },
        },
        active: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: theme.palette.getContrastText(blue.A100),
            backgroundColor: blue.A100,
            marginRight: '4px',
        },
        inactive: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            marginRight: '4px',
        },
        fontMed: {
            fontSize: '14px',
        },
    }),
);

function makeAssociations(
    accreditations: Accreditation[],
    stateList: State[],
    notifications: Notification[] = [],
) {
    return accreditations
        .map(({ code, state_id }) => ({
            code,
            notifications: notifications.filter((n) => n.state_id === state_id),
            state: stateList.find((s) => s.id === state_id) || ({} as State),
        }))
        .filter((a) => a.state);
}

export interface StateChipsProps extends React.HTMLProps<HTMLDivElement> {
    accreditations: Accreditation[];
}

export interface NotificationStateChipsProps extends StateChipsProps {
    notifications: Notification[];
}

export function NotificationStateChips({
    accreditations,
    notifications,
    ...props
}: NotificationStateChipsProps) {
    const styleClasses = useStyles();
    const { className, ...attrs } = props;
    const stateList = useSelector(stateSelectors.selectAll);
    const [notificationDialog, setNotificationDialog] = React.useState(false);
    const [notificationHistory, setNotificationHistory] = React.useState(
        [] as Notification[],
    );

    const associations = makeAssociations(
        accreditations,
        stateList,
        notifications,
    );
    const stateChips: JSX.Element[] = [];
    associations.forEach((a) => {
        const statuses = a.notifications.map((n) => n.status);
        let stateChip: JSX.Element;
        if (statuses.includes('SUCCESS')) {
            stateChip = (
                <Avatar
                    key={`state-${a.code}-${a.state.abbreviation}`}
                    alt={a.state.name}
                    className={styleClasses.sent}
                >
                    <span className={styleClasses.fontMed}>
                        {a.state.abbreviation}
                    </span>
                </Avatar>
            );
        } else {
            stateChip = (
                <Avatar
                    key={`state-${a.code}-${a.state.abbreviation}`}
                    alt={a.state.name}
                    className={styleClasses.pending}
                >
                    <span className={styleClasses.fontMed}>
                        {a.state.abbreviation}
                    </span>
                </Avatar>
            );
        }

        stateChips.push(
            <IconButton
                size="small"
                key={`notif-info-${a.state.abbreviation}-${a.code}`}
                onClick={() => {
                    setNotificationHistory(a.notifications);
                    setNotificationDialog(true);
                }}
            >
                {stateChip}
            </IconButton>,
        );
    });
    return (
        <div {...attrs} className={styleClasses.container}>
            <NotificationHistoryDialog
                open={notificationDialog}
                setOpen={setNotificationDialog}
                notifications={notificationHistory}
                onConfirm={() => {
                    setNotificationHistory([]);
                }}
            />
            {stateChips}
        </div>
    );
}

export interface ActiveStateChipsProps extends StateChipsProps {
    activeStates: number[];
    group?: boolean;
}

export function ActiveStateChips({
    accreditations,
    activeStates,
    group,
    ...props
}: ActiveStateChipsProps) {
    const styleClasses = useStyles();
    const { className, ...attrs } = props;
    const stateList = useSelector(stateSelectors.selectAll);

    // Add EPA to all state certification options for students
    const epaState = stateList.find(
        ({ abbreviation }) => abbreviation.toUpperCase() === 'EPA',
    );
    const availableAccreds = epaState
        ? [...accreditations, { state_id: epaState.id, code: '' }]
        : accreditations;

    const associations = makeAssociations(availableAccreds, stateList);
    const stateChips: JSX.Element[] = [];
    associations.forEach((a) => {
        const active = activeStates.includes(a.state.id);
        const chipColorStyle = active
            ? styleClasses.active
            : styleClasses.inactive;
        stateChips.push(
            <Avatar
                key={`state-active-${a.code}-${a.state.abbreviation}`}
                alt={a.state.name}
                className={chipColorStyle}
            >
                <span className={styleClasses.fontMed}>
                    {a.state.abbreviation}
                </span>
            </Avatar>,
        );
    });
    return stateChips.length > 6 && group ? (
        <div {...attrs} className={styleClasses.variableWidthContainer}>
            <AvatarGroup max={20}>{stateChips}</AvatarGroup>
        </div>
    ) : (
        <div {...attrs} className={styleClasses.variableWidthContainer}>
            {stateChips}
        </div>
    );
}

ActiveStateChips.defaultProps = {
    group: false,
};

export interface ToggleStateChipsProps extends StateChipsProps {
    seatsInfo: Seat[];
    setSeatsInfo: React.Dispatch<React.SetStateAction<Seat[]>>;
    seatIndex: number;
}

export function ToggleStateChips({
    accreditations,
    seatsInfo,
    setSeatsInfo,
    seatIndex,
    ...props
}: ToggleStateChipsProps) {
    const styleClasses = useStyles();
    const { className, ...attrs } = props;
    const stateList = useSelector(stateSelectors.selectAll);

    // Add EPA to all state certification options for students
    const epaState = stateList.find(
        ({ abbreviation }) => abbreviation.toUpperCase() === 'EPA',
    );
    const availableAccreds = epaState
        ? [...accreditations, { state_id: epaState.id, code: '' }]
        : accreditations;

    const associations = makeAssociations(availableAccreds, stateList);
    const stateChips: JSX.Element[] = [];
    associations.forEach((a) => {
        const active = seatsInfo[seatIndex].states.findIndex(
            (s) => s.id === a.state.id,
        );
        const chipColorStyle =
            active >= 0 ? styleClasses.active : styleClasses.inactive;
        stateChips.push(
            <IconButton
                size="small"
                key={`state-toggle-${a.code}-${a.state.abbreviation}`}
                onClick={() => {
                    if (active >= 0) {
                        const newSeats = [...seatsInfo];
                        newSeats[seatIndex].states.splice(active, 1);
                        setSeatsInfo(newSeats);
                    } else {
                        const newSeats = [...seatsInfo];
                        newSeats[seatIndex].states.push(a.state);
                        setSeatsInfo(newSeats);
                    }
                }}
            >
                <Avatar alt={a.state.name} className={chipColorStyle}>
                    <span className={styleClasses.fontMed}>
                        {a.state.abbreviation}
                    </span>
                </Avatar>
            </IconButton>,
        );
    });
    return (
        <div {...attrs} className={styleClasses.container}>
            {stateChips}
        </div>
    );
}
