import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AddAddressDialog from '../../AddAddressDialog';
import { HybridSession } from '../utils';

import Location from '../../../store/entities/location/type';
import { locationSelectors } from '../../../store/entities/location';
import { stateSelectors } from '../../../store/entities/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        locationTitle: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        locationDelete: {
            'margin-right': theme.spacing(1),
        },
        hiddenButton: {
            opacity: 0,
            color: 'transparent',
        },
        newAddressButton: {
            'margin-top': theme.spacing(3),
            'margin-left': theme.spacing(3),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    sessionIndex: number;
    sessionsInfo: HybridSession[];
    setSessionsInfo: React.Dispatch<React.SetStateAction<HybridSession[]>>;
}

export default function FormTrainingLocation({
    sessionIndex,
    sessionsInfo,
    setSessionsInfo,
    className,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const stateList = useSelector(stateSelectors.selectAll);
    const defaultState = {
        label: 'All States',
        id: 'All',
    };
    const locationId = sessionsInfo[sessionIndex].session.location_id;
    const [stateChoice, setStateChoice] = useState(defaultState);
    const dbLocations = useSelector(locationSelectors.selectAll);
    const [locationOptions, setLocationOptions] = useState(
        dbLocations.filter(
            (loc) => !loc.addressee.toUpperCase().includes('ZOOM.US'),
        ),
    );
    const location = useSelector((state) =>
        locationSelectors.selectById(state, locationId || 0),
    );
    const statesWithLocations = dbLocations.map((loc) => loc.state);
    const stateSelectorOptions = stateList
        .filter(
            ({ is_state, abbreviation }) =>
                is_state && statesWithLocations.includes(abbreviation),
        )
        .map(({ name, abbreviation }) => ({
            label: name,
            id: abbreviation,
        }));

    const handleConfirmLocation = (event: any) => {
        const newSessionsInfo = [...sessionsInfo];
        newSessionsInfo[sessionIndex].session.location_confirmed =
            event.target.checked;
        setSessionsInfo(newSessionsInfo);
    };

    const handleWebinar = (event: any) => {
        const newSessionsInfo = [...sessionsInfo];
        newSessionsInfo[sessionIndex].webinar = event.target.checked;
        setSessionsInfo(newSessionsInfo);
    };

    const physicalLocationParts = (
        <>
            <Grid item xs={12}>
                <TextField
                    id="locationName"
                    label="Location Name"
                    value={location?.addressee || ''}
                    helperText=""
                    fullWidth
                    disabled={!location}
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="locationAddress"
                    label="Street Address"
                    value={location?.address_1 || ''}
                    helperText=""
                    fullWidth
                    disabled={!location}
                    margin="dense"
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    id="locationCity"
                    label="City"
                    value={location?.city || ''}
                    helperText=""
                    fullWidth
                    disabled={!location}
                    margin="dense"
                />
            </Grid>
            <Grid item sm={2} xs={4}>
                <TextField
                    id="locationState"
                    label="State"
                    value={location?.state || ''}
                    helperText=""
                    fullWidth
                    disabled={!location}
                    margin="dense"
                />
            </Grid>
            <Grid item sm={4} xs={8}>
                <TextField
                    id="locationZipCode"
                    label="Zip Code"
                    value={location?.zip_code || ''}
                    helperText=""
                    fullWidth
                    disabled={!location}
                    margin="dense"
                />
            </Grid>
        </>
    );

    const onlineLocationParts = (
        <>
            <Grid item xs={12}>
                <TextField
                    id="webinarUrl"
                    label="Online Location"
                    value={sessionsInfo[sessionIndex].webinarUrl}
                    onChange={(event) => {
                        const newSessionsInfo = [...sessionsInfo];
                        newSessionsInfo[sessionIndex].webinarUrl =
                            event.target.value;
                        setSessionsInfo(newSessionsInfo);
                    }}
                    helperText=""
                    fullWidth
                    disabled={!sessionsInfo[sessionIndex].webinar}
                    margin="dense"
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    id="webinarMeetingId"
                    label="Meeting ID"
                    value={sessionsInfo[sessionIndex].webinarMeetingId}
                    onChange={(event) => {
                        const newSessionsInfo = [...sessionsInfo];
                        newSessionsInfo[sessionIndex].webinarMeetingId =
                            event.target.value;
                        setSessionsInfo(newSessionsInfo);
                    }}
                    helperText=""
                    fullWidth
                    disabled={!sessionsInfo[sessionIndex].webinar}
                    margin="dense"
                />
            </Grid>
            <Grid item sm={2} xs={4}>
                <TextField
                    id="webinarPasscode"
                    label="Passcode"
                    value={sessionsInfo[sessionIndex].webinarPasscode}
                    onChange={(event) => {
                        const newSessionsInfo = [...sessionsInfo];
                        newSessionsInfo[sessionIndex].webinarPasscode =
                            event.target.value;
                        setSessionsInfo(newSessionsInfo);
                    }}
                    helperText=""
                    fullWidth
                    disabled={!sessionsInfo[sessionIndex].webinar}
                    margin="dense"
                />
            </Grid>
        </>
    );

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <form autoComplete="off">
                <Grid container className={styleClasses.titleContainer}>
                    <Grid item className={styleClasses.title}>
                        <Typography variant="h6" component="h2">
                            Training Location
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <Autocomplete
                            id="usaStateAutocomplete"
                            options={stateSelectorOptions}
                            getOptionLabel={(option: any) =>
                                `${option.label} (${option.id})`
                            }
                            getOptionSelected={(option: any) =>
                                option.id === location?.state
                            }
                            value={stateChoice}
                            onChange={(event: any, newValue: any) => {
                                const filteredLocations = dbLocations.filter(
                                    (loc: Location) =>
                                        newValue
                                            ? loc.state === newValue.id
                                            : true,
                                );
                                setLocationOptions(filteredLocations);
                                setStateChoice(newValue ?? defaultState);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter by State"
                                    margin="normal"
                                />
                            )}
                            disabled={sessionsInfo[sessionIndex].webinar}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        sessionsInfo[sessionIndex].session
                                            .location_confirmed
                                    }
                                    color="primary"
                                    onChange={handleConfirmLocation}
                                    name="confirmed-location"
                                />
                            }
                            label="Confirmed Location"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sessionsInfo[sessionIndex].webinar}
                                    color="primary"
                                    onChange={handleWebinar}
                                    name="webinar"
                                />
                            }
                            label="Webinar"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Autocomplete
                            id="addressAutocomplete"
                            options={locationOptions}
                            getOptionLabel={(option: Location) => {
                                if (!option) {
                                    return 'no options available';
                                }
                                const addressParts = [
                                    option?.addressee || '',
                                    option?.address_1 || '',
                                    option?.address_2 || '',
                                    option?.city || '',
                                    option?.state || '',
                                    option?.zip_code || '',
                                ];
                                return addressParts
                                    .filter((p) => p !== '')
                                    .join(' ');
                            }}
                            getOptionSelected={(
                                option: Location,
                                value: Location,
                            ) => option.state === value.state}
                            value={location?.state ? location : null}
                            onChange={(event: any, newValue: any) => {
                                const newSessionsInfo = [...sessionsInfo];
                                newSessionsInfo[
                                    sessionIndex
                                ].session.location_id = newValue
                                    ? newValue.id
                                    : null;
                                setSessionsInfo(newSessionsInfo);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location search"
                                    margin="normal"
                                />
                            )}
                            disabled={sessionsInfo[sessionIndex].webinar}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {!sessionsInfo[sessionIndex].webinar && (
                            <div className={styleClasses.newAddressButton}>
                                <AddAddressDialog mode="location" />
                            </div>
                        )}
                    </Grid>
                    {sessionsInfo[sessionIndex].webinar
                        ? onlineLocationParts
                        : physicalLocationParts}
                </Grid>
            </form>
        </div>
    );
}
