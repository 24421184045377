import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Tooltip,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Client from '../../client';
import { ToggleStateChips } from '../StateChipLists';

import Invoice from '../../store/entities/invoice/type';
import Seat from '../../store/entities/seat/type';
import {
    actions as invoiceActions,
    invoiceSelectors,
} from '../../store/entities/invoice';
import { classSelectors } from '../../store/entities/class';
import { studentSelectors } from '../../store/entities/student';

const useStyles = makeStyles(() => ({
    root: {},
    marginVert: {
        margin: '10px 0px',
    },
    bold: {
        fontWeight: 'bold',
    },
    content: {
        width: '550px',
    },
}));

interface MoveInvoiceDialogProps extends React.HTMLProps<HTMLDivElement> {
    invoiceId: number;
    buttonRender: JSX.Element;
    onConfirm?: () => void;
    disabled?: boolean;
}

const MoveInvoiceDialog: React.FunctionComponent<MoveInvoiceDialogProps> = ({
    invoiceId,
    buttonRender,
    onConfirm = () => {},
    disabled = false,
}) => {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [newClassId, setNewClassId] = React.useState('');
    const [revisedTotal, setRevisedTotal] = React.useState('');
    const [classIdError, setClassIdError] = React.useState(false);
    const [validClassId, setValidClassId] = React.useState(false);
    const [seatsInfo, setSeatsInfo] = React.useState([] as Seat[]);
    const classes = useSelector(classSelectors.selectAll);
    const studentList = useSelector(studentSelectors.selectAll);
    const invoiceFetched = useSelector((state) =>
        invoiceSelectors.selectById(state, invoiceId),
    );
    const classChosen = classes.find((c) => c.id === parseInt(newClassId, 10));
    const students: JSX.Element[] = [];
    const title = invoiceFetched ? 'Moving Invoice' : 'Error';

    function handleClose() {
        setOpen(false);
    }

    const handleClassIdFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const newId = event.target.value;
        setClassIdError(false);
        setValidClassId(false);
        setNewClassId(newId);
    };

    const handleRevisedTotalFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const newTotal = event.target.value;
        setRevisedTotal(newTotal);
    };

    function validateClassId(thisInvoice: Invoice) {
        const classIdChosen = +newClassId;
        const error = Number.isNaN(classIdChosen);
        if (error) {
            setClassIdError(error);
            return !error;
        }
        const validClassIDs = classes
            .filter(
                ({ id, canceled }) =>
                    id !== thisInvoice.seats[0].class_id && !canceled,
            )
            .map(({ id }) => id);
        const hasValidId = validClassIDs.includes(classIdChosen);
        setClassIdError(!hasValidId);
        return hasValidId;
    }

    function moveInvoice(thisInvoice: Invoice) {
        const { individual, number } = thisInvoice;
        const invSeats = seatsInfo.map((seat) => ({
            ...seat,
            states: seat.states.map((st) => st.id),
        }));
        Client.put(`api/invoices/${invoiceId}`, {
            individual,
            // validate revised total
            amount: Number.isInteger(parseFloat(revisedTotal) * 100)
                ? revisedTotal
                : thisInvoice.amount,
            number,
            paid_amount: thisInvoice.paid_amount,
            date_issued: thisInvoice.date_issued,
            seats: invSeats,
        }).then(async (resp) => {
            if (resp) {
                const getInvResponse = await Client.get(
                    `api/invoices/${invoiceId}`,
                );
                const updatedInvoice = getInvResponse.data;
                dispatch(
                    invoiceActions.invoiceUpdate({
                        id: invoiceId,
                        changes: {
                            amount: updatedInvoice.amount,
                            seats: updatedInvoice.seats,
                        },
                    }),
                );
            }
        });
    }

    students.push(
        <Grid
            container
            key="student-labels"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item className={styleClasses.bold} xs={4}>
                Student Name
            </Grid>
            <Grid item className={styleClasses.bold} xs={8}>
                New Class Certifications
            </Grid>
        </Grid>,
    );

    /* eslint-disable no-plusplus */
    for (let n = 0; n < seatsInfo.length; n++) {
        const student = studentList.find((u) => u.id === seatsInfo[n].user_id);
        if (student) {
            students.push(
                <Grid
                    container
                    key={`student-${student.id}`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={4}>
                        <div>{`${student.full_name}`}</div>
                        <div>{`SSN: ${
                            student.ssn_last_four_digits
                                ? `${student.ssn_last_four_digits}`.slice(-4)
                                : '####'
                        }`}</div>
                    </Grid>
                    <Grid item xs={8}>
                        <ToggleStateChips
                            accreditations={
                                classChosen?.class_accreditations || []
                            }
                            seatsInfo={seatsInfo}
                            setSeatsInfo={setSeatsInfo}
                            seatIndex={n}
                        />
                    </Grid>
                </Grid>,
            );
        }
    }
    /* eslint-disable no-plusplus */

    const content = !invoiceFetched ? (
        `No invoice found for invoice id ${invoiceId}.`
    ) : (
        <Grid
            container
            key="move-invoice-content"
            justify="flex-start"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={7}>
                <TextField
                    label={`New Class ID for Invoice ${invoiceFetched.number}`}
                    id={`invoice-${invoiceId}-moveTo-classId`}
                    margin="dense"
                    variant="outlined"
                    onChange={handleClassIdFieldChange}
                    value={newClassId}
                    required
                    error={classIdError}
                    helperText={classIdError ? 'Invalid class ID.' : ''}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    color="primary"
                    variant="contained"
                    id={`move-invoice-${invoiceId}-dialog-button-cancel`}
                    disabled={validClassId} // Disabled when class Id has been applied and not further edited.
                    onClick={() => {
                        if (invoiceFetched) {
                            const validId = validateClassId(invoiceFetched);
                            setValidClassId(validId);
                            if (validId) {
                                const newSeats = invoiceFetched.seats.map(
                                    (seat) =>
                                        ({
                                            class_id: parseInt(newClassId, 10),
                                            user_id: seat.user_id,
                                            previous_certification:
                                                seat.previous_certification ??
                                                'none',
                                            states: [],
                                        } as Seat),
                                );
                                setSeatsInfo(newSeats);
                            }
                        }
                    }}
                >
                    Apply Class ID
                </Button>
            </Grid>
            <Grid item>
                {validClassId && (
                    <>
                        <div className={styleClasses.marginVert}>
                            {students}
                        </div>
                        <TextField
                            label="Revised Invoice Total"
                            id="revised-invoice-total"
                            margin="dense"
                            variant="outlined"
                            onChange={handleRevisedTotalFieldChange}
                            value={revisedTotal}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );

    return (
        <>
            <Tooltip title="Move Invoice">
                <IconButton
                    edge="end"
                    aria-label="moveInvoice"
                    disabled={
                        invoiceFetched?.notes?.includes('VOIDED') || disabled
                    }
                    onClick={() => setOpen(true)}
                >
                    {buttonRender}
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                aria-labelledby="move-invoice-dialog-title"
                aria-describedby="move-invoice-dialog-description"
            >
                <DialogTitle id={`move-invoice-${invoiceId}-dialog-title`}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id={`move-invoice-${invoiceId}-dialog-description`}
                    >
                        <div className={styleClasses.content}>{content}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id={`move-invoice-${invoiceId}-dialog-button-cancel`}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (invoiceFetched) {
                                moveInvoice(invoiceFetched);
                                onConfirm();
                                handleClose();
                            }
                        }}
                        color="primary"
                        autoFocus
                        id={`move-invoice-${invoiceId}-dialog-button-confirm`}
                        disabled={
                            !validClassId ||
                            seatsInfo.length === 0 ||
                            seatsInfo
                                .map((seat) => seat.states.length)
                                .sort()[0] === 0
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

MoveInvoiceDialog.defaultProps = {
    onConfirm: () => {},
    disabled: false,
};

export default MoveInvoiceDialog;
