import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Invoice from './type';

const invoiceAdapter = createEntityAdapter<Invoice>();

const slice = createSlice({
    name: 'invoice',
    initialState: invoiceAdapter.getInitialState(),
    reducers: {
        invoiceAddOne: invoiceAdapter.addOne,
        invoiceAddMany: invoiceAdapter.addMany,
        invoiceUpdate: invoiceAdapter.updateOne,
        invoiceRemove: invoiceAdapter.removeOne,
    },
});

export const invoiceSelectors = invoiceAdapter.getSelectors(
    (state: any) => state.invoices,
);

export const { actions, reducer } = slice;
