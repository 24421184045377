import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';

import { trainerSelectors } from '../../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        trainerGroup: {},
        listNumber: {
            flex: 0,
            marginRight: theme.spacing(1),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    sessionTrainers: number[];
    principalTrainer: number;
    onRemoveTrainer: (trainer_id: number) => void;
    onPrincipalTrainer: (trainer_id: number) => void;
}

export default function SessionTrainerList({
    sessionTrainers,
    principalTrainer,
    onPrincipalTrainer,
    onRemoveTrainer,
}: Props) {
    const styleClasses = useStyles();
    const trainers = useSelector(trainerSelectors.selectAll);

    function trainerName(trainer_id: number): string {
        const trainerFound = trainers.find(({ id }) => id === trainer_id);
        return trainerFound ? trainerFound.user.full_name : 'no Trainer found';
    }
    return (
        <div className={styleClasses.root}>
            <Grid container className={styleClasses.titleContainer}>
                <Grid item className={styleClasses.title}>
                    <Typography variant="h6" component="h2">
                        List of Trainers
                    </Typography>
                </Grid>
            </Grid>
            {sessionTrainers.map((trainer_id, i) => (
                <Grid
                    container
                    alignItems="flex-end"
                    direction="row"
                    justify="space-between"
                    wrap="nowrap"
                    key={`trainer-${trainer_id}`}
                    className={styleClasses.trainerGroup}
                >
                    <Grid
                        item
                        sm={false}
                        className={styleClasses.listNumber}
                        style={{}}
                    >
                        <Typography
                            style={{ marginBottom: 12, fontWeight: 800 }}
                        >
                            {i + 1})
                        </Typography>
                    </Grid>
                    <Grid item sm={false} xs={12} style={{ flex: 1 }}>
                        <Typography style={{ marginBottom: 12 }}>
                            {trainerName(trainer_id)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={false}
                        xs={12}
                        style={{ flex: 0, display: 'flex' }}
                    >
                        <Tooltip title="Make this the principal trainer">
                            <IconButton
                                color={
                                    principalTrainer === trainer_id
                                        ? 'secondary'
                                        : 'default'
                                }
                                style={
                                    principalTrainer === trainer_id
                                        ? { opacity: 1 }
                                        : { opacity: 0.4 }
                                }
                                onClick={() => onPrincipalTrainer(trainer_id)}
                            >
                                <StarIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete this trainer">
                            <IconButton
                                aria-label="delete"
                                onClick={() => onRemoveTrainer(trainer_id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
}
