import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import User from '../user/type';

const studentAdapter = createEntityAdapter<User>();

const slice = createSlice({
    name: 'student',
    initialState: studentAdapter.getInitialState(),
    reducers: {
        studentAddOne: studentAdapter.addOne,
        studentAddMany: studentAdapter.addMany,
        studentUpdate: studentAdapter.updateOne,
        studentRemove: studentAdapter.removeOne,
    },
});

export const studentSelectors = studentAdapter.getSelectors(
    (state: any) => state.students,
);

export const { actions, reducer } = slice;
