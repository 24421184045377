import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';

import { adminSelectors } from '../../store/entities/admin';
import { studentSelectors } from '../../store/entities/student';
import { trainerSelectors } from '../../store/entities/trainer';
import User from '../../store/entities/user/type';
import TrainerInputForm from '../TrainerInputForm';
import TrainerListItem from '../TrainerListItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        caption: {
            width: '100%',
            color: theme.palette.error.main,
            fontSize: 'small',
            marginTop: theme.spacing(1),
            height: '1.5em',
            display: 'block',
        },
        textfield: {
            marginRight: theme.spacing(2),
        },
        paper: {
            margin: theme.spacing(1),
            width: '100%',
            maxWidth: 540,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        listPaper: {
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
        },
        formMessageTextfield: {
            margin: theme.spacing(1),
            marginBottom: '-1.75em',
        },
        formNoMessageTextfield: {
            margin: theme.spacing(1),
        },
    }),
);

interface TrainerListProps extends React.HTMLProps<HTMLDivElement> {
    userFilters: { type: string; fName: string; lName: string };
}

const TrainerList: React.FunctionComponent<TrainerListProps> = (props) => {
    const { userFilters } = props;
    const classes = useStyles();

    const [itemSelected, setItemSelected] = React.useState(0);
    const [editDisabled, setEditDisabled] = React.useState(false);

    const admins = useSelector(adminSelectors.selectAll);
    const students = useSelector(studentSelectors.selectAll).filter(
        (user) => !user.is_staff_trainer && !user.is_contract_trainer,
    );
    const trainers = useSelector(trainerSelectors.selectAll);
    let filteredUsers: User[] = [];

    if (userFilters.type === 'METAStaff') {
        filteredUsers = [...admins];
    }
    if (userFilters.type === 'staff') {
        filteredUsers = trainers
            .map((t) => t.user)
            .filter((u) => u.is_staff_trainer);
    }
    if (userFilters.type === 'contract') {
        filteredUsers = trainers
            .map((t) => t.user)
            .filter((u) => u.is_contract_trainer);
    }
    if (userFilters.type === 'student') {
        const filterdStudents = students.filter((s) => {
            const studentFName = s.first_name.toUpperCase();
            const studentLName = s.last_name.toUpperCase();
            const { fName, lName } = userFilters;
            if (fName.length > 1 && lName.length > 1) {
                return (
                    studentFName.slice(0, fName.length) === fName &&
                    studentLName.slice(0, lName.length) === lName
                );
            }
            if (fName.length > 1) {
                return studentFName.slice(0, fName.length) === fName;
            }
            if (lName.length > 1) {
                return studentLName.slice(0, lName.length) === lName;
            }
            return true;
        });
        filteredUsers = [...filterdStudents.slice(0, 20)];
    }

    const handleEditClick = (given: string, surname: string, id: number) => {
        setItemSelected(id);
        setEditDisabled(true);
    };
    return (
        <div className={clsx(classes.root)}>
            <Paper className={clsx(classes.paper, classes.listPaper)}>
                <List>
                    {filteredUsers.length > 0 &&
                        filteredUsers.map((user) => (
                            <ListItem
                                key={`item-${user.id}`}
                                style={
                                    itemSelected !== user.id
                                        ? {}
                                        : { display: 'block' }
                                }
                            >
                                {itemSelected !== user.id && (
                                    <TrainerListItem
                                        trainer={user}
                                        trainerId={user.id}
                                        editDisabled={editDisabled}
                                        handleEditClick={handleEditClick}
                                    />
                                )}
                                {itemSelected === user.id && (
                                    <TrainerInputForm
                                        trainerId={itemSelected}
                                        oldTrainer={user}
                                        setItemSelected={setItemSelected}
                                        setEditDisabled={setEditDisabled}
                                    />
                                )}
                            </ListItem>
                        ))}
                </List>
            </Paper>
        </div>
    );
};

export default TrainerList;
