import React from 'react';
import { useSelector } from 'react-redux';
import MUIDataTable, {
    MUIDataTableOptions,
    MUIDataTableIsRowCheck,
} from 'mui-datatables';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import InvoiceDetails from '../InvoiceDetails';
import { getCustomColumns, useStyles } from '../utils';

import Seat from '../../../store/entities/seat/type';
import User from '../../../store/entities/user/type';
import Class from '../../../store/entities/class/type';
import { classSelectors } from '../../../store/entities/class';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { studentSelectors } from '../../../store/entities/student';
import { adminSelectors } from '../../../store/entities/admin';

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function DataTable(props: Props) {
    const { className, ...attrs } = props;
    const styleClasses = useStyles();
    const classData = useSelector(classSelectors.selectAll);
    const admins = useSelector(adminSelectors.selectAll);
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        (inv) => inv.seats.length > 0,
    );
    const students = useSelector(studentSelectors.selectAll);

    function getSeatStudents(seats: Seat[]): User[] {
        const seatIds = seats.map(({ user_id }) => user_id);
        const seatStudents = students.filter((s) => seatIds.includes(s.id));

        return seatStudents;
    }

    function getClassInfo(seat: Seat): Class {
        const classFound = classData.find((c) => c.id === seat?.class_id);

        return classFound || ({} as Class);
    }

    // Classname is supported by MUIIDataTable, but it isn't recognized as a valid property by the current TS definitions
    // so we have to trick the compiler into letting us use className.
    const specialProps = {
        className: styleClasses.root,
    };

    const columns = getCustomColumns();

    const options: MUIDataTableOptions = {
        filter: true,
        filterType: 'dropdown',
        sortOrder: {
            name: 'dateSort',
            direction: 'asc',
        },
        print: false,
        search: false,
        download: false,
        viewColumns: false,
        responsive: 'vertical',
        selectableRowsHeader: false,
        selectableRows: 'none',
        expandableRows: true,
        expandableRowsOnClick: false,
        isRowExpandable: (
            dataIndex: number,
            expandedRows: MUIDataTableIsRowCheck | undefined,
        ) =>
            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            !!expandedRows &&
            (expandedRows.data.length < 4 ||
                expandedRows.data.filter((d) => d.dataIndex === dataIndex)
                    .length !== 0),
        rowsExpanded: [],
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <InvoiceDetails
                            invoiceInstance={invoices[rowMeta.dataIndex]}
                            seatStudents={getSeatStudents(
                                invoices[rowMeta.dataIndex].seats,
                            )}
                            classInstance={getClassInfo(
                                invoices[rowMeta.dataIndex].seats[0],
                            )}
                        />
                    </TableCell>
                </TableRow>
            );
        },
    };

    return (
        <div {...attrs} className={className}>
            <MUIDataTable
                {...specialProps}
                title="META"
                data={invoices.map((inv) => {
                    const invoiceClass = getClassInfo(inv.seats?.[0]);
                    const seatStudents = getSeatStudents(inv.seats);
                    const firstStudent = seatStudents?.[0];
                    const invoiceIssuer = admins.find(
                        (admin) => admin.id === inv.issuer,
                    );
                    return {
                        id: inv.id,
                        invoiceAmounts: {
                            paidAmount: inv.paid_amount,
                            totalDue: inv.amount,
                        },
                        paidStatus:
                            Number(inv.paid_amount) < Number(inv.amount)
                                ? 'outstanding'
                                : 'paid in full',
                        invoiceNumber: inv.number,
                        issuer: `${invoiceIssuer?.first_name} ${invoiceIssuer?.last_name}`,
                        client: inv.company
                            ? inv.company.name
                            : `${firstStudent?.first_name || 'Not'} ${
                                  firstStudent?.last_name || 'Found'
                              }`,
                        courseName: `${invoiceClass.course?.name} (${invoiceClass.course?.abbreviations})`,
                        classLocation:
                            invoiceClass.sessions?.[0]?.location.addressee,
                        classStart: invoiceClass.sessions?.[0]?.date_start,
                        seatNames: seatStudents.map(
                            ({ first_name, last_name, username }) =>
                                `${first_name} ${last_name} (${username})`,
                        ),
                        dateSort: new Date(inv.date_issued).valueOf(),
                        date_filter: new Date(inv.date_issued).valueOf(),
                        voidedDate: inv.notes?.includes('VOIDED')
                            ? new Date(
                                  inv.notes.substring(
                                      inv.notes.indexOf('/') - 1,
                                      inv.notes.indexOf('|'),
                                  ),
                              ).valueOf()
                            : 0,
                    };
                })}
                columns={columns}
                options={options}
            />
        </div>
    );
}
