import React from 'react';
import { useSelector } from 'react-redux';
import { MUIDataTableColumn } from 'mui-datatables';

import {
    FormControlLabel,
    FormGroup,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { trainerSelectors } from '../../../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        filterOption: {
            width: `calc(50% - ${theme.spacing(2)}px)`,
        },
        principalTrainerCheckbox: {
            'margin-left': theme.spacing(3),
            'margin-top': theme.spacing(4),
        },
    }),
);

export function trainerFilterList(filter: string[]): string[] {
    if (filter[0] && filter[1] === 'true') {
        return [`Principal Trainer: ${filter[0]}`];
    }
    if (filter[0]) {
        return [`Trainer: ${filter[0]}`];
    }
    return [];
}

// MUIDataTable incorrectly types trainerNames as string instead of string[].
// trainerNames is the data passed onto the MUIDataTable under 'trainers' in ClassDataTabe/index.tsx
export function trainerFilterLogic(trainerNames: string, filters: string[]) {
    if (filters[0] && filters[1] === 'true') {
        return trainerNames[0] !== filters[0]; // but also make sure its only principal?
    }
    if (filters[0]) {
        return !trainerNames.includes(filters[0]);
    }
    return false;
}

export interface TrainerFilterDisplayProps
    extends React.HTMLProps<HTMLDivElement> {
    filterList: string[][]; // first array is for all filters, second is for options within each filter
    onChange: any;
    index: number; // use filterList[index] to access filter array for this particular filtering
    column: MUIDataTableColumn;
}

export default function TrainerFilterDisplay({
    filterList,
    onChange,
    index,
    column,
}: TrainerFilterDisplayProps) {
    const styleClasses = useStyles();
    const trainerFilters = filterList[index];
    const trainers = useSelector(trainerSelectors.selectAll);
    const trainerOptions = trainers.map((trainer) => trainer.user.full_name);
    trainerOptions.unshift('All');

    const handleChange = (event: any) => {
        const newFilters = [...trainerFilters];
        newFilters[1] = `${event.target.checked}`;
        onChange(newFilters, index, column);
    };

    return (
        <FormGroup row>
            <div className={styleClasses.filterOption}>
                <Autocomplete
                    id="trainerFilterAutocomplete"
                    options={trainerOptions} // trainerOptions should be full names of all available trainers
                    disableClearable
                    getOptionLabel={(option: string) => {
                        if (!option) {
                            return 'no options available';
                        }
                        return option;
                    }}
                    getOptionSelected={(option: string, value: string) =>
                        option === value
                    }
                    defaultValue={trainerOptions[0]}
                    value={trainerFilters[0] || trainerOptions[0]}
                    onChange={(event: any, newValue: any) => {
                        const newFilters = [...trainerFilters];
                        newFilters[0] = newValue === 'All' ? null : newValue;
                        onChange(newFilters, index, column);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Trainers"
                            margin="normal"
                        />
                    )}
                />
            </div>
            <div className={styleClasses.principalTrainerCheckbox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={trainerFilters[1] === 'true'}
                            color="primary"
                            onChange={handleChange}
                            name="principal"
                        />
                    }
                    label="Principal trainer only"
                />
            </div>
        </FormGroup>
    );
}
