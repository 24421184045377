import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import { TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        filterOption: {},
    }),
);

export interface AutocompleteFilterDisplayProps
    extends React.HTMLProps<HTMLDivElement> {
    filterList: string[]; // first array is for all filters, second is for options within each filter
    onChange: any;
    index: number; // use filterList[index] to access filter array for this particular filtering
    column: MUIDataTableColumn;
    listOptions: string[];
    label: string;
}

export default function AutocompleteFilterDisplay({
    filterList,
    onChange,
    index,
    column,
    listOptions,
    label,
}: AutocompleteFilterDisplayProps) {
    const styleClasses = useStyles();
    const dropdownOptions = [...listOptions];
    dropdownOptions.unshift('All');

    return (
        <div className={styleClasses.filterOption}>
            <Autocomplete
                id={`${label}FilterAutocomplete`}
                options={dropdownOptions}
                disableClearable
                getOptionLabel={(option: string) => {
                    if (!option) {
                        return 'no options available';
                    }
                    return option;
                }}
                getOptionSelected={(option: string, value: string) =>
                    option === value
                }
                defaultValue={dropdownOptions[0]}
                value={filterList[0] || dropdownOptions[0]}
                onChange={(event: any, newValue: any) => {
                    const newFilters = [...filterList];
                    newFilters[0] = newValue === 'All' ? null : newValue;
                    onChange(newFilters, index, column);
                }}
                renderInput={(params) => (
                    <TextField {...params} label={label} margin="normal" />
                )}
            />
        </div>
    );
}
