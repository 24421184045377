import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';

import FormField from '../FormField';
import FormAutocomplete from '../FormAutocomplete';
import AddAddressDialog from '../AddAddressDialog';

import Client from '../../client';
import Company from '../../store/entities/company/type';
import { locationSelectors } from '../../store/entities/location';
import { actions as companyActions } from '../../store/entities/company';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    sub_title: {
        marginTop: theme.spacing(4),
        fontSize: 24,
        width: '100%',
    },
    form_content: {
        fontSize: 18,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface AddCompanyDialogProps extends React.HTMLProps<HTMLDivElement> {
    onConfirm: () => void;
}

const AddCompanyDialog: React.FunctionComponent<AddCompanyDialogProps> = ({
    onConfirm,
}) => {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const locations = useSelector(locationSelectors.selectAll);
    const title = 'Add a Company';
    const content = 'Fill in the fields below to add a new company.';
    const form = useForm();
    const { control } = form;

    const companySelectorOptions = locations
        .filter((loc) => !loc.addressee.toUpperCase().includes('ZOOM.US'))
        .map((loc) => ({
            id: loc.id,
            label: `${loc.addressee}: ${loc.address_1} ${loc.city}, ${loc.state}`,
        }));

    function handleClose(): void {
        setOpen(false);
    }

    async function onSubmit(data: any) {
        // Takes new company data and POSTs to api
        // Return id of new company to main form via onConfirm()
        let payload: Partial<Company> = (({
            name,
            contact_name,
            location_id,
            primary_email,
            phone,
        }) => ({
            name,
            contact_name,
            location_id,
            primary_email,
            phone,
            active: true,
        }))(data);

        if (data.secondary_email_name) {
            payload = {
                ...payload,
                secondary_email_name: data.secondary_email_name,
            };
        }

        if (data.secondary_email) {
            payload = {
                ...payload,
                secondary_email: data.secondary_email,
            };
        }

        if (data.secondary_phone) {
            payload = {
                ...payload,
                secondary_phone: data.secondary_phone,
            };
        }

        await Client.post('/api/companies', payload).then((resp) =>
            dispatch(companyActions.companyAddOne(resp.data)),
        );
        await form.reset({});
        onConfirm();
        handleClose();
    }

    return (
        <FormProvider {...form}>
            <Tooltip title="New Company">
                <IconButton
                    edge="end"
                    aria-label="new-company"
                    onClick={() => setOpen(true)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="company-dialog-title"
                aria-describedby="company-dialog-description"
                maxWidth="lg"
            >
                <form
                    className={styleClasses.form}
                    // Submits current form without unintended submission of main from
                    onSubmit={(e) => {
                        e.stopPropagation();
                        return form.handleSubmit(onSubmit)(e);
                    }}
                >
                    <DialogTitle id="company-dialog-title">{title}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="company-dialog-description">
                            {content}
                        </DialogContentText>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Company Name"
                                    validate={{
                                        required: 'Name required.',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="contact_name"
                                    name="contact_name"
                                    label="Attn:"
                                    validate={{
                                        required: 'Name required.',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <FormAutocomplete
                                    options={companySelectorOptions}
                                    label="Choose a company location *"
                                    control={control}
                                    name="location_id"
                                    validate={{ required: true }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <AddAddressDialog mode="location" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="email"
                                    name="primary_email"
                                    label="Email"
                                    validate={{
                                        required: 'Email required.',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    validate={{
                                        required: 'Phone required.',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="secondary_email_name"
                                    name="secondary_email_name"
                                    label="Secondary Contact"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="secondary_email"
                                    name="secondary_email"
                                    label="Secondary Email"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="secondary_phone"
                                    name="secondary_phone"
                                    label="Secondary Phone"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => handleClose()}
                            color="default"
                            id="company-dialog-button-cancel"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            id="company-dialog-button-confirm"
                            variant="contained"
                        >
                            Add New Company
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </FormProvider>
    );
};

export default AddCompanyDialog;
