import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import useQuery from '../../../utils/useQuery';
import FormField from '../../FormField';
import ClientDetails from '../ClientDetails';
import SeatInformation from '../SeatInformation';
import DuplicateStudentDialog, { SeatInfo } from '../DuplicateStudentDialog';
import FormAutocomplete from '../../FormAutocomplete';
import AddCompanyDialog from '../../AddCompanyDialog';
import { NotificationStateChips } from '../../StateChipLists';

import Client from '../../../client';
import Invoice from '../../../store/entities/invoice/type';
import Seat from '../../../store/entities/seat/type';
import User from '../../../store/entities/user/type';
import { classSelectors } from '../../../store/entities/class';
import { companySelectors } from '../../../store/entities/company';
import {
    actions as invoiceActions,
    invoiceSelectors,
} from '../../../store/entities/invoice';
import { locationSelectors } from '../../../store/entities/location';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    sub_title: {
        marginTop: theme.spacing(4),
        fontSize: 24,
        width: '100%',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    form_content: {
        fontSize: 18,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export interface Props extends React.HTMLProps<HTMLDivElement> {
    currentUser: User;
}

export default function SeatInputForm({ currentUser, ...props }: Props) {
    const styleClasses = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { className } = props;
    const hourFormat = new Intl.DateTimeFormat('en', {
        timeStyle: 'short',
    });
    const query = useQuery();
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const invoiceId = parseInt(query.get('invoice_id') || '0', 10);
    const classId = parseInt(query.get('class_id') || '0', 10);
    const invoiceFetched = useSelector((state) =>
        invoiceSelectors.selectById(state, invoiceId),
    );
    const classFetched = useSelector((state) =>
        classSelectors.selectById(
            state,
            invoiceFetched?.seats[0]?.class_id || classId,
        ),
    );
    const classInvoices = invoices.filter(
        (inv) =>
            inv.seats?.[0]?.class_id ===
            (invoiceFetched?.seats[0]?.class_id || classId),
    );

    const companies = useSelector(companySelectors.selectAll);
    const [isLoading, setIsLoading] = useState(false);
    const [seatsInfo, setSeatsInfo] = useState([] as Seat[]);
    const [duplicateDialog, setDuplicateDialog] = useState(false);
    const [ignoreDuplicates, setIgnoreDuplicates] = useState(false);
    const [duplicateSeats, setDuplicateSeats] = useState([] as SeatInfo[]);
    const form = useForm();
    const { watch, control } = form;
    const companyId: number = watch('company_id');
    const paidAmount: number = watch('paid_amount');
    // if companyId === 0, take company fields from user in seatsInfo[0] if it exists
    const invoiceNumber = invoiceFetched
        ? invoiceFetched.number
        : `${currentUser.first_name[0].toUpperCase()}${currentUser.last_name[0].toUpperCase()}000000-00`;

    const companySelectorOptions = companies.map((company: any) => ({
        id: company.id,
        label: company.name,
    }));

    companySelectorOptions.unshift({
        id: 0,
        label: 'Self-represented Student',
    });

    const classCourse = classFetched?.course;
    const sessions = classFetched?.sessions;
    const startDate = new Date(sessions?.[0]?.date_start || Date.now());
    const endDate = new Date(
        sessions?.[sessions.length - 1]?.date_end || Date.now(),
    );
    const classLocation = useSelector((state) =>
        locationSelectors.selectById(state, sessions?.[0]?.location_id || 0),
    );
    const classAddress = classLocation
        ? `${classLocation.address_1}, ${classLocation.city}, ${classLocation.state} ${classLocation.zip_code}`
        : '';

    const paymentTypeChoices = ['cash', 'card', 'cheque'].map((option) => (
        <MenuItem key={option} value={option}>
            {option}
        </MenuItem>
    ));

    if (invoiceFetched && !Number.isInteger(companyId)) {
        // set currentInvoice data to this invoice's fields
        form.reset({
            company_id: invoiceFetched.company_id || 0,
            amount: invoiceFetched.amount,
            paid_amount: invoiceFetched.paid_amount,
            notes: '',
            po_number: invoiceFetched.po_number,
            payment_type: invoiceFetched.payment_type,
        });

        const invSeats = invoiceFetched.seats.map(
            (seat) =>
                ({
                    class_id: seat.class_id,
                    user_id: seat.user_id,
                    previous_certification:
                        seat.previous_certification ?? 'none',
                    states: seat.states.map((state) => ({
                        id: state.id,
                        name: state.name,
                        abbreviation: state.abbreviation,
                        is_territory: state.is_territory,
                        is_state: state.is_state,
                    })),
                } as Seat),
        );

        setSeatsInfo(invSeats);
    }

    function paddedNum(num: number, digits: number): string {
        const numLength = String(num).length;
        const zeros: string[] = [];
        for (let i = 0; i < digits - numLength; i += 1) {
            zeros.push('0');
        }
        const paddedResult = zeros.join('').concat(String(num));
        return paddedResult;
    }

    function confirmButton(): JSX.Element {
        const voidedInvoice =
            invoiceFetched && invoiceFetched.notes?.includes('VOIDED');
        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styleClasses.submit}
                // Disable button if no students or students havent selected at least one state each
                disabled={
                    voidedInvoice ||
                    seatsInfo.length === 0 ||
                    seatsInfo.map((seat) => seat.states.length).sort()[0] === 0
                }
            >
                {classId ? 'Create Invoice' : 'Submit Changes'}
            </Button>
        );
    }

    async function onSubmit(data: any) {
        if (!isLoading) {
            setIsLoading(true);
        }

        const classSeats: SeatInfo[] = [];
        classInvoices
            .filter((invoice) => invoice.id !== invoiceId)
            .forEach((inv) =>
                inv.seats.forEach((seat) =>
                    classSeats.push({
                        seat,
                        invoiceId: inv.id,
                        invoiceNumber: inv.number,
                        invoiceAmount: Number(inv.amount),
                        invoiceAmountPaid: Number(inv.paid_amount),
                        companyName: inv.company?.name,
                    }),
                ),
            );
        const newStudents = seatsInfo.map((s) => s.user_id);
        const duplicates: SeatInfo[] = [];
        classSeats.forEach((classSeat) => {
            if (newStudents.includes(classSeat.seat.user_id)) {
                duplicates.push(classSeat);
            }
        });

        setDuplicateSeats(duplicates);

        if (duplicates.length && !ignoreDuplicates) {
            setDuplicateDialog(true);
            return;
        }

        // New invoice request
        if (classId && classFetched) {
            const seatsPayload = seatsInfo.map((s) => ({
                user_id: s.user_id,
                class_id: classId,
                states: s.states.map((st) => st.id),
            }));

            let invoicePayload: Partial<Invoice> = {
                number: invoiceNumber,
                issuer: currentUser.id,
                individual: true,
                amount: data.amount,
                paid_amount: '0',
                date_issued: format(new Date(), 'y-MM-dd'),
            };

            if (companyId !== 0) {
                invoicePayload = {
                    ...invoicePayload,
                    individual: false,
                    company_id: data.company_id,
                };
            }

            if (Number(data.paid_amount) !== 0) {
                invoicePayload = {
                    ...invoicePayload,
                    paid_amount: data.paid_amount,
                    payment_type: data.payment_type,
                    date_paid: format(new Date(), 'y-MM-dd'),
                };
            }

            if (data.po_number) {
                invoicePayload = {
                    ...invoicePayload,
                    po_number: data.po_number,
                };
            }

            if (data.notes.length) {
                invoicePayload = {
                    ...invoicePayload,
                    notes: data.notes,
                };
            }

            await Client.post('/api/invoices', {
                ...invoicePayload,
                seats: seatsPayload,
            }).then((resp) => {
                dispatch(invoiceActions.invoiceAddOne(resp.data));
                const newId = resp.data.id;
                const updatedInvoiceNumber = invoiceNumber.replace(
                    '000000',
                    paddedNum(newId, 6),
                );
                const updateNumberPayload: Partial<Invoice> = {
                    number: updatedInvoiceNumber,
                    individual: resp.data.individual,
                    amount: resp.data.amount,
                    paid_amount: resp.data.paid_amount,
                    date_issued: resp.data.date_issued,
                };
                Client.put(`/api/invoices/${newId}`, {
                    ...updateNumberPayload,
                    seats: seatsPayload,
                }).then((response) => {
                    if (response.data) {
                        dispatch(
                            invoiceActions.invoiceUpdate({
                                id: newId,
                                changes: {
                                    number: updatedInvoiceNumber,
                                },
                            }),
                        );
                    }
                });
            });
        }

        // Edit invoice request
        if (invoiceId && invoiceFetched) {
            const seatsPayload = seatsInfo.map((s) => ({
                user_id: s.user_id,
                class_id: invoiceFetched.seats[0].class_id,
                states: s.states.map((st) => st.id),
            }));

            const currentInvoiceVersion = invoiceFetched.number.slice(-2);
            const versionUpdate = Number(currentInvoiceVersion) + 1;
            const newInvoiceVersion = paddedNum(versionUpdate, 2);

            let invoicePayload: Partial<Invoice> = {
                number: invoiceFetched.number.replace(
                    `-${currentInvoiceVersion}`,
                    `-${newInvoiceVersion}`,
                ),
                individual: invoiceFetched.individual,
                amount: data.amount,
                paid_amount: invoiceFetched.paid_amount,
                date_issued: invoiceFetched.date_issued,
            };

            if (data.paid_amount !== invoiceFetched.paid_amount) {
                invoicePayload = {
                    ...invoicePayload,
                    paid_amount: data.paid_amount,
                    payment_type: data.payment_type,
                    date_paid: format(new Date(), 'y-MM-dd'),
                };
            }

            if (data.po_number !== invoiceFetched.po_number) {
                invoicePayload = {
                    ...invoicePayload,
                    po_number: data.po_number,
                };
            }

            if (data.notes) {
                invoicePayload = {
                    ...invoicePayload,
                    notes: `${
                        invoiceFetched.notes ? `${invoiceFetched.notes} | ` : ''
                    }${data.notes} : ${new Date().toLocaleDateString()}`,
                };
            }

            await Client.put(`/api/invoices/${invoiceId}`, {
                ...invoicePayload,
                seats: seatsPayload,
            }).then((resp) => {
                if (resp.data) {
                    dispatch(
                        invoiceActions.invoiceUpdate({
                            id: invoiceFetched.id,
                            changes: {
                                ...invoicePayload,
                                seats: seatsInfo.map((s) => ({
                                    user_id: s.user_id,
                                    class_id: invoiceFetched.seats[0].class_id,
                                    states: s.states,
                                    previous_certification:
                                        s.previous_certification,
                                })),
                            },
                        }),
                    );
                }
            });
        }

        setSeatsInfo([]);
        setIgnoreDuplicates(false);
        setIsLoading(false);
        await form.reset({});
        await history.push(`/classview?class_id=${classFetched?.id}`);
    }

    return (
        <div className={className}>
            <DuplicateStudentDialog
                open={duplicateDialog}
                setOpen={setDuplicateDialog}
                seats={duplicateSeats}
                classAccreditations={classFetched?.class_accreditations || []}
                onConfirm={() => setIgnoreDuplicates(true)}
            />
            <FormProvider {...form}>
                <form
                    className={styleClasses.form}
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                            {`Invoice Number: ${
                                invoiceFetched ? invoiceNumber : 'New Invoice'
                            }`}
                        </Grid>
                        <Grid item xs={3}>
                            {`From: ${currentUser.email}`}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <FormAutocomplete
                                options={companySelectorOptions}
                                label="Choose a company"
                                control={control}
                                name="company_id"
                                style={{ width: 300 }}
                                validate={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {/* Posts new company and retrieves id for this form
                                onConfirm should get a useState update function(?) */}
                            <AddCompanyDialog onConfirm={() => {}} />
                        </Grid>
                    </Grid>
                    <ClientDetails
                        clientId={
                            companyId === 0
                                ? seatsInfo?.[0]?.user_id
                                : companyId
                        }
                        clientType={companyId === 0 ? 'Individual' : 'Company'}
                    />
                    <Divider />
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <div className={styleClasses.form_content}>
                                {`Course: ${classCourse?.name || ''}`}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="amount"
                                name="amount"
                                label="Total due"
                                validate={{
                                    required: 'Total due required.',
                                    validate: {
                                        isNumber: (cost: any) =>
                                            Number.isInteger(cost * 100),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="paid_amount"
                                name="paid_amount"
                                label="Total paid"
                                validate={{
                                    required: 'Total paid required.',
                                    validate: {
                                        isNumber: (cost: any) =>
                                            Number.isInteger(cost * 100),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {paidAmount > 0 && (
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    select
                                    id="payment_type"
                                    name="payment_type"
                                    label="Payment type"
                                >
                                    {paymentTypeChoices}
                                </FormField>
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            {(paidAmount > 0 || invoiceId > 0) && (
                                <FormField
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="po_number"
                                    name="po_number"
                                    label="PO number"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className={styleClasses.sub_title}>Class Info</div>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            {`Class Location: ${
                                classLocation?.addressee || ''
                            }`}
                        </Grid>
                        <Grid item xs={4}>
                            {`Class Address: ${classAddress}`}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            {`Site Contact: ${
                                classLocation?.contact_name || 'John Doe'
                            }`}
                        </Grid>
                        <Grid item xs={4}>
                            {`Site Phone: ${
                                classLocation?.contact_phone || '555-123-4567'
                            }`}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                            {`Start Date: ${
                                startDate.toLocaleDateString() || ''
                            }`}
                        </Grid>
                        <Grid item xs={2}>
                            {`End Date: ${endDate.toLocaleDateString() || ''}`}
                        </Grid>
                        <Grid item xs={3}>
                            {`Class Time: ${
                                `${hourFormat.format(
                                    startDate,
                                )} - ${hourFormat.format(endDate)}` || ''
                            }`}
                        </Grid>
                        <Grid item xs={5}>
                            <span className={styleClasses.container}>
                                Accreditations:
                                <NotificationStateChips
                                    accreditations={
                                        classFetched?.class_accreditations || []
                                    }
                                    notifications={
                                        classFetched?.notifications || []
                                    }
                                />
                            </span>
                        </Grid>
                    </Grid>
                    <Divider />
                    <SeatInformation
                        seatsInfo={seatsInfo}
                        setSeatsInfo={setSeatsInfo}
                        accreditations={classFetched?.class_accreditations}
                        openClass={Boolean(classFetched?.open_class)}
                    />
                    <Divider />
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                        className={styleClasses.submit}
                    >
                        {invoiceFetched && (
                            <Grid item xs={8}>
                                Invoice Notes:
                                {invoiceFetched.notes || ''}
                            </Grid>
                        )}
                        <Grid item xs={8}>
                            <FormField
                                variant="outlined"
                                fullWidth
                                id="notes"
                                name="notes"
                                label="Add a Note"
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Grid item>{confirmButton()}</Grid>
                    </Grid>
                </form>
            </FormProvider>
            <Divider />
        </div>
    );
}
