import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import State from './type';

const stateAdapter = createEntityAdapter<State>();

const slice = createSlice({
    name: 'state',
    initialState: stateAdapter.getInitialState(),
    reducers: {
        statesAddOne: stateAdapter.addOne,
        statesAddMany: stateAdapter.addMany,
        stateUpdate: stateAdapter.updateOne,
        stateRemove: stateAdapter.removeOne,
    },
});

export const stateSelectors = stateAdapter.getSelectors(
    (state: any) => state.states,
);

export const { actions, reducer } = slice;
