import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { green, yellow, red } from '@material-ui/core/colors';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import PreviousCertField from '../PreviousCertField';
import DownloadInvoice from '../../DownloadInvoice';
import { ActiveStateChips } from '../../StateChipLists';
import { SeatInfo } from '../../SeatsForm/DuplicateStudentDialog';
import verifyAdmin from '../../../utils/verifyAdmin';

import Class from '../../../store/entities/class/type';
import User from '../../../store/entities/user/type';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { studentSelectors } from '../../../store/entities/student';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        marginVert: {
            margin: '20px 0px',
        },
        bold: {
            fontWeight: 'bold',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        green: {
            color: green[600],
        },
        yellow: {
            color: yellow[700],
        },
        red: {
            color: red[600],
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    classInstance: Class;
    user: User;
}

export default function ClassViewStudents({
    classInstance,
    user,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const students: JSX.Element[] = [];
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const classInvoices = invoices.filter(
        (inv) => inv.seats?.[0]?.class_id === classInstance.id,
    );
    const classSessions = classInstance.sessions?.length;
    const classEndDate = new Date(
        classInstance.sessions?.[classSessions - 1]?.date_end,
    );
    const twoWeeks = 2 * 7 * 24 * 60 * 60 * 1000;
    const studentList = useSelector(studentSelectors.selectAll);
    const classSeats: SeatInfo[] = [];
    classInvoices.forEach((inv) =>
        inv.seats.forEach((seat) =>
            classSeats.push({
                seat,
                invoiceId: inv.id,
                invoiceNumber: inv.number,
                invoiceAmount: Number(inv.amount),
                invoiceAmountPaid: Number(inv.paid_amount),
                companyName: inv.company?.name,
            }),
        ),
    );

    // Adding labels to student array
    students.push(
        <Grid
            container
            key="student-labels"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item className={styleClasses.bold} xs={2}>
                Student Name
            </Grid>
            <Grid item className={styleClasses.bold} xs={2}>
                Company Name
            </Grid>
            <Grid item className={styleClasses.bold} xs={1}>
                Invoice
            </Grid>
            <Grid item className={styleClasses.bold} xs={1}>
                Status
            </Grid>
            <Grid item className={styleClasses.bold} xs={3}>
                Certifications
            </Grid>
            <Grid item className={styleClasses.bold} xs={2}>
                Previous Certification
            </Grid>
            <Grid item xs={1} />
        </Grid>,
    );

    /* eslint-disable no-plusplus */
    for (let n = 0; n < classSeats.length; n++) {
        const student = studentList.find(
            (u: User) => u.id === classSeats[n].seat.user_id,
        );
        if (student) {
            students.push(
                <Grid
                    container
                    key={`student-${student.id}`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={2}>
                        <div>{`${student.full_name}`}</div>
                        <div>{`SSN: ${
                            student.ssn_last_four_digits
                                ? `${student.ssn_last_four_digits}`.slice(-4)
                                : '####'
                        }`}</div>
                    </Grid>
                    <Grid item xs={2}>
                        {`${classSeats[n].companyName}`}
                    </Grid>
                    <Grid item xs={1}>
                        <Link
                            to={{
                                pathname: '/seatsform',
                                search: `?invoice_id=${classSeats[n].invoiceId}`,
                            }}
                        >
                            {`${classSeats[n].invoiceNumber}`}
                        </Link>
                    </Grid>
                    <Grid item xs={1}>
                        <div className={styleClasses.container}>
                            <MonetizationOnIcon
                                className={clsx(
                                    styleClasses.small,
                                    classSeats[n].invoiceAmount >
                                        classSeats[n].invoiceAmountPaid
                                        ? styleClasses.red
                                        : styleClasses.green,
                                )}
                            />
                            {`${classSeats[n].invoiceAmount}`}
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <ActiveStateChips
                            accreditations={
                                classInstance.class_accreditations || []
                            }
                            activeStates={classSeats[n].seat.states.map(
                                (s) => s.id,
                            )}
                            group
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <PreviousCertField
                            editingSeat={classSeats[n].seat}
                            invoiceId={classSeats[n].invoiceId}
                            disableEdit={
                                !verifyAdmin(user) &&
                                classEndDate.valueOf() + twoWeeks < Date.now()
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <DownloadInvoice invoiceId={classSeats[n].invoiceId} />
                    </Grid>
                </Grid>,
            );
        }
    }
    /* eslint-disable no-plusplus */

    return (
        <div {...attrs} className={styleClasses.marginVert}>
            {students}
        </div>
    );
}
