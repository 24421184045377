import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import User from '../user/type';

const adminAdapter = createEntityAdapter<User>();

const slice = createSlice({
    name: 'admin',
    initialState: adminAdapter.getInitialState(),
    reducers: {
        adminAddOne: adminAdapter.addOne,
        adminAddMany: adminAdapter.addMany,
        adminUpdate: adminAdapter.updateOne,
        adminRemove: adminAdapter.removeOne,
    },
});

export const adminSelectors = adminAdapter.getSelectors(
    (state: any) => state.admins,
);

export const { actions, reducer } = slice;
