import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ClassViewSessions from '../ClassViewSessions';
import ClassViewStudents from '../ClassViewStudents';
import useQuery from '../../../utils/useQuery';
import { NotificationStateChips } from '../../StateChipLists';

import Class from '../../../store/entities/class/type';
import User from '../../../store/entities/user/type';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { classSelectors } from '../../../store/entities/class';
import { trainerSelectors } from '../../../store/entities/trainer';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        title: {
            flexGrow: 1,
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        bold: {
            fontWeight: 'bold',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    user: User;
}

export default function ClassAdminView(props: Props) {
    const styleClasses = useStyles();
    const { className, user, ...attrs } = props;
    const query = useQuery();
    const classId = parseInt(query.get('class_id') || '0', 10);
    const invoices = useSelector(invoiceSelectors.selectAll);
    const thisClassInvoices = invoices.filter(
        (inv) => inv.seats[0]?.class_id === classId,
    );
    const classFetched = useSelector((state) =>
        classSelectors.selectById(state, classId),
    );

    const principalTrainer = useSelector((state) =>
        trainerSelectors.selectById(
            state,
            classFetched?.principal_trainer_id || 0,
        ),
    );
    const courseNumber = classFetched?.id;
    const invoiceTotal = thisClassInvoices
        .map((inv) => inv.amount)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const invoicePaid = thisClassInvoices
        .map((inv) => inv.paid_amount)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const invoiceDue = invoiceTotal - invoicePaid;

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Grid
                container
                justify="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item>
                    <Typography
                        align="left"
                        variant="h6"
                        className={styleClasses.title}
                    >
                        {classFetched?.course?.name}
                    </Typography>
                    <Typography
                        align="left"
                        variant="body2"
                        className={styleClasses.bold}
                    >{`#${courseNumber}`}</Typography>
                </Grid>
                <Grid item>
                    <Typography
                        align="left"
                        variant="h6"
                        className={styleClasses.title}
                    >
                        Class Billing Status
                    </Typography>
                    <Typography variant="body2">
                        {`Total Due: $${invoiceTotal}`}
                    </Typography>
                    <Typography variant="body2">
                        {`Balance Remaining: $${invoiceDue}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={styleClasses.bold}>
                        Class accreditations
                    </div>
                    <NotificationStateChips
                        accreditations={
                            classFetched?.class_accreditations || []
                        }
                        notifications={classFetched?.notifications || []}
                    />
                </Grid>
            </Grid>
            <ClassViewSessions
                classInstance={classFetched || ({} as Class)}
                principalTrainer={principalTrainer}
            />
            <ClassViewStudents
                classInstance={classFetched || ({} as Class)}
                user={user}
            />
        </div>
    );
}
