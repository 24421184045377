import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Course from './type';

const courseAdapter = createEntityAdapter<Course>();

const slice = createSlice({
    name: 'course',
    initialState: courseAdapter.getInitialState(),
    reducers: {
        courseAddOne: courseAdapter.addOne,
        courseAddMany: courseAdapter.addMany,
        courseUpdate: courseAdapter.updateOne,
        courseRemove: courseAdapter.removeOne,
    },
});

export const courseSelectors = courseAdapter.getSelectors(
    (state: any) => state.courses,
);

export const { actions, reducer } = slice;
