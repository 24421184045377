import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import client from '../../client';

const name = 'auth';

interface LoginPayload {
    username: string;
    password: string;
}
export const login = createAsyncThunk(
    `${name}/login`,
    async ({ username, password }: LoginPayload) => {
        const loginResponse = await client.post('/api/login', {
            username,
            password,
        });
        client.interceptors.request.use((config) => {
            const bearerToken = loginResponse.data.token;
            /* eslint-disable no-param-reassign */
            config.headers.Authorization = `Bearer ${bearerToken.slice(
                bearerToken.indexOf('|') + 1,
            )}`;
            return config;
        });
        // Backend login attempt
        axios
            .post(`${window.location.origin}/beLogin`, {
                username,
                password,
            })
            .catch(
                (error) =>
                    new Error(
                        error instanceof Error ? error.message : `${error}`,
                    ),
            );
        const response2 = await client.get('/api/user');
        return response2.data;
    },
);

const slice = createSlice({
    name,
    initialState: {
        user: null,
    },
    reducers: {},
    extraReducers: (builder) =>
        builder.addCase(login.fulfilled, (state, action) => {
            /* eslint-disable no-param-reassign */
            state.user = action.payload;
        }),
});

export const { reducer } = slice;
