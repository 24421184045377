import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import Notification from '../../../store/entities/notification/type';
import State from '../../../store/entities/state/type';
import { stateSelectors } from '../../../store/entities/state';

function notificationDetails(notifications: Notification[]): {
    id: number;
    state: State;
    type: string;
    status: string;
    date: string;
}[] {
    if (!notifications.length) {
        return [];
    }

    const stateFetched = useSelector((state) =>
        stateSelectors.selectById(state, notifications[0].state_id),
    );

    if (!stateFetched) {
        return [];
    }

    // META relevant notification types by id:
    //     6 === initial
    //     7 === change
    //     8 === cancel
    const notifList = notifications
        .filter(
            (n) => n.notification_types_id > 5 && n.notification_types_id < 9,
        )
        .map((n) => {
            const response = {
                id: n.id,
                state: stateFetched,
                type: 'No Notifications Found',
                status: '',
                date: '',
            };

            switch (n.notification_types_id) {
                case 6:
                    response.type = 'Initial notification';
                    break;
                case 7:
                    response.type = 'Change notification';
                    break;
                case 8:
                    response.type = 'Cancel notification';
                    break;
                default:
                    response.type = 'No Notifications Found';
            }

            switch (n.status) {
                case 'CREATED':
                    response.status = 'scheduled on';
                    response.date = new Date(
                        n.scheduled_at,
                    ).toLocaleDateString();
                    break;
                case 'CANCELLED':
                    response.status = 'cancelled';
                    response.date = new Date(n.updated_at).toLocaleDateString();
                    break;
                default:
                    response.status = 'sent on';
                    response.date = new Date(
                        n.scheduled_at,
                    ).toLocaleDateString();
            }

            return response;
        });

    return notifList;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notifications: Notification[];
    onConfirm?: () => void;
}

export default function NotificationHistoryDialog({
    open,
    setOpen,
    notifications,
    onConfirm = () => {},
}: Props) {
    function handleClose(): void {
        setOpen(false);
    }
    const notifList = notificationDetails(notifications);
    const title = notifList.length
        ? `Notification History for ${notifList[0].state.name}`
        : 'No Notifications found';

    const notifContent = notifList.map((n) => (
        <Grid
            container
            key={`notification-${n.id}`}
            justify="flex-start"
            alignItems="center"
            spacing={1}
        >
            <Grid item>{n.type}</Grid>
            <Grid item>{n.status}</Grid>
            <Grid item>{n.date}</Grid>
        </Grid>
    ));

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="notif-history-dialog-title"
            aria-describedby="notif-history-description"
            maxWidth="md"
        >
            <DialogTitle id="notif-history-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>{notifContent}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onConfirm();
                        handleClose();
                    }}
                    color="primary"
                    id="notif-history-dialog-button-confirm"
                    variant="contained"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
NotificationHistoryDialog.defaultProps = {
    onConfirm: () => {},
};
