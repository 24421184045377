import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface ConfirmDeleteDialogProps extends React.HTMLProps<HTMLDivElement> {
    onConfirm: () => void;
    content: string;
    title?: string;
}

const ConfirmDeleteDialog: React.FunctionComponent<
    ConfirmDeleteDialogProps
> = ({ onConfirm, content, title }) => {
    const [open, setOpen] = React.useState(false);

    function handleClose(): void {
        setOpen(false);
    }

    return (
        <>
            <Tooltip title="Delete Entry">
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setOpen(true)}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id="confirm-dialog-button-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            handleClose();
                        }}
                        color="primary"
                        autoFocus
                        id="confirm-dialog-button-confirm"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

ConfirmDeleteDialog.defaultProps = {
    title: '',
};

export default ConfirmDeleteDialog;
