import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Button,
    Grid,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { ToggleStateChips } from '../../StateChipLists';

import Seat from '../../../store/entities/seat/type';
import User from '../../../store/entities/user/type';
import Accreditation from '../../../store/entities/accreditation/type';
import { studentSelectors } from '../../../store/entities/student';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        componentTitle: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        componentDelete: {
            'margin-right': theme.spacing(1),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    seatsInfo: Seat[];
    setSeatsInfo: React.Dispatch<React.SetStateAction<Seat[]>>;
    accreditations: Accreditation[] | undefined;
    openClass: Boolean;
}

export default function SeatInformation({
    seatsInfo,
    setSeatsInfo,
    accreditations,
    openClass,
    className,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const seatSections: JSX.Element[] = [];
    const students = useSelector(studentSelectors.selectAll);
    const filteredStudents = students.filter(
        (s: User) =>
            !s.deactivated_at &&
            !seatsInfo
                .filter((value) => !!value.user_id)
                .map((value) => value.user_id)
                .includes(s.id),
    );
    const studentOptions = filteredStudents.map((s: any) => ({
        label: s.full_name,
        id: s.id,
        username: s.username,
    }));

    /* eslint-disable no-plusplus */
    for (let i: number = 0; i < seatsInfo.length; i++) {
        const thisStudent = students.find(
            (s: User) => s.id === seatsInfo[i].user_id,
        );
        const studentInfo = thisStudent
            ? {
                  ssn_ending: thisStudent.ssn_last_four_digits
                      ? `${thisStudent.ssn_last_four_digits}`.slice(-4)
                      : '####',
                  full_name: thisStudent.full_name,
              }
            : undefined;
        seatSections.push(
            <Paper key={`seat-${i + 1}`}>
                {studentInfo ? (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        spacing={2}
                    >
                        <Grid item xs={1}>
                            Seat #: {i + 1}
                        </Grid>
                        <Grid item xs={1}>
                            SSN #: {studentInfo.ssn_ending}
                        </Grid>
                        <Grid item xs={3}>
                            {studentInfo.full_name}
                        </Grid>
                        <Grid item xs={1}>
                            Certifications:
                        </Grid>
                        <Grid item xs={3}>
                            <ToggleStateChips
                                accreditations={accreditations || []}
                                seatsInfo={seatsInfo}
                                setSeatsInfo={setSeatsInfo}
                                seatIndex={i}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label="Previous Certification"
                                variant="outlined"
                                size="small"
                                value={seatsInfo[i].previous_certification}
                                onChange={(event) => {
                                    const newSeats = [...seatsInfo];
                                    newSeats[i].previous_certification =
                                        event.target.value;
                                    setSeatsInfo(newSeats);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Delete this seat">
                                <IconButton
                                    className={styleClasses.componentDelete}
                                    aria-label="delete"
                                    color="default"
                                    onClick={() => {
                                        // Removes the current seat from the seatsInfo array
                                        const newSeats = [...seatsInfo];
                                        newSeats.splice(i, 1);
                                        setSeatsInfo(newSeats);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        spacing={2}
                    >
                        <Grid item xs={1}>
                            Seat #: {i + 1}
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                id={`seat-${i + 1}-student-picker`}
                                style={{ width: 300 }}
                                options={studentOptions}
                                getOptionLabel={(option) =>
                                    `${option.label} (${option.username})`
                                }
                                onChange={(event, newStudent) => {
                                    if (newStudent) {
                                        const newSeats = [...seatsInfo];
                                        newSeats[i].user_id = newStudent.id;
                                        setSeatsInfo(newSeats);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Student"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Link
                                to={{
                                    pathname: '/users',
                                    search: '?user_type=student',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="/users?user_type=student"
                                >
                                    New Student
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Delete this seat">
                                <IconButton
                                    className={styleClasses.componentDelete}
                                    aria-label="delete"
                                    color="default"
                                    onClick={() => {
                                        // Removes the current seat from the seatsInfo array
                                        const newSeats = [...seatsInfo];
                                        newSeats.splice(i, 1);
                                        setSeatsInfo(newSeats);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </Paper>,
        );
    }
    /* eslint-disable no-plusplus */

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Grid container className={styleClasses.titleContainer}>
                <Grid item className={styleClasses.title}>
                    <Typography variant="h6" component="h2">
                        {`Class Seats (Enrollment: ${
                            openClass ? 'Open' : 'Closed'
                        } Class)`}
                    </Typography>
                </Grid>
                <Grid item className={styleClasses.buttonGroup}>
                    <Tooltip title="Add New Seat">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                // Adds a new blank seat to 'seatsInfo' array
                                const newSeats = [...seatsInfo];
                                newSeats.push({
                                    user_id: 0,
                                    class_id: 0,
                                    states: [],
                                    previous_certification: 'none',
                                });
                                setSeatsInfo(newSeats);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {seatSections}
        </div>
    );
}
