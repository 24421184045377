import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Location from './type';

const locationAdapter = createEntityAdapter<Location>();

const slice = createSlice({
    name: 'location',
    initialState: locationAdapter.getInitialState(),
    reducers: {
        locationAddOne: locationAdapter.addOne,
        locationAddMany: locationAdapter.addMany,
        locationUpdate: locationAdapter.updateOne,
        locationRemove: locationAdapter.removeOne,
    },
});

export const locationSelectors = locationAdapter.getSelectors(
    (state: any) => state.locations,
);

export const { actions, reducer } = slice;
