import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Address from './type';

const addressAdapter = createEntityAdapter<Address>();

const slice = createSlice({
    name: 'address',
    initialState: addressAdapter.getInitialState(),
    reducers: {
        addressAddOne: addressAdapter.addOne,
        addressAddMany: addressAdapter.addMany,
        addressUpdate: addressAdapter.updateOne,
        addressRemove: addressAdapter.removeOne,
    },
});

export const addressSelectors = addressAdapter.getSelectors(
    (state: any) => state.addresses,
);

export const { actions, reducer } = slice;
