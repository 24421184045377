import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Copyright from '../../components/Copyright';
import SeatsInputForm from '../../components/SeatsForm/SeatsInputForm';

import Client from '../../client';
import User from '../../store/entities/user/type';
import { actions as classActions } from '../../store/entities/class';
import { actions as companyActions } from '../../store/entities/company';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as studentActions } from '../../store/entities/student';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export interface Props extends React.HTMLProps<HTMLDivElement> {
    user: User;
}

export default function SeatsForm({ user, ...props }: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className } = props;

    useEffect(() => {
        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadClasses() {
            const response = await Client.get('/api/classes');
            await dispatch(classActions.classAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        async function loadCompanies() {
            const response = await Client.get('/api/companies');
            await dispatch(companyActions.companyAddMany(response.data));
        }

        async function loadStudents() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
        }

        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        loadInvoices();
        loadLocations();
        loadClasses();
        loadCompanies();
        loadStudents();
        loadStates();
    }, []);

    return (
        <div className={clsx(styleClasses.root, className)}>
            <Container component="main" maxWidth="lg">
                <div className={styleClasses.title}>Seats Form</div>
                <SeatsInputForm currentUser={user} />
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
