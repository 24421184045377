import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ActiveStateChips } from '../../StateChipLists';

import Invoice from '../../../store/entities/invoice/type';
import Class from '../../../store/entities/class/type';
import User from '../../../store/entities/user/type';
import { locationSelectors } from '../../../store/entities/location';
import { addressSelectors } from '../../../store/entities/address';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        small: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        paper: {
            padding: theme.spacing(1),
            margin: 'auto',
        },
        map: {
            width: 250,
            height: 250,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    invoiceInstance: Invoice;
    seatStudents: User[];
    classInstance: Class;
}

export default function InvoiceDetails(props: Props) {
    const styleClasses = useStyles();
    const {
        className,
        invoiceInstance,
        seatStudents,
        classInstance,
        ...attrs
    } = props;
    const classLocation = classInstance.sessions?.[0]?.location;
    const classStartDate = new Date(classInstance.sessions?.[0]?.date_start);
    const companyLocation = useSelector((state) =>
        locationSelectors.selectById(
            state,
            invoiceInstance.company?.location_id || 0,
        ),
    );
    const studentAddress = useSelector((state) =>
        addressSelectors.selectById(state, seatStudents[0].address_id || 0),
    );
    const invoiceClient = {
        name: '',
        addressLine1: '',
        addressLine2: '',
        contactInfo: '',
    };
    if (invoiceInstance.company) {
        invoiceClient.name = invoiceInstance.company.name;
        invoiceClient.addressLine1 = companyLocation?.address_1 ?? '';
        invoiceClient.addressLine2 = companyLocation
            ? `${companyLocation.city}, ${companyLocation.state}`
            : '';
        invoiceClient.contactInfo = `${invoiceInstance.company.contact_name} ${invoiceInstance.company.primary_email}`;
    } else {
        invoiceClient.name = `${seatStudents[0].first_name} ${seatStudents[0].last_name}`;
        invoiceClient.addressLine1 = studentAddress?.address1 ?? '';
        invoiceClient.addressLine2 = studentAddress
            ? `${studentAddress.locality}, ${studentAddress.administrative_area}`
            : '';
        invoiceClient.contactInfo = `${seatStudents[0].email}`;
    }
    const students = invoiceInstance.seats.map((seat, index) => {
        const student = seatStudents[index];
        return (
            <Grid
                container
                key={`student-${seat.user_id}`}
                justify="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={1}>
                    <Avatar
                        className={styleClasses.small}
                        key={`avatar-${student.last_name}-${seat.user_id}-invoice-${invoiceInstance.id}`}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="caption" display="block">
                        {`${student.first_name} ${student.last_name}`}
                    </Typography>
                    <Typography variant="caption" display="block">
                        {`SSN: ${
                            student.ssn_last_four_digits
                                ? `${student.ssn_last_four_digits}`.slice(-4)
                                : '####'
                        }`}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <ActiveStateChips
                        accreditations={
                            classInstance.class_accreditations || []
                        }
                        activeStates={seat.states.map((s) => s.id)}
                    />
                </Grid>
            </Grid>
        );
    });

    return (
        <div {...attrs} className={styleClasses.root}>
            <Paper className={styleClasses.paper}>
                <Grid container spacing={1} justify="flex-start">
                    <Grid item xs={3} direction="row">
                        <Typography>Client details</Typography>
                        <Typography variant="body2">
                            {invoiceClient.name}
                        </Typography>
                        <Typography variant="body2">
                            {invoiceClient.addressLine1}
                        </Typography>
                        <Typography variant="body2">
                            {invoiceClient.addressLine2}
                        </Typography>
                        <Typography variant="body2">
                            {invoiceClient.contactInfo}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} direction="row">
                        <Typography>Class details</Typography>
                        <Typography variant="body2">
                            {`Date: ${classStartDate.toLocaleDateString()}`}
                        </Typography>
                        <Typography variant="body2">
                            {`Location: ${classLocation.addressee}`}
                        </Typography>
                        <Typography variant="body2">
                            {`          ${classLocation.address_1} ${
                                classLocation.address_2 ?? ''
                            }`}
                        </Typography>
                        <Typography variant="body2">
                            {`          ${classLocation.city}, ${classLocation.state}, ${classLocation.zip_code}`}
                        </Typography>
                        {classLocation.contact_name && (
                            <Typography variant="body2">
                                {`          ${classLocation.contact_name} ${classLocation.contact_phone}`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        spacing={1}
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                    >
                        {students}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
