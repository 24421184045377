import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
            width: '100%',
            paddingBottom: '.25em',
            textAlign: 'center',
        },
    }),
);

export interface MultiFieldErrorMessageProps
    extends React.HTMLProps<HTMLDivElement> {
    message: string;
}

export default function MultiFieldErrorMessage(
    props: MultiFieldErrorMessageProps,
) {
    const classes = useStyles();
    const { message } = props;
    return <div className={classes.error}>{message}</div>;
}
