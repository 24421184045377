import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import FormSessionTime from '../FormSessionTime';
import StateSelector from '../../StateSelector';
import SessionTrainerList from '../SessionTrainerList';
import FormTrainingLocation from '../FormTrainingLocation';
import { HybridSession } from '../utils';

import Location from '../../../store/entities/location/type';
import { trainerSelectors } from '../../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        componentTitle: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        componentDelete: {
            'margin-right': theme.spacing(1),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    sessionsInfo: HybridSession[];
    setSessionsInfo: React.Dispatch<React.SetStateAction<HybridSession[]>>;
    principalTrainer: number;
    setPrincipalTrainer: React.Dispatch<React.SetStateAction<number>>;
}

export default function FormSessions({
    sessionsInfo,
    setSessionsInfo,
    principalTrainer,
    setPrincipalTrainer,
    className,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const trainers = useSelector(trainerSelectors.selectAll);
    const sessionSections: JSX.Element[] = [];

    const trainerSelectorOptions = trainers
        .filter((t) => !t.user.deactivated_at)
        .map((t) => ({
            label: t.user.full_name, // Name shown on dropdown
            id: `${t.id}`, // Internal id for form (string)
        }));

    function addNewSession() {
        // Adds a new blank hybrid session to 'sessionsInfo' array
        const sessionNum = sessionsInfo.length;
        const blankSessionInfo: HybridSession = {
            webinar: false,
            webinarUrl: '',
            webinarMeetingId: '',
            webinarPasscode: '',
            session: {
                date_start: new Date(new Date().setHours(8, 0, 0, 0)),
                date_end: new Date(new Date().setHours(16, 0, 0, 0)),
                location_id: null,
                trainers: [],
                location: {} as Location,
                location_confirmed: false,
            },
        };
        // If a session is already defined, new session will be
        // scheduled for the next consecutive business day.
        if (sessionNum) {
            const lastStartDate = new Date(
                sessionsInfo[sessionNum - 1].session.date_start,
            );
            const lastEndDate = new Date(
                sessionsInfo[sessionNum - 1].session.date_end,
            );
            // Skips weekends
            const daysToAdd =
                lastStartDate.getDay() > 4 ? 8 - lastStartDate.getDay() : 1;
            blankSessionInfo.session.date_start = new Date(
                lastStartDate.setDate(lastStartDate.getDate() + daysToAdd),
            );
            blankSessionInfo.session.date_end = new Date(
                lastEndDate.setDate(lastEndDate.getDate() + daysToAdd),
            );
        }

        const newSessionsInfo = [...sessionsInfo];
        newSessionsInfo.push(blankSessionInfo);
        setSessionsInfo(newSessionsInfo);
    }

    function duplicateSession(thisSessionInfo: HybridSession) {
        // Duplicates the current session to the sessions array
        // Increments date of last session by 1 business day
        const newSessionsInfo = [...sessionsInfo];
        const newSession = {
            ...thisSessionInfo,
            session: {
                ...thisSessionInfo.session,
                trainers: [...thisSessionInfo.session.trainers],
            },
        };
        const startDate = new Date(
            sessionsInfo[sessionsInfo.length - 1].session.date_start,
        );
        const endDate = new Date(
            sessionsInfo[sessionsInfo.length - 1].session.date_end,
        );
        // Skips weekends
        const daysToAdd = startDate.getDay() > 4 ? 8 - startDate.getDay() : 1;
        newSession.session.date_start = new Date(
            startDate.setDate(startDate.getDate() + daysToAdd),
        );
        newSession.session.date_end = new Date(
            endDate.setDate(endDate.getDate() + daysToAdd),
        );
        newSessionsInfo.push(newSession);
        setSessionsInfo(newSessionsInfo);
    }

    /* eslint-disable no-plusplus */
    for (let i: number = 0; i < sessionsInfo.length; i++) {
        const thisSessionInfo = sessionsInfo[i];
        sessionSections.push(
            <Paper key={`session-${i + 1}`}>
                <div className={styleClasses.titleContainer}>
                    <Typography
                        variant="h6"
                        className={styleClasses.componentTitle}
                    >
                        Session {i + 1}
                    </Typography>
                    <Tooltip title="Duplicate this session">
                        <IconButton
                            className={styleClasses.componentDelete}
                            aria-label="duplicate"
                            color="default"
                            onClick={() => {
                                duplicateSession(thisSessionInfo);
                            }}
                        >
                            <FilterNoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete this session">
                        <IconButton
                            className={styleClasses.componentDelete}
                            aria-label="delete"
                            color="default"
                            onClick={() => {
                                // Removes the current session from the sessions array
                                const newSessionsInfo = [...sessionsInfo];
                                newSessionsInfo.splice(i, 1);
                                setSessionsInfo(newSessionsInfo);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <FormSessionTime
                            sessionIndex={i}
                            sessionsInfo={sessionsInfo}
                            setSessionsInfo={setSessionsInfo}
                        />
                    </Grid>
                    <Grid item md={5} sm={6} xs={12}>
                        <FormTrainingLocation
                            sessionIndex={i}
                            sessionsInfo={sessionsInfo}
                            setSessionsInfo={setSessionsInfo}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <SessionTrainerList
                            principalTrainer={principalTrainer}
                            sessionTrainers={thisSessionInfo.session.trainers.map(
                                (t) => t.id,
                            )}
                            onRemoveTrainer={(trainerId) => {
                                const sessionTrainerIds =
                                    thisSessionInfo.session.trainers.map(
                                        (t) => t.id,
                                    );
                                const index =
                                    sessionTrainerIds.indexOf(trainerId);
                                const newSessionsInfo = [...sessionsInfo];
                                newSessionsInfo[i].session.trainers.splice(
                                    index,
                                    1,
                                );
                                setSessionsInfo(newSessionsInfo);
                                // If principal trainer removed and doesn't appear on other sessions
                                // then set principal trainer value to 0
                                const classTrainerIds: number[] = [];
                                sessionsInfo
                                    .map(({ session }) => session)
                                    .forEach((s) =>
                                        s.trainers.forEach((t) =>
                                            classTrainerIds.push(t.id),
                                        ),
                                    );
                                if (
                                    trainerId === principalTrainer &&
                                    !classTrainerIds.includes(principalTrainer)
                                ) {
                                    setPrincipalTrainer(0);
                                }
                            }}
                            onPrincipalTrainer={(trainerId) => {
                                const sessionTrainerIds =
                                    thisSessionInfo.session.trainers.map(
                                        (t) => t.id,
                                    );
                                const index =
                                    sessionTrainerIds.indexOf(trainerId);
                                const newSessionsInfo = [...sessionsInfo];
                                const trainer = newSessionsInfo[
                                    i
                                ].session.trainers.splice(index, 1);
                                newSessionsInfo[i].session.trainers.unshift(
                                    trainer[0],
                                );
                                setPrincipalTrainer(trainerId);
                                setSessionsInfo(newSessionsInfo);
                            }}
                        />
                        <StateSelector
                            options={trainerSelectorOptions}
                            label="Select trainers for this session"
                            makeOptionLabel={(option) => option.id}
                            makeOptionDisabled={(option) =>
                                thisSessionInfo.session.trainers.some(
                                    (t) => `${t.id}` === option.id,
                                )
                            }
                            values={thisSessionInfo.session.trainers.map(
                                (t) => {
                                    const trainerFound = trainers.find(
                                        ({ id }) => id === t.id,
                                    );
                                    const fullName = trainerFound
                                        ? trainerFound.user.full_name
                                        : 'no Trainer found';
                                    return {
                                        label: fullName,
                                        id: `${t.id}`,
                                    };
                                },
                            )}
                            updateData={(value: any) => {
                                const newId = parseInt(value.abbreviation, 10);
                                const trainerFound = trainers.find(
                                    ({ id }) => id === newId,
                                );
                                if (trainerFound) {
                                    const newSessionsInfo = [...sessionsInfo];
                                    newSessionsInfo[i].session.trainers.push(
                                        trainerFound,
                                    );
                                    setSessionsInfo(newSessionsInfo);
                                    // If there are no other current trainers
                                    // then this trainer becomes principalTrainer
                                    const classTrainerIds: number[] = [];
                                    sessionsInfo
                                        .map(({ session }) => session)
                                        .forEach((s) =>
                                            s.trainers.forEach((t) =>
                                                classTrainerIds.push(t.id),
                                            ),
                                        );
                                    if (classTrainerIds.length < 2) {
                                        setPrincipalTrainer(newId);
                                    }
                                }
                            }}
                            placeholderText=""
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </Paper>,
        );
    }
    /* eslint-disable no-plusplus */

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Paper id="training-location">
                <Grid
                    container
                    justifyContent="space-between"
                    className={styleClasses.titleContainer}
                >
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Class Sessions
                        </Typography>
                    </Grid>
                    <Grid item className={styleClasses.buttonGroup}>
                        <Tooltip title="Add New Session">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    addNewSession();
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {sessionSections}
            </Paper>
        </div>
    );
}
