import { configureStore } from '@reduxjs/toolkit';

import { reducer as auth } from './auth';
import { reducer as addresses } from './entities/address';
import { reducer as admins } from './entities/admin';
import { reducer as classes } from './entities/class';
import { reducer as companies } from './entities/company';
import { reducer as courses } from './entities/course';
import { reducer as invoices } from './entities/invoice';
import { reducer as languages } from './entities/language';
import { reducer as locations } from './entities/location';
import { reducer as states } from './entities/state';
import { reducer as students } from './entities/student';
import { reducer as trainers } from './entities/trainer';

const store = configureStore({
    reducer: {
        auth,
        addresses,
        admins,
        classes,
        companies,
        courses,
        invoices,
        languages,
        locations,
        states,
        students,
        trainers,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

type RootState = ReturnType<typeof store.getState>;

declare module 'react-redux' {
    interface DefaultRootState extends RootState {}
}

export default store;
