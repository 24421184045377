import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface PickerOption {
    label: string;
    id: number | string;
}

export interface FormAutocompleteProps extends React.HTMLProps<HTMLDivElement> {
    options: PickerOption[];
    label: string;
    control: any;
    name: string;
    defaultValue?: string;
    validate?: { [key: string]: any };
    width?: string | number;
    fieldSize?: 'small' | 'medium';
    margin?: 'normal' | 'dense';
}

export default function FormAutocomplete({
    options,
    label,
    control,
    name,
    defaultValue = '',
    validate = {},
    width = 300,
    fieldSize = 'small',
    margin = undefined,
}: FormAutocompleteProps) {
    return (
        <Controller
            render={({ field, fieldState }) => (
                <Autocomplete
                    id={`form-autocomplete-${name}`}
                    style={{ width }}
                    options={options}
                    onChange={(event, data) => field.onChange(data?.id)}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            size={fieldSize}
                            variant="outlined"
                            margin={margin}
                            error={!!fieldState.error}
                        />
                    )}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={validate}
        />
    );
}

FormAutocomplete.defaultProps = {
    defaultValue: '',
    validate: {},
    width: 300,
    fieldSize: 'small',
    margin: undefined,
};
