import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';

import FormField from '../FormField';
import FormAutocomplete from '../FormAutocomplete';
import TrainerInputForm from '../TrainerInputForm';

import Client from '../../client';
import Company from '../../store/entities/company/type';
import { locationSelectors } from '../../store/entities/location';
import {
    actions as companyActions,
    companySelectors,
} from '../../store/entities/company';
import { studentSelectors } from '../../store/entities/student';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    sub_title: {
        marginTop: theme.spacing(4),
        fontSize: 24,
        width: '100%',
    },
    form_content: {
        fontSize: 18,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface EditClientProps extends React.HTMLProps<HTMLDivElement> {
    clientId: number;
    clientType: 'Company' | 'Individual';
}

const EditClient: React.FunctionComponent<EditClientProps> = ({
    clientId,
    clientType,
}) => {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const locations = useSelector(locationSelectors.selectAll);
    const locationSelectorOptions = locations
        .filter((loc) => !loc.addressee.toUpperCase().includes('ZOOM.US'))
        .map((loc) => ({
            id: loc.id,
            label: `${loc.addressee}: ${loc.address_1} ${loc.city}, ${loc.state}`,
        }));
    const content = 'Edit the fields below to make changes.';
    const form = useForm();
    const { control } = form;
    const companyData = useSelector((state) =>
        companySelectors.selectById(
            state,
            clientType === 'Company' ? clientId : 0,
        ),
    );
    const studentData = useSelector((state) =>
        studentSelectors.selectById(
            state,
            clientType === 'Individual' ? clientId : 0,
        ),
    );

    function resetForm(companyInfo: Company) {
        form.reset({
            name: companyInfo.name,
            contact_name: companyInfo.contact_name,
            location_id: companyInfo.location_id,
            primary_email: companyInfo.primary_email,
            phone: companyInfo.phone,
            secondary_email_name: companyInfo.secondary_email_name,
            secondary_email: companyInfo.secondary_email,
            secondary_phone: companyInfo.secondary_phone,
        });
    }

    function handleClose(): void {
        setOpen(false);
    }

    async function onSubmit(data: any) {
        let payload: Partial<Company> = (({
            name,
            contact_name,
            location_id,
            primary_email,
            phone,
        }) => ({
            name,
            contact_name,
            location_id,
            primary_email,
            phone,
            active: true,
        }))(data);

        if (data.secondary_email_name) {
            payload = {
                ...payload,
                secondary_email_name: data.secondary_email_name,
            };
        }

        if (data.secondary_email) {
            payload = {
                ...payload,
                secondary_email: data.secondary_email,
            };
        }

        if (data.secondary_phone) {
            payload = {
                ...payload,
                secondary_phone: data.secondary_phone,
            };
        }

        await Client.put(`/api/companies/${clientId}`, payload).then((resp) => {
            if (resp.data) {
                dispatch(
                    companyActions.companyUpdate({
                        id: clientId,
                        changes: {
                            ...payload,
                        },
                    }),
                );
            }
        });
        await form.reset({});
        handleClose();
    }

    const editCompanyForm: JSX.Element = (
        <FormProvider {...form}>
            <form
                className={styleClasses.form}
                // Submits current form without unintended submission of main from
                onSubmit={(e) => {
                    e.stopPropagation();
                    return form.handleSubmit(onSubmit)(e);
                }}
            >
                <DialogContent>
                    <DialogContentText id="edit-client-dialog-description">
                        {content}
                    </DialogContentText>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="name"
                                name="name"
                                label="Company Name"
                                validate={{
                                    required: 'Name required.',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="contact_name"
                                name="contact_name"
                                label="Attn:"
                                validate={{
                                    required: 'Name required.',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <FormAutocomplete
                                options={locationSelectorOptions}
                                label="Choose a company location *"
                                control={control}
                                name="location_id"
                                validate={{ required: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="email"
                                name="primary_email"
                                label="Email"
                                validate={{
                                    required: 'Email required.',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="phone"
                                name="phone"
                                label="Phone"
                                validate={{
                                    required: 'Phone required.',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="secondary_email_name"
                                name="secondary_email_name"
                                label="Secondary Contact"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="secondary_email"
                                name="secondary_email"
                                label="Secondary Email"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="secondary_phone"
                                name="secondary_phone"
                                label="Secondary Phone"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id="edit-client-button-cancel"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        id="edit-client-button-confirm"
                        variant="contained"
                    >
                        Confirm Changes
                    </Button>
                </DialogActions>
            </form>
        </FormProvider>
    );
    // Have dialog display company form or TrainerInputForm in edit mode (pass trainerId={clientId})
    return (
        <>
            <Tooltip title="Edit Client Data">
                <IconButton
                    edge="end"
                    aria-label="edit-client"
                    size="small"
                    onClick={() => {
                        if (companyData) {
                            resetForm(companyData);
                        }
                        setOpen(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="edit-client-dialog-title"
                aria-describedby="edit-client-dialog-description"
                maxWidth="lg"
            >
                {companyData ? (
                    editCompanyForm
                ) : (
                    <TrainerInputForm
                        trainerId={clientId}
                        oldTrainer={studentData}
                        onConfirm={() => handleClose()}
                        onCancel={() => handleClose()}
                    />
                )}
            </Dialog>
        </>
    );
};

export default EditClient;
