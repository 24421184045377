import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import SmartySDK from 'smartystreets-javascript-sdk';
import axios from 'axios';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ConfirmAddressDialog from './ConfirmAddressDialog';
import FormPicker from '../FormPicker';
import FormField from '../FormField';
import Client from '../../client';
import { actions as locationActions } from '../../store/entities/location';
import { actions as addressActions } from '../../store/entities/address';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        paper: {
            margin: theme.spacing(1),
            width: '100%',
            maxWidth: 740,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '6em',
            padding: '0px 10px',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        cancel: {
            margin: theme.spacing(3, 0, 2),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }),
);

export interface AddressVerificationFormProps
    extends React.HTMLProps<HTMLDivElement> {
    mode?: 'address' | 'location';
    dialogClose?: () => void;
}

export default function AddressVerificationForm({
    mode,
    dialogClose = () => {},
}: AddressVerificationFormProps) {
    const styleClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [addressComponents, setAddressComponents] = React.useState({} as any);
    const dispatch = useDispatch();
    const form = useForm();
    const { reset, setValue, watch } = form;
    const countrySelected: string = watch('country');
    const addressType: string = watch('addressType');

    // ISO-3 codes for supported countries can be found on the Smarty Streets site
    // https://www.smarty.com/docs/cloud/international-street-api#countries
    const countries = [
        { name: 'United States', key: 'USA' },
        { name: 'Republic of Korea', key: 'KOR' },
        { name: 'Germany', key: 'DEU' },
        { name: 'Mexico', key: 'MEX' },
        { name: 'Canada', key: 'CAN' },
    ];

    useEffect(() => {
        setValue('country', 'USA');
        setValue('addressType', mode ?? 'location');
    }, []);

    function resetForm() {
        reset({
            state: '',
            city: '',
            postalCode: '',
            address1: '',
            addressee: '',
            contact_phone: '',
            contact_name: '',
            addressType: mode ?? 'location',
        });
    }

    function confirmButton(): JSX.Element {
        return (
            <Button
                id="address-verification-button-cancel"
                type="submit"
                variant="contained"
                color="primary"
                className={styleClasses.submit}
            >
                Validate Address
            </Button>
        );
    }

    function cancelButton(): JSX.Element {
        return (
            <Button
                id="address-verification-button-cancel"
                onClick={() => {
                    resetForm();
                    dialogClose();
                }}
                color="default"
                variant="contained"
                className={styleClasses.cancel}
            >
                Cancel
            </Button>
        );
    }

    function handleResult(lookup: SmartySDK.internationalStreet.Lookup) {
        if (lookup.result.length === 0) {
            setVerified(false);
        } else {
            const { address1, address2, components, analysis } =
                lookup.result[0];
            setVerified(analysis.addressPrecision === 'DeliveryPoint');
            setAddressComponents({
                ...components,
                address1,
                address2,
            });
        }
        setOpen(true);
    }

    async function onSubmit(data: any) {
        const freeformAddress = [
            data.address1,
            data.city,
            data.state,
            data.postalCode,
        ].join(', ');

        const response = await axios.post(
            `${window.location.origin}/verifyAddress`,
            { addressData: freeformAddress, country: data.country },
        );
        handleResult(response.data.verification);
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <Paper className={styleClasses.paper}>
            <ConfirmAddressDialog
                open={open}
                setOpen={setOpen}
                verified={verified}
                addressComponents={addressComponents}
                onConfirm={async () => {
                    if (!verified) {
                        return;
                    }
                    if (addressType === 'location') {
                        const payload = {
                            address_1: addressComponents.address1,
                            city: addressComponents.locality,
                            state: addressComponents.administrativeArea,
                            zip_code: addressComponents.postalCodeShort,
                            addressee: form.getValues('addressee'),
                            contact_name: form.getValues('contact_name'),
                            contact_phone: form.getValues('contact_phone'),
                        };
                        await Client.post('/api/locations', payload).then(
                            async (resp) => {
                                const locationInfo = await Client.get(
                                    `/api/locations/${resp.data.id}`,
                                );
                                dispatch(
                                    locationActions.locationAddOne(
                                        locationInfo.data,
                                    ),
                                );
                            },
                        );
                    } else if (addressType === 'address') {
                        const payload = {
                            address1: addressComponents.address1,
                            locality: addressComponents.locality,
                            administrative_area:
                                addressComponents.administrativeArea,
                            country: addressComponents.countryIso3,
                            postal_code: addressComponents.postalCodeShort,
                        };
                        await Client.post('/api/addresses', payload).then(
                            async (resp) => {
                                const addressInfo = await Client.get(
                                    `/api/addresses/${resp.data.id}`,
                                );
                                dispatch(
                                    addressActions.addressAddOne(
                                        addressInfo.data.message,
                                    ),
                                );
                            },
                        );
                    }
                    resetForm();
                    dialogClose();
                }}
            />
            <FormProvider {...form}>
                <form
                    className={styleClasses.form}
                    onSubmit={(e) => {
                        e.stopPropagation();
                        return form.handleSubmit(onSubmit)(e);
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormPicker
                                id="address-type-picker"
                                key={`address-type-${addressType}`}
                                value={addressType || 'location'}
                                name="addressType"
                                label="Address Type*"
                                choices={[
                                    {
                                        label: 'Training/Company Location',
                                        id: 'location',
                                    },
                                    {
                                        label: "Individual's Address",
                                        id: 'address',
                                    },
                                ]}
                                fullWidth
                                validate={{ required: true }}
                                disabled={!!mode}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormPicker
                                id="address-verification-country-picker"
                                key={`address-verification-${countrySelected}`}
                                value={countrySelected || 'USA'}
                                name="country"
                                label="Country*"
                                choices={countries.map((e) => ({
                                    label: e.name,
                                    id: e.key,
                                }))}
                                fullWidth
                                validate={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                id="street-address1"
                                name="address1"
                                label="Street Address"
                                autoFocus
                                fullWidth
                                validate={{
                                    required: 'Street address is required.',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                id="city"
                                name="city"
                                label="City/Locality"
                                fullWidth
                                validate={{
                                    required: 'City or locality required.',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                id="state"
                                name="state"
                                label="State/Province"
                                fullWidth
                                validate={{
                                    required: 'State or province required.',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                id="postalCode"
                                name="postalCode"
                                label="Postal Code"
                                fullWidth
                                validate={{
                                    required: 'Postal code required.',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required={addressType === 'location'}
                                id="addressee"
                                name="addressee"
                                label="Location Name"
                                fullWidth
                                disabled={addressType !== 'location'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                id="contact_name"
                                name="contact_name"
                                label="Contact"
                                fullWidth
                                disabled={addressType !== 'location'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                id="contact_phone"
                                name="contact_phone"
                                label="Phone"
                                fullWidth
                                disabled={addressType !== 'location'}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>{confirmButton()}</Grid>
                        <Grid item>{cancelButton()}</Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Paper>
    );
    /* eslint-disable react/jsx-props-no-spreading */
}

AddressVerificationForm.defaultProps = {
    mode: undefined,
    dialogClose: () => {},
};
