import React, { useEffect, useRef } from 'react';

export default function useAbort(deps: React.DependencyList) {
    const controller = useRef(new AbortController());

    useEffect(() => {
        controller.current = new AbortController();
        return () => controller.current.abort();
    }, deps);

    return (promise: Promise<any>) => {
        let aborted = false;

        controller.current.signal.addEventListener('abort', () => {
            aborted = true;
        });

        return new Promise((resolve, reject) => {
            promise
                .then((result) => {
                    if (aborted) return;
                    resolve(result);
                })
                .catch((err) => {
                    if (aborted) return;
                    reject(err);
                });
        });
    };
}
