import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        voidedCheckbox: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: `100%`,
        },
    }),
);

export function VoidedFilterList(filter: string[]): string[] {
    return filter[0] === 'true' ? [`Voided Invoices`] : [];
}
// MUI incorrectly types 'voidedDate' as string when it is in fact a number
export function VoidedFilterLogic(voidedDate: string, filters: string[]) {
    // filters[0] can be:
    // 'true' when checked
    // 'false' when unchecked
    // undefined when filters are cleared
    const valueOfDate = parseInt(voidedDate, 10);
    return filters[0] === 'true' ? valueOfDate <= 0 : valueOfDate > 0;
}

export interface VoidedFilterDisplayProps
    extends React.HTMLProps<HTMLDivElement> {
    filterList: string[][]; // first array is all filters, second is options within each filter
    onChange: any;
    index: number; // use filterList[index] to access filter array for this particular filtering
    column: MUIDataTableColumn;
}

export default function VoidedFilterDisplay({
    filterList,
    onChange,
    index,
    column,
}: VoidedFilterDisplayProps) {
    const styleClasses = useStyles();
    const voidedFilters = filterList[index];

    const handleChange = (event: any) => {
        const newFilters = [...voidedFilters];
        newFilters[0] = `${event.target.checked}`;
        onChange(newFilters, index, column);
    };

    return (
        <FormGroup row>
            <div className={styleClasses.voidedCheckbox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={voidedFilters[0] === 'true'}
                            color="primary"
                            onChange={handleChange}
                            name="voided"
                        />
                    }
                    label="Display Voided Invoices Only"
                    labelPlacement="start"
                />
            </div>
        </FormGroup>
    );
}
