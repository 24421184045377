import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import Client from '../../client';
import Course from '../../store/entities/course/type';
import {
    actions as courseActions,
    courseSelectors,
} from '../../store/entities/course';
import { stateSelectors } from '../../store/entities/state';

import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import CourseInputForm from '../CourseInputForm';

export interface Props extends React.HTMLProps<HTMLDivElement> {
    editId?: number;
    updateId?: (id: number) => void;
}

export default function CourseList({
    editId = 0,
    updateId = undefined,
}: Props) {
    const dispatch = useDispatch();
    const courses = useSelector(courseSelectors.selectAll);
    const stateList = useSelector(stateSelectors.selectAll);

    return (
        <List>
            {courses.length > 0 &&
                courses.map((course: Course) => {
                    const courseInfo = `${course.name || 'nameError'} (${
                        course.abbreviations || 'abbrevError'
                    }),
                    Language: ${course.language?.name || 'langError'},
                    Price: ${course.default_price || 'priceError'}`;
                    const accreditationInfo = course.course_accreditations?.map(
                        (a) => {
                            const stateFound = stateList.find(
                                ({ id }) => id === a.state_id,
                            );
                            return `${stateFound?.abbreviation} code: '${
                                a.code ?? ''
                            }'`;
                        },
                    );

                    if (editId === course.id) {
                        return (
                            <div>
                                <CourseInputForm
                                    editId={editId}
                                    updateId={updateId}
                                />
                            </div>
                        );
                    }
                    return (
                        <ListItem
                            key={`Course-${course.id}`}
                            divider
                            alignItems="center"
                        >
                            <ListItemText
                                primary={courseInfo}
                                secondary={
                                    course.course_accreditations
                                        ? `Accreditations (${
                                              course.course_accreditations
                                                  .length
                                          }): ${accreditationInfo.join(', ')}`
                                        : 'Accreditations list and codes here'
                                }
                            />
                            <Tooltip title="Edit Course">
                                <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() => {
                                        if (updateId) {
                                            updateId(course.id);
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <ListItemSecondaryAction>
                                <Tooltip title="Delete Course">
                                    <div>
                                        <ConfirmDeleteDialog
                                            title="Delete Course"
                                            content={`Deleting the following Course: ${courseInfo}`}
                                            onConfirm={async () => {
                                                await Client.delete(
                                                    `/api/courses/${course.id}`,
                                                ).then((resp) => {
                                                    if (resp.data) {
                                                        dispatch(
                                                            courseActions.courseRemove(
                                                                course.id,
                                                            ),
                                                        );
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            ;
        </List>
    );
}

CourseList.defaultProps = {
    editId: 0,
    updateId: undefined,
};
