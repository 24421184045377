import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Copyright from '../../components/Copyright';
import CourseInputForm from '../../components/CourseInputForm';
import CourseList from '../../components/CourseList';

import Client from '../../client';
import { actions as courseActions } from '../../store/entities/course';
import { actions as languageActions } from '../../store/entities/language';
import { actions as stateActions } from '../../store/entities/state';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function Courses(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className } = props;
    const [editingId, setEditingId] = useState(0);

    useEffect(() => {
        async function loadLanguages() {
            const response = await Client.get('/api/languages');
            await dispatch(languageActions.languageAddMany(response.data));
        }

        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadCourses() {
            const response = await Client.get('/api/courses');
            await dispatch(courseActions.courseAddMany(response.data));
        }

        loadLanguages();
        loadStates();
        loadCourses();
    }, []);

    return (
        <div className={clsx(styleClasses.root, className)}>
            <Container component="main" maxWidth="lg">
                <div className={styleClasses.title}>Course Form</div>
                <CourseInputForm disabled={editingId !== 0} />
                <CourseList editId={editingId} updateId={setEditingId} />
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
