import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Divider, IconButton, Grid, Tooltip } from '@material-ui/core';
import {
    OpenInNew as OpenInNewIcon,
    Info as InfoIcon,
} from '@material-ui/icons';

import DownloadInvoice from '../DownloadInvoice';
import { METAClient } from '../ClientSearch';

import { invoiceSelectors } from '../../store/entities/invoice';

const useStyles = makeStyles((theme) => ({
    root: {},
    subtitle: {
        marginTop: theme.spacing(5),
        fontSize: 18,
        width: '100%',
    },
}));

export interface ClientDataProps extends React.HTMLProps<HTMLDivElement> {
    clientInfo: METAClient;
}

export default function ClientData({ clientInfo, ...props }: ClientDataProps) {
    if (!Number.isInteger(clientInfo.id) || clientInfo.type === 'None') {
        return <div />;
    }
    const { className } = props;
    const styleClasses = useStyles();
    const invoices = useSelector(invoiceSelectors.selectAll);
    const clientInvoices = invoices.filter((invoice) =>
        clientInfo.type === 'Company'
            ? invoice.company_id === clientInfo.id
            : invoice.individual && invoice.seats[0]?.user_id === clientInfo.id,
    );

    const invoiceInfo = clientInvoices.map((invoice) => (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1}>
                    {invoice.number}
                </Grid>
                <Grid item xs={1}>
                    {`$${invoice.amount}`}
                </Grid>
                <Grid item xs={2}>
                    {`$${invoice.paid_amount}${
                        invoice.notes?.includes('VOIDED') ? ' (voided)' : ''
                    }`}
                </Grid>
                <Grid item xs={1}>
                    {invoice.seats.length}
                </Grid>
                <Grid item xs={1}>
                    <Link
                        to={{
                            pathname: '/seatsform',
                            search: `?invoice_id=${invoice.id}`,
                        }}
                    >
                        <Tooltip title="View Invoice">
                            <IconButton onClick={() => {}}>
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <DownloadInvoice invoiceId={invoice.id} />
                </Grid>
                <Grid item xs={1}>
                    <Link
                        to={{
                            pathname: '/classview',
                            search: `?class_id=${invoice.seats[0]?.class_id}`,
                        }}
                    >
                        <Tooltip title="View Class">
                            <IconButton onClick={() => {}}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                </Grid>
            </Grid>
            <Divider />
        </>
    ));

    invoiceInfo.unshift(
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={1}>
                    Invoice #
                </Grid>
                <Grid item xs={1}>
                    Total
                </Grid>
                <Grid item xs={2}>
                    Paid
                </Grid>
                <Grid item xs={1}>
                    Seats
                </Grid>
                <Grid item xs={1}>
                    Go To
                </Grid>
                <Grid item xs={1}>
                    Download
                </Grid>
                <Grid item xs={1}>
                    Class Info
                </Grid>
            </Grid>
            <Divider />
        </>,
    );

    return (
        <div className={className}>
            <div className={styleClasses.subtitle}>Invoices</div>
            {invoiceInfo}
        </div>
    );
}
