import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Tooltip, IconButton } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';

import Client from '../../client';

import Class from '../../store/entities/class/type';
import Invoice from '../../store/entities/invoice/type';
import Location from '../../store/entities/location/type';
import State from '../../store/entities/state/type';
import User from '../../store/entities/user/type';
import { addressSelectors } from '../../store/entities/address';
import { classSelectors } from '../../store/entities/class';
import { invoiceSelectors } from '../../store/entities/invoice';
import { locationSelectors } from '../../store/entities/location';
import { stateSelectors } from '../../store/entities/state';
import { studentSelectors } from '../../store/entities/student';

export interface DownlaodInvoiceProps extends React.HTMLProps<HTMLDivElement> {
    invoiceId: number;
}

export default function DownlaodInvoice({ invoiceId }: DownlaodInvoiceProps) {
    const studentList = useSelector(studentSelectors.selectAll);
    const stateList = useSelector(stateSelectors.selectAll);
    const addressList = useSelector(addressSelectors.selectAll);
    const invoiceFetched = useSelector((state) =>
        invoiceSelectors.selectById(state, invoiceId),
    );
    const companyLocationId = invoiceFetched?.company?.location_id ?? 0;
    const companyLocation = useSelector((state) =>
        locationSelectors.selectById(state, companyLocationId),
    );
    const classId = invoiceFetched?.seats[0]?.class_id ?? 0;
    const classFetched = useSelector((state) =>
        classSelectors.selectById(state, classId),
    );

    function getInvoiceData(): {
        invoice: Invoice;
        classData: Class;
        classStates: State[];
        invoicee: any;
        seatsInfo: any;
    } {
        const invoiceFound = invoiceFetched ?? ({} as Invoice);
        const classFound = classFetched ?? ({} as Class);
        const classStates = (classFound.class_accreditations || []).map(
            (accr) => {
                const stateFound = stateList.find(
                    ({ id }) => id === accr.state_id,
                );
                return stateFound ?? ({} as State);
            },
        );
        const invoiceSeats = invoiceFound.seats.map((seat) => {
            const studentFound = studentList.find(
                ({ id }) => id === seat.user_id,
            );
            return {
                states: seat.states,
                student: studentFound ?? ({} as User),
            };
        });
        let invoicee = {
            name: '',
            contact: '',
            address: '',
            cityStateZip: '',
            phone: '',
            email: '',
        };

        if (invoiceFound.company) {
            const companyLocationInfo = companyLocation ?? ({} as Location);
            invoicee = {
                name: invoiceFound.company.name,
                contact: invoiceFound.company.contact_name,
                address: `${companyLocationInfo.address_1} ${
                    companyLocationInfo.address_2 ?? ''
                }`,
                cityStateZip: `${companyLocationInfo.city}, ${companyLocationInfo.state} ${companyLocationInfo.zip_code}`,
                phone: invoiceFound.company.phone,
                email: invoiceFound.company.primary_email,
            };
        } else {
            const primaryStudent = invoiceSeats[0].student;
            const studentAddressInfo = addressList.find(
                ({ id }) => id === primaryStudent.address_id,
            );
            invoicee = {
                name: primaryStudent.full_name,
                contact: primaryStudent.full_name,
                address: `${studentAddressInfo?.address1} ${
                    studentAddressInfo?.address2 ?? ''
                }`,
                cityStateZip: `${studentAddressInfo?.administrative_area}, ${studentAddressInfo?.locality} ${studentAddressInfo?.postal_code}`,
                phone: '',
                email: primaryStudent.email,
            };
        }

        return {
            invoice: invoiceFound,
            classData: classFound,
            classStates,
            invoicee,
            seatsInfo: invoiceSeats,
        };
    }

    return (
        <Tooltip title="Download Invoice">
            <IconButton
                onClick={async () => {
                    const invoiceData = getInvoiceData();
                    const issuerInfo = await Client.get(
                        `/api/users/${invoiceFetched?.issuer}`,
                    );
                    const response = await axios.post(
                        `${window.location.origin}/createInvoice`,
                        { ...invoiceData, issuer: issuerInfo.data.full_name },
                    );

                    window.location.href = response.data.url;
                }}
            >
                <SaveAlt />
            </IconButton>
        </Tooltip>
    );
}
