import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';

import { useForm, FormProvider } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Copyright from '../../components/Copyright';
import FormField from '../../components/FormField';

import { login } from '../../store/auth';
import useAbort from '../../hooks/useAbort';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface Props extends PropsFromRedux {}

/* eslint-disable-next-line @typescript-eslint/no-shadow */
const Component: React.FC<Props> = function SignIn({ login }) {
    const classes = useStyles();
    const Abortable = useAbort([]);
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm();
    const {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        formState: { isDirty, isValid, dirtyFields, errors },
    } = form;

    async function onSubmit(data: any) {
        setIsLoading(true);
        await Abortable(login(data));
        setIsLoading(false);
    }

    /* eslint-disable jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <FormProvider {...form}>
                    <form
                        className={classes.form}
                        onSubmit={form.handleSubmit(onSubmit)}
                        /* eslint-enable react/jsx-props-no-spreading */
                    >
                        <FormField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            validate={{
                                required: 'Username is required.',
                            }}
                        />
                        <FormField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            validate={{
                                required: 'Password required.',
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!isDirty}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    Don&apos;t have an account? Sign Up
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const connector = connect((state) => ({ user: null }), {
    login,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Component);
