import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import Invoice from '../../store/entities/invoice/type';
import { companySelectors } from '../../store/entities/company';

interface AddStudentDialogProps extends React.HTMLProps<HTMLDivElement> {
    onConfirm: () => void;
    classId: number;
    invoices: Invoice[];
    disabled: boolean;
}

const AddStudentDialog: React.FunctionComponent<AddStudentDialogProps> = ({
    onConfirm,
    classId,
    invoices,
    disabled,
}) => {
    const [open, setOpen] = React.useState(false);
    const companies = useSelector(companySelectors.selectAll);
    const title = 'Add a Student';
    const content =
        'Select an Invoice below to edit, or choose "New Invoice" to create a new Invoice';

    function handleClose(): void {
        setOpen(false);
    }

    const companyOptions = companies.map((c) => ({
        label: c.name,
        id: c.id,
    }));
    const invoiceOptions = invoices.map((inv) => ({
        label: inv.number,
        id: inv.id,
        company_id: inv.company_id,
    }));

    const [invoice, setInvoice] = useState(invoiceOptions[0]);
    const [filteredInvoices, setFilteredInvoices] = useState(invoiceOptions);

    return (
        <>
            <Tooltip title="Add Student">
                <IconButton
                    edge="end"
                    aria-label="add-student"
                    disabled={disabled}
                    onClick={() => {
                        setFilteredInvoices(invoiceOptions);
                        setOpen(true);
                    }}
                >
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="student-dialog-title"
                aria-describedby="student-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="student-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="student-dialog-description">
                        {content}
                    </DialogContentText>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="company-picker"
                                options={companyOptions}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newCompany) => {
                                    const newOptions = newCompany
                                        ? invoiceOptions.filter(
                                              (inv) =>
                                                  inv.company_id ===
                                                  newCompany.id,
                                          )
                                        : invoiceOptions;
                                    setFilteredInvoices(newOptions);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company Name"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="invoice-picker"
                                options={filteredInvoices}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newInvoice) => {
                                    if (newInvoice) {
                                        setInvoice(newInvoice);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Invoice Number"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            OR
                        </Grid>
                        <Grid item xs={3}>
                            <Link
                                to={{
                                    pathname: '/seatsform',
                                    search: `?class_id=${classId}`,
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        onConfirm();
                                        handleClose();
                                    }}
                                    color="primary"
                                    id="student-dialog-button-new-invoice"
                                    fullWidth
                                    autoFocus
                                    variant="outlined"
                                >
                                    New Invoice
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id="student-dialog-button-cancel"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Link
                        to={{
                            pathname: '/seatsform',
                            search: `?invoice_id=${invoice?.id}`,
                        }}
                    >
                        <Button
                            onClick={() => {
                                onConfirm();
                                handleClose();
                            }}
                            color="primary"
                            id="student-dialog-button-confirm"
                            variant="contained"
                        >
                            Add Student
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddStudentDialog;
