import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Trainer from './type';

const trainerAdapter = createEntityAdapter<Trainer>();

const slice = createSlice({
    name: 'trainer',
    initialState: trainerAdapter.getInitialState(),
    reducers: {
        trainersAddOne: trainerAdapter.addOne,
        trainersAddMany: trainerAdapter.addMany,
        trainerUpdate: trainerAdapter.updateOne,
        trainerRemove: trainerAdapter.removeOne,
    },
});

export const trainerSelectors = trainerAdapter.getSelectors(
    (state: any) => state.trainers,
);

export const { actions, reducer } = slice;
