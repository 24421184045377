import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import FormField from '../FormField';
import StateSelector from '../StateSelector';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

import Course from '../../store/entities/course/type';
import Accreditation from '../../store/entities/accreditation/type';
import Client from '../../client';
import {
    actions as courseActions,
    courseSelectors,
} from '../../store/entities/course';
import { languageSelectors } from '../../store/entities/language';
import { stateSelectors } from '../../store/entities/state';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultAccr: Accreditation[] = [];

export interface Props extends React.HTMLProps<HTMLDivElement> {
    editId?: number;
    updateId?: (id: number) => void;
    disabled?: boolean;
}

export default function CourseInputForm({
    editId = 0,
    updateId = undefined,
    disabled = false,
}: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [currentAccr, setCurrentAccr] = useState(defaultAccr);
    const courses = useSelector(courseSelectors.selectAll);
    const languages = useSelector(languageSelectors.selectAll);
    const stateList = useSelector(stateSelectors.selectAll);
    const courseToEdit = courses.find((course: Course) => course.id === editId);

    useEffect(() => {
        async function loadAccreditations() {
            const courseAccr: Accreditation[] = [];
            if (courseToEdit && courseToEdit.course_accreditations) {
                courseToEdit.course_accreditations.forEach(
                    ({ code, state_id }: Accreditation) => {
                        courseAccr.push({ code, state_id });
                    },
                );
            }
            await setCurrentAccr(courseAccr);
        }

        loadAccreditations();
    }, []);

    const form = useForm();
    const {
        formState: { isDirty },
    } = form;
    const stateSelectorOptions = stateList
        .filter(({ is_state }) => is_state)
        .map((s) => ({
            label: s.name,
            id: s.abbreviation,
        }));

    function isDuplicateTitle(input: string): boolean {
        const found = courses.find(
            (course: Course) =>
                course.name.toLowerCase() === input.toLowerCase() &&
                course.id !== editId,
        );
        return Boolean(found);
    }

    function isDuplicateAbbreviation(input: string): boolean {
        const found = courses.find(
            (course: Course) =>
                course.abbreviations.toLowerCase() === input.toLowerCase() &&
                course.id !== editId,
        );
        return Boolean(found);
    }

    const handleCodeChange = (value: string, index: number) => {
        const newAccreditations = [...currentAccr];
        newAccreditations[index].code = value;
        setCurrentAccr(newAccreditations);
    };

    function confirmButton(): JSX.Element {
        if (editId) {
            return (
                <div>
                    <Tooltip title="Confirm">
                        <IconButton type="submit" edge="end" aria-label="edit">
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <IconButton
                            edge="end"
                            aria-label="cancel"
                            onClick={() => {
                                if (updateId) {
                                    updateId(0);
                                }
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        }
        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styleClasses.submit}
                disabled={!isDirty || disabled}
            >
                Submit
            </Button>
        );
    }

    async function onSubmit(data: any) {
        if (!isLoading) {
            setIsLoading(true);
        }
        const accredPayload = currentAccr.map((accr) =>
            accr.code ? accr : { state_id: accr.state_id },
        );
        if (!editId) {
            await Client.post('/api/courses', {
                ...data,
                accreditations: accredPayload,
                on_site: false,
            }).then(async (resp) => {
                const courseInfo = await Client.get(
                    `/api/courses/${resp.data.id}`,
                );
                dispatch(courseActions.courseAddOne(courseInfo.data));
            });
            await form.reset({});
            setCurrentAccr(defaultAccr);
        } else {
            await Client.put(`/api/courses/${editId}`, {
                ...data,
                accreditations: accredPayload,
                on_site: false,
            }).then((resp) => {
                if (resp.data) {
                    const langInfo = languages.find(
                        (lang) => lang.id === data.language_id,
                    );
                    dispatch(
                        courseActions.courseUpdate({
                            id: editId,
                            changes: {
                                ...data,
                                language: langInfo,
                                course_accreditations: currentAccr,
                            },
                        }),
                    );
                }
            });
            if (updateId) {
                updateId(0);
            }
            await form.reset({});
            setCurrentAccr(defaultAccr);
        }
        setIsLoading(false);
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <div>
            <FormProvider {...form}>
                <form
                    className={styleClasses.form}
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="course-title-input"
                                name="name"
                                label="Course Title"
                                defaultValue={
                                    editId !== 0 && courseToEdit
                                        ? courseToEdit.name
                                        : ''
                                }
                                autoComplete="Course Title"
                                autoFocus
                                disabled={disabled}
                                validate={{
                                    required: 'Course title is required.',
                                    validate: {
                                        unique: (title: string) =>
                                            !isDuplicateTitle(title) ||
                                            'Already in use.',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="course-abbr-input"
                                name="abbreviations"
                                label="Course Abbreviation"
                                defaultValue={
                                    editId !== 0 && courseToEdit
                                        ? courseToEdit.abbreviations
                                        : ''
                                }
                                autoComplete="auto"
                                disabled={disabled}
                                validate={{
                                    required: 'Abbreviation required.',
                                    validate: {
                                        unique: (abbr: string) =>
                                            !isDuplicateAbbreviation(abbr) ||
                                            'Already in use.',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl>
                                <InputLabel id="language-label">
                                    Language
                                </InputLabel>
                                <Select
                                    {...form.register('language_id', {
                                        valueAsNumber: true,
                                    })}
                                    id="language-select"
                                    disabled={disabled}
                                    defaultValue={
                                        editId !== 0 && courseToEdit
                                            ? courseToEdit.language_id
                                            : 2
                                    }
                                >
                                    {languages?.map((lang: any) => (
                                        <MenuItem
                                            key={lang.name}
                                            value={lang.id}
                                        >
                                            {lang.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="course-price-input"
                                name="default_price"
                                label="Price"
                                defaultValue={
                                    editId !== 0 && courseToEdit
                                        ? courseToEdit.default_price
                                        : ''
                                }
                                autoComplete="auto"
                                disabled={disabled}
                                validate={{
                                    valueAsNumber: true,
                                    validate: {
                                        isNumber: (price: any) =>
                                            Number.isInteger(price),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <StateSelector
                                options={stateSelectorOptions}
                                label="Select states for this course"
                                makeOptionLabel={(option) => option.id}
                                makeOptionDisabled={(option) =>
                                    currentAccr.some((accr) => {
                                        const stateFound = stateList.find(
                                            ({ id }) => id === accr.state_id,
                                        );
                                        return (
                                            stateFound?.abbreviation ===
                                            option.id
                                        );
                                    })
                                }
                                values={currentAccr.map(
                                    (accr: Accreditation) => {
                                        const stateFound = stateList.find(
                                            ({ id }) => id === accr.state_id,
                                        );
                                        return {
                                            label: `${stateFound?.name}`,
                                            id: `${stateFound?.abbreviation}`,
                                        };
                                    },
                                )}
                                updateData={(stateData: any) => {
                                    const stateFound = stateList.find(
                                        ({ abbreviation }) =>
                                            abbreviation ===
                                            stateData.abbreviation,
                                    );
                                    const newAccreditation = {
                                        code: '',
                                        state_id: stateFound?.id || 0,
                                    };
                                    setCurrentAccr([
                                        ...currentAccr,
                                        newAccreditation,
                                    ]);
                                }}
                                placeholderText=""
                                margin="dense"
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>{confirmButton()}</Grid>
                    </Grid>
                </form>
            </FormProvider>
            <Grid container alignItems="flex-start" spacing={3}>
                <Grid item xs={6}>
                    <List>
                        {currentAccr.length > 0 &&
                            currentAccr.map((accr, index) => {
                                const stateFound = stateList.find(
                                    ({ id }) => id === accr.state_id,
                                );
                                return (
                                    <ListItem
                                        key={`${stateFound?.abbreviation}`}
                                        divider
                                        alignItems="center"
                                    >
                                        <ListItemText
                                            primary={`${stateFound?.name}`}
                                        />
                                        <TextField
                                            id={`accreditation-code-input-${index}`}
                                            label="Accreditation Code"
                                            value={accr.code}
                                            onChange={(event: any) =>
                                                handleCodeChange(
                                                    event.target.value,
                                                    index,
                                                )
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                        <ListItemSecondaryAction>
                                            <ConfirmDeleteDialog
                                                content={`Deleting accreditation for ${stateFound?.name}`}
                                                onConfirm={() => {
                                                    const newAccreditations = [
                                                        ...currentAccr,
                                                    ];
                                                    newAccreditations.splice(
                                                        index,
                                                        1,
                                                    );
                                                    setCurrentAccr(
                                                        newAccreditations,
                                                    );
                                                }}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                    </List>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
    /* eslint-disable react/jsx-props-no-spreading */
}

CourseInputForm.defaultProps = {
    editId: 0,
    updateId: undefined,
    disabled: false,
};
