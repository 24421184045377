import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface Props extends React.HTMLProps<HTMLDivElement> {
    options: OptionType[];
    label: string;
    updateData: (values: OptionType | object) => void;
    values: OptionType[];
    placeholderText: string;
    makeOptionLabel: (option: OptionType) => string;
    makeOptionDisabled: (option: OptionType) => boolean;
    margin?: 'normal' | 'none' | 'dense';
    disabled?: boolean;
}

export interface OptionType {
    label: string;
    id: string;
}

export default function StateSelector({
    options,
    label,
    margin,
    disabled = false,
    updateData,
    values,
    placeholderText,
}: Props) {
    // value to track is c.class.certification_states which is an array of {name: string, abbreviation: string}

    return (
        <div>
            <Autocomplete
                multiple
                id="checkboxes-autocomplete-multiselect"
                value={values}
                onChange={(event: any, newValues: OptionType[]) => {
                    // This always gives us all the selected values, but we are only interested
                    // in the last option selected.
                    const item = newValues[newValues.length - 1];
                    updateData({ name: item.label, abbreviation: item.id });
                }}
                options={options}
                disableCloseOnSelect
                disableClearable
                // This will be the value compared to when the user types a value to filter by.
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                }
                filterSelectedOptions
                // Don't display tags, we display a list of notified states on the side already.
                // renderTags={(tagValue: any, getTagProps: any) => null}
                renderTags={() => null}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        margin={margin}
                        variant="outlined"
                        label={label}
                        placeholder={placeholderText}
                    />
                )}
                /* eslint-enable react/jsx-props-no-spreading */
                limitTags={-1}
                disabled={disabled}
            />
        </div>
    );
}

StateSelector.defaultProps = {
    margin: 'dense',
    disabled: false,
};
