import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import CancelStateDialog from '../CancelStateDialog';

import Accreditation from '../../../store/entities/accreditation/type';
import State from '../../../store/entities/state/type';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { stateSelectors } from '../../../store/entities/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 752,
        },
        list: {
            backgroundColor: theme.palette.background.paper,
        },
        title: {
            margin: theme.spacing(0, 2),
        },
        inline: {
            display: 'inline',
        },
    }),
);

interface Props extends React.HTMLProps<HTMLDivElement> {
    classAccr: Accreditation[];
    setClassAccr: React.Dispatch<React.SetStateAction<Accreditation[]>>;
    classId: number;
}

export default function SessionStateList({
    classAccr,
    setClassAccr,
    className,
    classId,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const stateList = useSelector(stateSelectors.selectAll);
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const stateObjects = classAccr.map(({ state_id }) => {
        const stateFound = stateList.find(({ id }) => id === state_id);
        if (stateFound) {
            return stateFound;
        }

        return {
            id: 0,
            name: 'State not found',
            abbreviation: 'SNF',
            is_state: false,
            is_territory: false,
        };
    });

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Typography variant="h6" className={styleClasses.title}>
                Selected States
            </Typography>
            <div className={styleClasses.list}>
                <List>
                    {stateObjects.map((state: State, index: number) => (
                        <ListItem key={state.abbreviation} divider>
                            <Grid container>
                                <Grid item>
                                    <ListItemAvatar>
                                        <Avatar>{state.abbreviation}</Avatar>
                                    </ListItemAvatar>
                                </Grid>
                                <Grid item>
                                    <ListItemText primary={state.name} />
                                </Grid>
                                <Grid item>
                                    <ListItemSecondaryAction>
                                        {classId ? (
                                            <CancelStateDialog
                                                onConfirm={() => {
                                                    const newClassAccr = [
                                                        ...classAccr,
                                                    ];
                                                    newClassAccr.splice(
                                                        index,
                                                        1,
                                                    );
                                                    setClassAccr(newClassAccr);
                                                }}
                                                classId={classId}
                                                stateId={state.id}
                                                invoices={invoices.filter(
                                                    (inv) =>
                                                        inv.seats[0]
                                                            ?.class_id ===
                                                        classId,
                                                )}
                                            />
                                        ) : (
                                            <Tooltip title="Delete Notification">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        const newClassAccr = [
                                                            ...classAccr,
                                                        ];
                                                        newClassAccr.splice(
                                                            index,
                                                            1,
                                                        );
                                                        setClassAccr(
                                                            newClassAccr,
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </ListItemSecondaryAction>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
}
