import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green, yellow, red } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';

import { ActiveStateChips } from '../../StateChipLists';

import Seat from '../../../store/entities/seat/type';
import Invoice from '../../../store/entities/invoice/type';
import { classSelectors } from '../../../store/entities/class';
import { studentSelectors } from '../../../store/entities/student';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        marginVert: {
            margin: '20px 0px',
        },
        bold: {
            fontWeight: 'bold',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        green: {
            color: green[600],
        },
        yellow: {
            color: yellow[700],
        },
        red: {
            color: red[600],
        },
    }),
);

interface SeatInfo {
    seat: Seat;
    invoiceId: number;
    invoiceNumber: string;
    invoiceAmount: number;
    invoiceAmountPaid: number;
    companyName?: string;
}

interface CancelStateDialogProps extends React.HTMLProps<HTMLDivElement> {
    onConfirm: () => void;
    classId: number;
    stateId: number;
    invoices: Invoice[];
}

const CancelStateDialog: React.FunctionComponent<CancelStateDialogProps> = ({
    onConfirm,
    classId,
    stateId,
    invoices,
}) => {
    const styleClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const students: JSX.Element[] = [];
    const studentList = useSelector(studentSelectors.selectAll);
    const classFetched = useSelector((state) =>
        classSelectors.selectById(state, classId),
    );
    const title = 'Cancel State Notification';
    const content = `Cancel notifications can only be issued
        for states that have no students signed up.
        Please address the enrollment of the following students:`;

    const seats: SeatInfo[] = [];

    invoices.forEach((inv) =>
        inv.seats.forEach((seat) => {
            const seatStates = seat.states.map((st) => st.id);
            if (seatStates.includes(stateId)) {
                seats.push({
                    seat,
                    invoiceId: inv.id,
                    invoiceNumber: inv.number,
                    invoiceAmount: Number(inv.amount),
                    invoiceAmountPaid: Number(inv.paid_amount),
                    companyName: inv.company?.name,
                });
            }
        }),
    );

    function handleClose(): void {
        setOpen(false);
    }

    /* eslint-disable no-plusplus */
    for (let n = 0; n < seats.length; n++) {
        const student = studentList.find((u) => u.id === seats[n].seat.user_id);
        if (student) {
            students.push(
                <Grid
                    container
                    key={`student-${student.id}`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={1}>
                        <Avatar
                            key={`avatar-${student.last_name}-${student.id}-invoice-${seats[n].invoiceId}`}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div>{`${student.full_name}`}</div>
                        <div>{`SSN: ${
                            student.ssn_last_four_digits
                                ? `${student.ssn_last_four_digits}`.slice(-4)
                                : '####'
                        }`}</div>
                    </Grid>
                    <Grid item xs={2}>
                        {`${seats[n].companyName}`}
                    </Grid>
                    <Grid item xs={1}>
                        {`${seats[n].invoiceNumber}`}
                    </Grid>
                    <Grid item xs={1}>
                        <div className={styleClasses.container}>
                            <MonetizationOnIcon
                                className={clsx(
                                    styleClasses.small,
                                    seats[n].invoiceAmount >
                                        seats[n].invoiceAmountPaid
                                        ? styleClasses.red
                                        : styleClasses.green,
                                )}
                            />
                            {`${seats[n].invoiceAmount}`}
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <ActiveStateChips
                            accreditations={
                                classFetched?.class_accreditations || []
                            }
                            activeStates={seats[n].seat.states.map((s) => s.id)}
                        />
                    </Grid>
                </Grid>,
            );
        }
    }
    /* eslint-disable no-plusplus */

    return (
        <>
            <Tooltip title={title}>
                <IconButton
                    edge="end"
                    aria-label="cancel-state"
                    onClick={() => {
                        setOpen(true);
                        if (seats.length === 0) {
                            onConfirm();
                            handleClose();
                        }
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="cancel-state-dialog-title"
                aria-describedby="cancel-state-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="cancel-state-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="cancel-state-description">
                        {content}
                    </DialogContentText>
                    {students}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id="cancel-state-dialog-button-cancel"
                        variant="contained"
                    >
                        Return to class editing
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CancelStateDialog;
