import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Copyright from '../../components/Copyright';
import ClassMainForm from '../../components/ClassForm/ClassMainForm';
import Client from '../../client';

import { actions as courseActions } from '../../store/entities/course';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as trainerActions } from '../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        title: {
            marginTop: theme.spacing(10),
            fontSize: 24,
            width: '100%',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function ClassForm(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className, ...attrs } = props;

    useEffect(() => {
        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadCourses() {
            const response = await Client.get('/api/courses');
            await dispatch(courseActions.courseAddMany(response.data));
        }

        async function loadTrainers() {
            const response = await Client.get('/api/trainers');
            await dispatch(trainerActions.trainersAddMany(response.data));
        }

        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        loadStates();
        loadCourses();
        loadTrainers();
        loadLocations();
        loadInvoices();
    }, []);

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Container component="main" maxWidth="lg">
                <Paper id="class-form">
                    <div className={styleClasses.title}>Class Form</div>
                    <ClassMainForm />
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Paper>
            </Container>
        </div>
    );
}
