import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Class from './type';

const classAdapter = createEntityAdapter<Class>();

const slice = createSlice({
    name: 'class',
    initialState: classAdapter.getInitialState(),
    reducers: {
        classAddOne: classAdapter.addOne,
        classAddMany: classAdapter.addMany,
        classUpdate: classAdapter.updateOne,
        classRemove: classAdapter.removeOne,
    },
});

export const classSelectors = classAdapter.getSelectors(
    (state: any) => state.classes,
);

export const { actions, reducer } = slice;
