import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        canceledCheckbox: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: `100%`,
        },
    }),
);

export function CanceledFilterList(filter: string[]): string[] {
    return filter[0] === 'true' ? [`Canceled Classes`] : [];
}
// MUI incorrectly types 'canceled' as string when it is in fact a boolean
export function CanceledFilterLogic(canceled: string, filters: string[]) {
    // filters[0] can be:
    // 'true' when checked
    // 'false' when unchecked
    // undefined when filters are cleared
    return filters[0] === 'true'
        ? `${canceled}` === 'false'
        : `${canceled}` === 'true';
}

export interface CanceledFilterDisplayProps
    extends React.HTMLProps<HTMLDivElement> {
    filterList: string[][]; // first array is all filters, second is options within each filter
    onChange: any;
    index: number; // use filterList[index] to access filter array for this particular filtering
    column: MUIDataTableColumn;
}

export default function CanceledFilterDisplay({
    filterList,
    onChange,
    index,
    column,
}: CanceledFilterDisplayProps) {
    const styleClasses = useStyles();
    const canceledFilters = filterList[index];

    const handleChange = (event: any) => {
        const newFilters = [...canceledFilters];
        newFilters[0] = `${event.target.checked}`;
        onChange(newFilters, index, column);
    };

    return (
        <FormGroup row>
            <div className={styleClasses.canceledCheckbox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={canceledFilters[0] === 'true'}
                            color="primary"
                            onChange={handleChange}
                            name="canceled"
                        />
                    }
                    label="Display Canceled Classes Only"
                    labelPlacement="start"
                />
            </div>
        </FormGroup>
    );
}
