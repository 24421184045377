import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Clients from '../pages/Clients';
import Courses from '../pages/Courses';
import Trainers from '../pages/Trainers';
import ClassTable from '../pages/ClassTable';
import ClassForm from '../pages/ClassForm';
import ClassView from '../pages/ClassView';
import SeatsForm from '../pages/SeatsForm';
import Invoices from '../pages/Invoices';

import MetaAppBar, { MenuElement } from '../components/MetaAppBar';
import verifyAdmin from '../utils/verifyAdmin';

interface Props extends PropsFromRedux {}

const Component: React.FC<Props> = function Router({ user }) {
    if (!user) {
        return <Login />;
    }

    const menu: MenuElement[] = [
        {
            name: 'Welcome',
            path: '/home',
            display: true,
            component: null,
        },
        {
            name: 'Manage Clients',
            path: '/clients',
            display: verifyAdmin(user),
            component: <Clients />,
        },
        {
            name: 'Courses',
            path: '/courses',
            display: true,
            component: <Courses />,
        },
        {
            name: 'Manage Users',
            path: '/users',
            display: verifyAdmin(user),
            component: <Trainers />,
        },
        {
            name: 'Classes',
            path: '/classes',
            display: true,
            component: <ClassTable user={user} />,
        },
        {
            name: 'Class Form',
            path: '/classform',
            display: false,
            component: <ClassForm />,
        },
        {
            name: 'Seats Form',
            path: '/seatsform',
            display: false,
            component: <SeatsForm user={user} />,
        },
        {
            name: 'Class View',
            path: '/classview',
            display: false,
            component: <ClassView user={user} />,
        },
        {
            name: 'Invoices',
            path: '/invoices',
            display: true,
            component: <Invoices />,
        },
    ];

    return (
        <BrowserRouter>
            <div>
                <MetaAppBar menu={menu} />
                <Switch>
                    {menu.map((item: MenuElement) => (
                        <Route path={item.path}>
                            {item.component || item.name}
                        </Route>
                    ))}
                </Switch>
            </div>
        </BrowserRouter>
    );
};

const connector = connect((state) => ({ user: state.auth.user }));

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Component);
