import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import ClientSearch, { METAClient } from '../../components/ClientSearch';
import ClientData from '../../components/ClientData';
import Copyright from '../../components/Copyright';

import Client from '../../client';
import User from '../../store/entities/user/type';
import { actions as addressActions } from '../../store/entities/address';
import { actions as classActions } from '../../store/entities/class';
import { actions as companyActions } from '../../store/entities/company';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as studentActions } from '../../store/entities/student';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginTop: theme.spacing(10),
        fontSize: 24,
        width: '100%',
    },
}));

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function Clients(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className } = props;
    const [clientInfo, setClientInfo] = useState({
        type: 'None',
        id: 0,
        label: '',
    } as METAClient);

    useEffect(() => {
        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        async function loadCompanies() {
            const response = await Client.get('/api/companies');
            await dispatch(companyActions.companyAddMany(response.data));
        }

        async function loadClasses() {
            const response = await Client.get('/api/classes');
            await dispatch(classActions.classAddMany(response.data));
        }

        async function loadAddresses() {
            const response = await Client.get('/api/addresses');
            await dispatch(
                addressActions.addressAddMany(response.data.addresses),
            );
        }

        async function loadStudents() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
        }

        loadStates();
        loadLocations();
        loadInvoices();
        loadCompanies();
        loadClasses();
        loadAddresses();
        loadStudents();
    }, []);

    return (
        <div className={clsx(styleClasses.root, className)}>
            <Container component="main" maxWidth="lg">
                <div className={styleClasses.title}>Manage Clients</div>
                <ClientSearch
                    clientInfo={clientInfo}
                    setClientInfo={setClientInfo}
                />
                <Divider />
                <ClientData clientInfo={clientInfo} />
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
