import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Company from './type';

const companyAdapter = createEntityAdapter<Company>();

const slice = createSlice({
    name: 'company',
    initialState: companyAdapter.getInitialState(),
    reducers: {
        companyAddOne: companyAdapter.addOne,
        companyAddMany: companyAdapter.addMany,
        companyUpdate: companyAdapter.updateOne,
        companyRemove: companyAdapter.removeOne,
    },
});

export const companySelectors = companyAdapter.getSelectors(
    (state: any) => state.companies,
);

export const { actions, reducer } = slice;
