import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import SignatureCanvas from 'react-signature-canvas';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        form: {
            width: '100%',
        },
        sigPad: {
            border: 'solid thick red',
            maxWidth: '400px',
            maxHeight: '300px',
            width: '100%',
            minHeight: '250px',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    setSignature: (data: string) => void;
    titleText: string;
}

export default function ResponsiveDialog(props: Props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { setSignature, titleText } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sigPad = React.useRef() as React.MutableRefObject<any>;

    const formatIntoPng = () => {
        if (sigPad.current) {
            const dataURL = sigPad?.current?.toDataURL();
            return dataURL;
        }
        return null;
    };

    const clear = () => sigPad.current.clear();

    const handleSave = () => {
        const trimmed = sigPad.current
            .getTrimmedCanvas()
            .toDataURL('image/png');
        setSignature(trimmed);
        clear();
        handleClose();
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                {titleText}
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Draw Signature
                </DialogTitle>
                <DialogContent>
                    <SignatureCanvas
                        ref={sigPad}
                        onEnd={() => formatIntoPng()}
                        penColor="black"
                        canvasProps={{
                            className: classes.sigPad,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={clear}>
                        Clear
                    </Button>
                    <Button autoFocus type="submit" onClick={handleSave}>
                        Save
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
