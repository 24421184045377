import React, { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

interface PickerChoice {
    label: string;
    id: number | string;
}

interface OwnProps {
    choices: PickerChoice[];
    defaultInputValue?: number | string;
    name: string;
    validate?: { [key: string]: any };
    label?: string;
}

type Props = OwnProps & ComponentProps<typeof Select>;

const Component: React.FC<Props> = function FormPicker({
    choices,
    defaultInputValue,
    name,
    validate,
    label,
    ...props
}: Props) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { ref, ...controller } = register(name, validate);

    return (
        <div>
            <InputLabel id="input-select-label">{label}</InputLabel>
            <Select
                label={label}
                error={!!errors[name]}
                defaultValue={defaultInputValue}
                inputRef={ref}
                {...props}
                {...controller}
                inputProps={{ 'aria-label': name }}
            >
                {choices?.map((element: PickerChoice) => (
                    <MenuItem key={element.label} value={element.id}>
                        {element.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

Component.defaultProps = {
    defaultInputValue: 0,
    validate: {},
    label: 'Select your choice',
};

export default Component;
