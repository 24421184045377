import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Client from '../../client';
import InvoiceDataTable from '../../components/InvoiceTable/InvoiceDataTable';
import Copyright from '../../components/Copyright';

import User from '../../store/entities/user/type';
import { actions as adminActions } from '../../store/entities/admin';
import { actions as classActions } from '../../store/entities/class';
import { actions as companyActions } from '../../store/entities/company';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as studentActions } from '../../store/entities/student';
import { actions as trainerActions } from '../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        test: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '80px',
            bottom: '70px',
        },
        error: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function Invoices(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className, ...attrs } = props;

    useEffect(() => {
        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadStudentsAndAdmins() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
            await dispatch(
                adminActions.adminAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 1,
                    ),
                ),
            );
        }

        async function loadCompanies() {
            const response = await Client.get('/api/companies');
            await dispatch(companyActions.companyAddMany(response.data));
        }

        async function loadClasses() {
            const response = await Client.get('/api/classes');
            await dispatch(classActions.classAddMany(response.data));
        }

        async function loadTrainers() {
            const response = await Client.get('/api/trainers');
            await dispatch(trainerActions.trainersAddMany(response.data));
        }

        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        loadInvoices();
        loadLocations();
        loadTrainers();
        loadCompanies();
        loadClasses();
        loadStates();
        loadStudentsAndAdmins();
    }, []);

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <InvoiceDataTable />
                </Box>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
