import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { green, yellow, red } from '@material-ui/core/colors';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { ActiveStateChips } from '../../StateChipLists';

import Seat from '../../../store/entities/seat/type';
import Accreditation from '../../../store/entities/accreditation/type';
import { studentSelectors } from '../../../store/entities/student';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        marginVert: {
            margin: '20px 0px',
        },
        bold: {
            fontWeight: 'bold',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        green: {
            color: green[600],
        },
        yellow: {
            color: yellow[700],
        },
        red: {
            color: red[600],
        },
    }),
);

export interface SeatInfo {
    seat: Seat;
    invoiceId: number;
    invoiceNumber: string;
    invoiceAmount: number;
    invoiceAmountPaid: number;
    companyName?: string;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    seats: SeatInfo[];
    classAccreditations: Accreditation[];
    onConfirm?: () => void;
}

export default function DuplicateStudentDialog({
    open,
    setOpen,
    seats,
    classAccreditations,
    onConfirm = () => {},
}: Props) {
    const styleClasses = useStyles();
    const studentList = useSelector(studentSelectors.selectAll);
    const students: JSX.Element[] = [];
    const title = 'Duplicate Students';
    const content = `The following students are already part of this class.
        Consider editing their invoices to add new certifications.`;
    function handleClose(): void {
        setOpen(false);
    }

    /* eslint-disable no-plusplus */
    for (let n = 0; n < seats.length; n++) {
        const student = studentList.find((u) => u.id === seats[n].seat.user_id);
        if (student) {
            students.push(
                <Grid
                    container
                    key={`student-${student.id}`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={1}>
                        <Avatar
                            key={`avatar-${student.last_name}-${student.id}-invoice-${seats[n].invoiceId}`}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div>{`${student.full_name}`}</div>
                        <div>{`SSN: ${
                            student.ssn_last_four_digits
                                ? `${student.ssn_last_four_digits}`.slice(-4)
                                : '####'
                        }`}</div>
                    </Grid>
                    <Grid item xs={2}>
                        {`${seats[n].companyName}`}
                    </Grid>
                    <Grid item xs={1}>
                        {`${seats[n].invoiceNumber}`}
                    </Grid>
                    <Grid item xs={1}>
                        <div className={styleClasses.container}>
                            <MonetizationOnIcon
                                className={clsx(
                                    styleClasses.small,
                                    seats[n].invoiceAmount >
                                        seats[n].invoiceAmountPaid
                                        ? styleClasses.red
                                        : styleClasses.green,
                                )}
                            />
                            {`${seats[n].invoiceAmount}`}
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <ActiveStateChips
                            accreditations={classAccreditations || []}
                            activeStates={seats[n].seat.states.map((s) => s.id)}
                        />
                    </Grid>
                </Grid>,
            );
        }
    }
    /* eslint-disable no-plusplus */

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="seat-accreditation-dialog-title"
            aria-describedby="seat-accreditation-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="seat-accreditation-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="seat-accreditation-description">
                    {content}
                </DialogContentText>
                {students}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    color="default"
                    id="seat-accreditation-dialog-button-cancel"
                    variant="contained"
                >
                    Return to invoice
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        handleClose();
                    }}
                    color="primary"
                    id="seat-accreditation-dialog-button-confirm"
                    variant="contained"
                >
                    Proceed anyway
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DuplicateStudentDialog.defaultProps = {
    onConfirm: () => {},
};
