import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface AlertModalProps extends React.HTMLProps<HTMLDivElement> {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    dialogContent: string | JSX.Element;
    title?: string;
}

const AlertModal: React.FunctionComponent<AlertModalProps> = ({
    open,
    setOpen,
    dialogContent,
    title,
}) => {
    function handleClose(): void {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
        >
            <DialogTitle id="alert-modal-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-modal-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    color="primary"
                    autoFocus
                    id="alert-modal-button"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AlertModal.defaultProps = {
    title: 'Notice',
};

export default AlertModal;
