import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import Client from '../../client';

import {
    actions as invoiceActions,
    invoiceSelectors,
} from '../../store/entities/invoice';
import Invoice from '../../store/entities/invoice/type';

interface VoidInvoiceDialogProps extends React.HTMLProps<HTMLDivElement> {
    invoiceId: number;
    onConfirm?: () => void;
}

const VoidInvoiceDialog: React.FunctionComponent<VoidInvoiceDialogProps> = ({
    invoiceId,
    onConfirm = () => {},
}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const invoiceFetched = useSelector((state) =>
        invoiceSelectors.selectById(state, invoiceId),
    );
    const content = invoiceFetched
        ? `This action will void invoice #${invoiceFetched.number}. Do you wish to proceed?`
        : `No invoice found for invoice id ${invoiceId}.`;
    const title = invoiceFetched ? 'Warning!' : 'Error';

    function handleClose() {
        setOpen(false);
    }

    function voidInvoice(thisInvoice: Invoice) {
        const { individual, amount, number } = thisInvoice;
        const invSeats = thisInvoice.seats.map((seat) => ({
            class_id: seat.class_id,
            user_id: seat.user_id,
            states: seat.states.map((st) => st.id),
        }));
        const existingInvNotes = thisInvoice.notes || '';
        Client.put(`api/invoices/${invoiceId}`, {
            individual,
            amount,
            number,
            paid_amount: thisInvoice.paid_amount,
            date_issued: thisInvoice.date_issued,
            seats: invSeats,
            notes: `INVOICE VOIDED ON ${new Date().toLocaleString()} | ${existingInvNotes}`,
        }).then(async (resp) => {
            if (resp) {
                const getInvResponse = await Client.get(
                    `api/invoices/${invoiceId}`,
                );
                const updatedInvoice = getInvResponse.data;
                dispatch(
                    invoiceActions.invoiceUpdate({
                        id: invoiceId,
                        changes: {
                            notes: updatedInvoice.notes,
                        },
                    }),
                );
            }
        });
    }

    return (
        <>
            <Tooltip title="Void Invoice">
                <IconButton
                    edge="end"
                    aria-label="voidInvoice"
                    disabled={invoiceFetched?.notes?.includes('VOIDED')}
                    onClick={() => setOpen(true)}
                >
                    <BlockIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                aria-labelledby="void-invoice-dialog-title"
                aria-describedby="void-invoice-dialog-description"
            >
                <DialogTitle id={`void-invoice-${invoiceId}-dialog-title`}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id={`void-invoice-${invoiceId}-dialog-description`}
                    >
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="default"
                        id={`void-invoice-${invoiceId}-dialog-button-cancel`}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (invoiceFetched) {
                                voidInvoice(invoiceFetched);
                                onConfirm();
                            }
                            handleClose();
                        }}
                        color="primary"
                        autoFocus
                        id={`void-invoice-${invoiceId}-dialog-button-confirm`}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

VoidInvoiceDialog.defaultProps = {
    onConfirm: () => {},
};

export default VoidInvoiceDialog;
