import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import EditClient from '../../EditClient';

import { addressSelectors } from '../../../store/entities/address';
import { companySelectors } from '../../../store/entities/company';
import { locationSelectors } from '../../../store/entities/location';
import { studentSelectors } from '../../../store/entities/student';

export interface ClientDetailsProps extends React.HTMLProps<HTMLDivElement> {
    clientId: number;
    clientType: 'Company' | 'Individual' | 'None';
    canEdit?: boolean;
}

export default function ClientDetails({
    clientId,
    clientType,
    canEdit = false,
    ...props
}: ClientDetailsProps) {
    if (!Number.isInteger(clientId) || clientType === 'None') {
        return <div />;
    }

    const thisClient = {
        name: 'Client Not Found',
        attn: '',
        address: '',
        phone: '',
        email: '',
        DL_license: '',
        fl_license_number: '',
        ssn_last_four_digits: '',
    };

    if (clientType === 'Company') {
        const companyFetched = useSelector((state) =>
            companySelectors.selectById(state, clientId),
        );
        const companyLocation = useSelector((state) =>
            locationSelectors.selectById(
                state,
                companyFetched?.location_id || 0,
            ),
        );

        if (companyFetched && companyLocation) {
            const clientAddress = `${companyLocation.address_1}, ${companyLocation.city}, ${companyLocation.state} ${companyLocation.zip_code}`;
            thisClient.name = companyFetched.name;
            thisClient.attn = companyFetched.contact_name;
            thisClient.address = clientAddress;
            thisClient.phone = companyFetched.phone;
            thisClient.email = companyFetched.primary_email;
        }
    } else {
        const userFetched = useSelector((state) =>
            studentSelectors.selectById(state, clientId),
        );

        if (userFetched) {
            const userAddress = useSelector((state) =>
                addressSelectors.selectById(state, userFetched.address_id || 0),
            );
            const clientAddress = userAddress
                ? `${userAddress.address1}, ${userAddress.administrative_area}, ${userAddress.locality} ${userAddress.postal_code}`
                : '';
            thisClient.name = userFetched.full_name;
            thisClient.address = clientAddress;
            thisClient.email = userFetched.email;
            if (userFetched.ssn_last_four_digits) {
                thisClient.ssn_last_four_digits =
                    userFetched.ssn_last_four_digits;
            }
            if (userFetched.DL_license) {
                thisClient.DL_license = userFetched.DL_license;
            }
            if (userFetched.fl_license_number) {
                thisClient.fl_license_number = userFetched.fl_license_number;
            }
        }
    }

    return (
        <div {...props}>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                    {`Client Name: ${thisClient.name}`}
                </Grid>
                <Grid item xs={3}>
                    {clientType === 'Company'
                        ? `Attn: ${thisClient.attn}`
                        : `SSN#: ${thisClient.ssn_last_four_digits}`}
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                    {`Address: ${thisClient.address}`}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                    {canEdit && (
                        <EditClient
                            clientId={clientId}
                            clientType={clientType}
                        />
                    )}
                </Grid>
            </Grid>
            {clientType === 'Company' ? (
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        {`Phone: ${thisClient.phone}`}
                    </Grid>
                    <Grid item xs={3}>
                        {`Email: ${thisClient.email}`}
                    </Grid>
                </Grid>
            ) : (
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                        {`Driver's License #: ${thisClient.DL_license}`}
                    </Grid>
                    <Grid item xs={3}>
                        {`FL License #: ${thisClient.fl_license_number}`}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

ClientDetails.defaultProps = {
    canEdit: false,
};
