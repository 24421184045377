import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Copyright from '../../components/Copyright';
import ClassAdminView from '../../components/ClassView/ClassAdminView';
import Client from '../../client';

import User from '../../store/entities/user/type';
import { actions as classActions } from '../../store/entities/class';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as studentActions } from '../../store/entities/student';
import { actions as trainerActions } from '../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            marginTop: theme.spacing(10),
            fontSize: 24,
            width: '100%',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    user: User;
}

export default function TablePage(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className, user, ...attrs } = props;

    useEffect(() => {
        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        async function loadClasses() {
            const response = await Client.get('/api/classes');
            await dispatch(classActions.classAddMany(response.data));
        }

        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadStudents() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
        }

        async function loadTrainers() {
            const response = await Client.get('/api/trainers');
            await dispatch(trainerActions.trainersAddMany(response.data));
        }

        loadStates();
        loadInvoices();
        loadClasses();
        loadLocations();
        loadStudents();
        loadTrainers();
    }, []);

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Container component="main" maxWidth="lg">
                <div className={styleClasses.title}>Class View</div>
                <ClassAdminView user={user} />
                <Box my={4}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
