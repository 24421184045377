import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Client from '../../client';
import ClassDataTable from '../../components/ClassTable/ClassDataTable';
import Copyright from '../../components/Copyright';

import User from '../../store/entities/user/type';
import { actions as classActions } from '../../store/entities/class';
import { actions as companyActions } from '../../store/entities/company';
import { actions as courseActions } from '../../store/entities/course';
import { actions as invoiceActions } from '../../store/entities/invoice';
import { actions as languageActions } from '../../store/entities/language';
import { actions as locationActions } from '../../store/entities/location';
import { actions as stateActions } from '../../store/entities/state';
import { actions as studentActions } from '../../store/entities/student';
import { actions as trainerActions } from '../../store/entities/trainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        test: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '80px',
            bottom: '70px',
        },
        error: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    user: User;
}

export default function ClassTablePage(props: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const { className, user, ...attrs } = props;

    useEffect(() => {
        async function loadLanguages() {
            const response = await Client.get('/api/languages');
            await dispatch(languageActions.languageAddMany(response.data));
        }

        async function loadStates() {
            const response = await Client.get('/api/states');
            await dispatch(stateActions.statesAddMany(response.data));
        }

        async function loadStudents() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
        }

        async function loadCompanies() {
            const response = await Client.get('/api/companies');
            await dispatch(companyActions.companyAddMany(response.data));
        }

        async function loadCourses() {
            const response = await Client.get('/api/courses');
            await dispatch(courseActions.courseAddMany(response.data));
        }

        async function loadClasses() {
            const response = await Client.get('/api/classes');
            await dispatch(classActions.classAddMany(response.data));
        }

        async function loadTrainers() {
            const response = await Client.get('/api/trainers');
            await dispatch(trainerActions.trainersAddMany(response.data));
        }

        async function loadLocations() {
            const response = await Client.get('/api/locations');
            await dispatch(locationActions.locationAddMany(response.data));
        }

        async function loadInvoices() {
            const response = await Client.get('/api/invoices');
            await dispatch(invoiceActions.invoiceAddMany(response.data));
        }

        loadInvoices();
        loadLanguages();
        loadLocations();
        loadTrainers();
        loadCompanies();
        loadCourses();
        loadClasses();
        loadStates();
        loadStudents();
    }, []);

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <ClassDataTable user={user} />
                </Box>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
