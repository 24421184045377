import User from '../store/entities/user/type';

function verifyAdmin(thisUser: User) {
    const verified = thisUser.is_superadmin === 1;
    const adminWhiteList = ['system system', 'Rachel Bowlin', 'Rebecca Mayhew'];
    const userFullName = `${thisUser.first_name} ${thisUser.last_name}`;

    return verified && adminWhiteList.includes(userFullName);
}

export default verifyAdmin;
