import React from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function ClassTableToolbar() {
    // const dispatch = useDispatch();
    return (
        <Tooltip title="Add New Notification">
            <Link to={{ pathname: '/classform' }}>
                <IconButton>
                    <AddIcon />
                </IconButton>
            </Link>
        </Tooltip>
    );
}
