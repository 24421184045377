import React, { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import TextField from '@material-ui/core/TextField';

interface OwnProps {
    defaultInputValue?: string;
    name: string;
    validate?: { [key: string]: any };
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type Props = OwnProps & ComponentProps<typeof TextField>;

/* eslint-disable react/jsx-props-no-spreading */
const Component: React.FC<Props> = function FormField({
    defaultInputValue,
    name,
    validate,
    onChange,
    ...props
}) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { ref, ...controller } = register(name, validate);

    return (
        <TextField
            error={!!errors[name]}
            inputRef={ref}
            defaultValue={defaultInputValue}
            {...props}
            {...controller}
            inputProps={{ 'aria-label': name }}
            helperText={errors[name]?.message || ' '}
            onChange={onChange}
        />
    );
};
/* eslint-enable react/jsx-props-no-spreading */

Component.defaultProps = {
    defaultInputValue: '',
    validate: {},
    onChange: () => {},
};

export default Component;
