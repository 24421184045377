import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

import Seat from '../../../store/entities/seat/type';
import Client from '../../../client';
import {
    actions as invoiceActions,
    invoiceSelectors,
} from '../../../store/entities/invoice';

const useStyles = makeStyles(() => ({
    root: {},
    container: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export interface Props extends React.HTMLProps<HTMLDivElement> {
    editingSeat: Seat;
    invoiceId: number;
    disableEdit?: boolean;
}

export default function PreviousCertField({
    editingSeat,
    invoiceId,
    disableEdit = false,
}: Props) {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [prevCert, setPrevCert] = useState(
        editingSeat.previous_certification || 'none',
    );
    const invoiceFetched = useSelector((state) =>
        invoiceSelectors.selectById(state, invoiceId),
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPrevCert = event.target.value;
        setPrevCert(newPrevCert);
    };

    function editInvoice() {
        const { individual, amount, number } = invoiceFetched || {};
        const invSeats = invoiceFetched?.seats.map((seat) =>
            editingSeat.user_id === seat.user_id
                ? {
                      class_id: seat.class_id,
                      user_id: seat.user_id,
                      states: seat.states.map((st) => st.id),
                      previous_certification: prevCert,
                  }
                : {
                      class_id: seat.class_id,
                      user_id: seat.user_id,
                      states: seat.states.map((st) => st.id),
                      previous_certification:
                          seat.previous_certification || 'none',
                  },
        );
        Client.put(`api/invoices/${invoiceId}`, {
            individual,
            amount,
            number,
            paid_amount: invoiceFetched?.paid_amount,
            date_issued: invoiceFetched?.date_issued,
            seats: invSeats,
        }).then(async (resp) => {
            if (resp) {
                const getInvResponse = await Client.get(
                    `api/invoices/${invoiceId}`,
                );
                const updatedInvoice = getInvResponse.data;
                dispatch(
                    invoiceActions.invoiceUpdate({
                        id: invoiceId,
                        changes: {
                            seats: updatedInvoice.seats,
                        },
                    }),
                );
            }
        });
    }

    if (isEditing) {
        return (
            <div className={styleClasses.container}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    value={prevCert}
                    onChange={handleChange}
                />
                <Tooltip title="Confirm">
                    <IconButton
                        edge="end"
                        aria-label="confirm"
                        onClick={() => {
                            editInvoice();
                            setIsEditing(false);
                        }}
                    >
                        <CheckCircleIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton
                        edge="end"
                        aria-label="cancel"
                        onClick={() => {
                            setIsEditing(false);
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
    return (
        <div>
            {`${prevCert}`}
            <Tooltip title="Edit">
                <IconButton
                    edge="end"
                    aria-label="edit"
                    disabled={disableEdit}
                    onClick={() => {
                        setIsEditing(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
}

PreviousCertField.defaultProps = {
    disableEdit: false,
};
