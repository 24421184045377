import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ClientDetails from '../SeatsForm/ClientDetails';
import AddCompanyDialog from '../AddCompanyDialog';

import { companySelectors } from '../../store/entities/company';
import { studentSelectors } from '../../store/entities/student';

export interface METAClient {
    type: 'Company' | 'Individual' | 'None';
    id: number;
    label?: string;
}

export interface ClientSearchProps extends React.HTMLProps<HTMLDivElement> {
    clientInfo: METAClient;
    setClientInfo: React.Dispatch<React.SetStateAction<METAClient>>;
}

export default function ClientSearch({
    clientInfo,
    setClientInfo,
    ...props
}: ClientSearchProps) {
    const { className } = props;

    const companies = useSelector(companySelectors.selectAll);
    const students = useSelector(studentSelectors.selectAll);

    const clientSelectorOptions = companies
        .map(
            ({ id, name }) =>
                ({
                    id,
                    label: name,
                    type: 'Company',
                } as METAClient),
        )
        .concat(
            students.map(({ id, full_name }) => ({
                id,
                label: full_name,
                type: 'Individual',
            })),
        );

    return (
        <div className={className}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Autocomplete
                        id="clientAutocomplete"
                        style={{ width: 400 }}
                        options={clientSelectorOptions}
                        getOptionLabel={(option) => {
                            if (!option) {
                                return 'no options available';
                            }
                            return `${option.label} (${option.type})`;
                        }}
                        value={clientSelectorOptions.find(
                            ({ id }) => id === clientInfo.id,
                        )}
                        onChange={(event: any, newValue: METAClient | null) => {
                            let newClient = {
                                type: 'None',
                                id: 0,
                                label: '',
                            } as METAClient;
                            if (newValue) {
                                newClient = { ...newValue };
                            }
                            setClientInfo(newClient);
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label="Clients"
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={1}>
                    <AddCompanyDialog onConfirm={() => {}} />
                </Grid>
            </Grid>
            <ClientDetails
                clientId={clientInfo.id}
                clientType={clientInfo.type}
                canEdit
            />
        </div>
    );
}
