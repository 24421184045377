/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';

import useQuery from '../../utils/useQuery';
import Client from '../../client';
import { addressSelectors } from '../../store/entities/address';
import {
    actions as studentActions,
    studentSelectors,
} from '../../store/entities/student';
import {
    actions as trainerActions,
    trainerSelectors,
} from '../../store/entities/trainer';
import Trainer from '../../store/entities/trainer/type';
import User from '../../store/entities/user/type';
import FormField from '../FormField';
import FormAutocomplete from '../FormAutocomplete';
import AddAddressDialog from '../AddAddressDialog';
import MultiFieldErrorMessage from '../MultiFieldErrorMessage';

import SignatureDialog from '../SignatureDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        alignButtonRight: {
            marginLeft: 'auto',
            width: '50%',
        },
        paper: {
            margin: theme.spacing(1),
            width: '100%',
            maxWidth: 540,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '6em',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
        button: {
            margin: theme.spacing(3, 0, 2, 2),
            width: '50%',
            marginLeft: '25%',
        },
        formItemColumn: {
            width: `calc(50% - ${theme.spacing(4)}px)`,
        },
        formItemFullWidth: {
            marginTop: '2em',
            width: `calc(100% - ${theme.spacing(4)}px)`,
        },
        signatureDialogButton: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
        },
        submit: {
            margin: `0 ${theme.spacing(2)}px`,
        },
        submitContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: `calc(50% - ${theme.spacing(4)}px)`,
        },
        shiftUp: {
            marginTop: '-2em',
            fontSize: 'small',
        },
        sigImage: {
            minHeight: '20px',
            minWidth: '20px',
            border: `2px ${theme.palette.secondary.main} solid`,
            boxSizing: 'border-box',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    trainerId?: number;
    setItemSelected?: React.Dispatch<React.SetStateAction<number>>;
    setEditDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
    oldTrainer?: User;
    onConfirm?: VoidFunction;
    onCancel?: VoidFunction;
    userFilters?: { type: string; fName: string; lName: string };
    setUserFilters?: React.Dispatch<
        React.SetStateAction<{ type: string; fName: string; lName: string }>
    >;
}

export default function TrainerInputForm(props: Props) {
    const styleClasses = useStyles();
    const {
        className,
        trainerId,
        setItemSelected,
        setEditDisabled,
        oldTrainer,
        onConfirm,
        onCancel,
        userFilters,
        setUserFilters,
    } = props;
    const dispatch = useDispatch();
    const form = useForm();
    const {
        control,
        reset,
        watch,
        formState: { isDirty, dirtyFields, errors },
    } = form;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isDuplicate, setIsDuplicate] = React.useState(false);
    const [signature, setSignature] = React.useState(
        oldTrainer?.headshot || '',
    );
    const [signatureError, setSignatureError] = React.useState('');

    const addresses = useSelector(addressSelectors.selectAll);
    const trainers = useSelector(trainerSelectors.selectAll);
    const students = useSelector(studentSelectors.selectAll);
    const query = useQuery();
    const formUserType = watch('userType');
    const queryUserType = query.get('user_type') || 'contract';
    const addressSelectorOptions = addresses.map((address) => ({
        id: address.id,
        label: `${address.address1} ${address.address2} ${address.administrative_area}, ${address.locality}`,
    }));

    function resetForm(resetUserType: string) {
        reset({
            firstName: '',
            lastName: '',
            email: '',
            ssn: '',
            username: '',
            DL_license: '',
            fl_license_number: '',
            password: '',
            userType: resetUserType,
        });
    }

    function getUserType(user: User): string {
        if (user.is_staff_trainer) {
            return 'staff';
        }
        if (user.is_contract_trainer) {
            return 'contract';
        }
        if (user.is_superadmin) {
            return 'METAStaff';
        }
        return 'student';
    }

    useEffect(() => {
        if (setUserFilters && userFilters) {
            const newFilter = {
                ...userFilters,
                type: queryUserType,
            };
            setUserFilters(newFilter);
            resetForm(queryUserType);
        }
        if (oldTrainer) {
            reset({
                firstName: oldTrainer.first_name,
                lastName: oldTrainer.last_name,
                email: oldTrainer.email,
                ssn: `${oldTrainer.ssn_last_four_digits}`.slice(-4),
                username: oldTrainer.username,
                DL_license: oldTrainer.DL_license ?? '',
                fl_license_number: oldTrainer.fl_license_number ?? '',
                password: '',
                userType: getUserType(oldTrainer),
            });
        }
    }, []);

    function isDuplicateUsername(input: string): boolean {
        // students are all users (incl trainers) that are not superadmins
        const duplicateFound = students.find(
            (student: User) =>
                student.username.toLowerCase() === input.toLowerCase() &&
                student.id !== oldTrainer?.id,
        );
        setIsDuplicate(Boolean(duplicateFound));
        return Boolean(duplicateFound);
    }

    function getUsername() {
        if (formUserType === 'student') {
            const alphabet =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const fName = form.getValues('firstName');
            const lName = form.getValues('lastName');
            let generatedUsername = '';
            let i = 0;
            let n = 0;
            while (i < 3 && n < fName.length) {
                if (alphabet.includes(fName[n])) {
                    generatedUsername += fName[n].toUpperCase();
                    i += 1;
                }
                n += 1;
            }
            i = 0;
            n = 0;
            while (i < 3 && n < lName.length) {
                if (alphabet.includes(lName[n])) {
                    generatedUsername += lName[n].toUpperCase();
                    i += 1;
                }
                n += 1;
            }
            generatedUsername += form.getValues('ssn').slice(-4);

            form.setValue('username', generatedUsername);

            return generatedUsername;
        }

        return form.getValues('username');
    }

    async function onSubmit(data: any) {
        setIsLoading(true);
        if (!data.userType) {
            form.setError('userType', {
                type: 'manual',
                message: 'User type is not set.',
            });
            setIsLoading(false);
            return;
        }
        const usernameSubmitted = getUsername();
        const duplicateUsername = isDuplicateUsername(usernameSubmitted);
        if (duplicateUsername) {
            form.setError('firstName', { type: 'manual' });
            form.setError('lastName', { type: 'manual' });
            form.setError('ssn', { type: 'manual' });
            setIsLoading(false);
            return;
        }
        if (
            !signature &&
            (data.userType === 'contract' || data.userType === 'staff')
        ) {
            setSignatureError('Signature required.');
            setIsLoading(false);
            return;
        }
        if (signature) {
            setSignatureError('');
        }
        let userPayload: Partial<User> = {
            first_name: data.firstName,
            last_name: data.lastName,
            username: usernameSubmitted,
        };
        if (data.email) {
            userPayload = {
                ...userPayload,
                email:
                    formUserType === 'student' &&
                    data.email.includes('retail') &&
                    data.email.includes('@metaworldwide.com')
                        ? `retail+${usernameSubmitted}@metaworldwide.com`
                        : data.email,
            };
        }
        if (data.ssn) {
            userPayload = {
                ...userPayload,
                ssn_last_four_digits: data.ssn,
            };
        }
        if (data.DL_license) {
            userPayload = {
                ...userPayload,
                DL_license: data.DL_license,
            };
        }
        if (data.fl_license_number) {
            userPayload = {
                ...userPayload,
                fl_license_number: data.fl_license_number,
            };
        }
        if (data.address_id) {
            userPayload = {
                ...userPayload,
                address_id: data.address_id,
            };
        }

        if (oldTrainer) {
            await Client.put(`/api/users/${oldTrainer.id}`, userPayload).then(
                (resp) => {
                    if (resp.data) {
                        const type = getUserType(oldTrainer);
                        if (type === 'student') {
                            dispatch(
                                studentActions.studentUpdate({
                                    id: oldTrainer.id,
                                    changes: {
                                        ...userPayload,
                                    },
                                }),
                            );
                        } else if (type === 'METAStaff') {
                            // Dispatch for administrators
                        } else {
                            // Dispatch for trainers in future?
                            // Currently uses dispatch for students/non-admin user
                            dispatch(
                                studentActions.studentUpdate({
                                    id: oldTrainer.id,
                                    changes: {
                                        ...userPayload,
                                    },
                                }),
                            );
                        }
                    }
                },
            );
        } else if (data.userType === 'student') {
            if (!data.email) {
                userPayload = {
                    ...userPayload,
                    email: `retail+${usernameSubmitted}@metaworldwide.com`,
                };
            }
            userPayload = {
                ...userPayload,
                username: usernameSubmitted,
                headshot: data.userType,
            };
            await Client.post('/api/users', {
                ...userPayload,
                password: 'password',
            });
        } else if (data.userType === 'METAStaff') {
            userPayload = {
                ...userPayload,
                headshot: data.userType,
                is_superadmin: 1,
            };
            await Client.post('/api/users', {
                ...userPayload,
                password: data.password || 'adminPassword123',
            });
        } else {
            userPayload = {
                ...userPayload,
                headshot: signature,
                is_contract_trainer: data.userType === 'contract' ? 1 : 0,
                is_staff_trainer: data.userType === 'staff' ? 1 : 0,
            };
            await Client.post('/api/trainers', {
                trainerData: {
                    user: {
                        ...userPayload,
                        password: data.password || 'trainerPassword321',
                    },
                },
            });
        }
        if (onConfirm) {
            onConfirm();
        }
        resetForm(data.userType);
        await form.clearErrors();
        setSignatureError('');
        setSignature('');
        setIsLoading(false);
        if (setItemSelected) {
            setItemSelected(0);
        }
        if (setEditDisabled) {
            setEditDisabled(false);
        }
    }

    const handleFileInput = (e: any) => {
        // handle validations
        const target = e?.target as HTMLInputElement;
        const file: Blob | null = target?.files?.[0] || null;
        if (!file) {
            setSignatureError('No file found');
            return;
        }
        if (file?.size > 300000) {
            setSignatureError('File size cannot exceed more than 300KB');
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result;
                if (typeof base64String === 'string') {
                    setSignature(base64String);
                }
            };
        }
    };

    const signatureField = () => (
        <FormControl
            required={formUserType === 'contract' || formUserType === 'staff'}
            error={!!signatureError}
        >
            <FormLabel id="signature-image-label">Signature</FormLabel>
            <div
                style={
                    signature
                        ? {
                              display: 'flex',
                              flexWrap: 'wrap',
                          }
                        : { display: 'block' }
                }
            >
                {signature && (
                    <img
                        src={signature}
                        alt="signature"
                        className={styleClasses.sigImage}
                    />
                )}
                <div
                    className={styleClasses.signatureDialogButton}
                    id="signature-input"
                >
                    <SignatureDialog
                        setSignature={setSignature}
                        titleText="Draw"
                    />
                    <label htmlFor="contained-button-file">
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            style={{
                                display: 'none',
                            }}
                            onChange={handleFileInput}
                        />
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                    {signature && (
                        <Button onClick={() => setSignature('')}>Delete</Button>
                    )}
                </div>
            </div>
            <FormHelperText>{signatureError || ' '}</FormHelperText>
        </FormControl>
    );

    return (
        <div className={clsx(styleClasses.root, className)}>
            <Paper className={styleClasses.paper}>
                {/* eslint-disable  react/jsx-props-no-spreading */}
                <FormProvider {...form}>
                    <form
                        className={styleClasses.form}
                        onSubmit={form.handleSubmit(onSubmit)}
                        onChange={() => {
                            form.clearErrors(['firstName', 'lastName']);
                            if (dirtyFields.firstName && dirtyFields.lastName) {
                                form.trigger();
                            }
                        }}
                    >
                        <div className={styleClasses.form}>
                            <div className={styleClasses.formItemFullWidth}>
                                <FormControl fullWidth component="fieldset">
                                    <FormLabel component="legend">
                                        Select a User Type
                                    </FormLabel>
                                    <Controller
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        name="userType"
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                onChange={(e) => {
                                                    const choice =
                                                        e.target.value;
                                                    if (
                                                        setUserFilters &&
                                                        userFilters
                                                    ) {
                                                        const newFilter = {
                                                            ...userFilters,
                                                            type: choice,
                                                        };
                                                        setUserFilters(
                                                            newFilter,
                                                        );
                                                    }
                                                    form.setValue(
                                                        'userType',
                                                        choice,
                                                    );
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="student"
                                                    control={<Radio />}
                                                    label="Student"
                                                />
                                                <FormControlLabel
                                                    value="contract"
                                                    control={<Radio />}
                                                    label="Contract Trainer"
                                                />
                                                <FormControlLabel
                                                    value="staff"
                                                    control={<Radio />}
                                                    label="Staff Trainer"
                                                />
                                                <FormControlLabel
                                                    value="METAStaff"
                                                    control={<Radio />}
                                                    label="Admin"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    <FormHelperText>
                                        {errors?.userType?.message || ' '}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            <div className={styleClasses.formItemColumn}>
                                <FormField
                                    label="First Name"
                                    name="firstName"
                                    variant="outlined"
                                    margin="normal"
                                    defaultValue={oldTrainer?.first_name || ''}
                                    required
                                    autoFocus
                                    validate={{
                                        required: 'First name required.',
                                    }}
                                    onChange={(e) => {
                                        const fNameInput = e.target.value;
                                        if (setUserFilters && userFilters) {
                                            const newFilter = {
                                                ...userFilters,
                                                fName: fNameInput.toUpperCase(),
                                            };
                                            setUserFilters(newFilter);
                                        }
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className={styleClasses.formItemColumn}>
                                <FormField
                                    label="Last Name"
                                    name="lastName"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    validate={{
                                        required: 'Last name required.',
                                    }}
                                    onChange={(e) => {
                                        const lNameInput = e.target.value;
                                        if (setUserFilters && userFilters) {
                                            const newFilter = {
                                                ...userFilters,
                                                lName: lNameInput.toUpperCase(),
                                            };
                                            setUserFilters(newFilter);
                                        }
                                    }}
                                    fullWidth
                                />
                            </div>
                            {isDuplicate && (
                                <div
                                    className={clsx(
                                        styleClasses.shiftUp,
                                        styleClasses.formItemFullWidth,
                                    )}
                                >
                                    <MultiFieldErrorMessage
                                        message={`The username ${form.getValues(
                                            'username',
                                        )} is already in use.`}
                                    />
                                </div>
                            )}
                            <div className={styleClasses.formItemColumn}>
                                <FormField
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    margin="normal"
                                    required={formUserType !== 'student'}
                                    validate={{
                                        pattern: {
                                            message: 'Invalid email',
                                            value: /^[a-zA-Z0-9_+]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                                        },
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className={styleClasses.formItemColumn}>
                                <FormField
                                    label="SSN"
                                    name="ssn"
                                    variant="outlined"
                                    margin="normal"
                                    required={formUserType !== 'METAStaff'}
                                    fullWidth
                                />
                            </div>
                            {formUserType !== 'student' && (
                                <>
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        <FormField
                                            label="Username"
                                            name="username"
                                            variant="outlined"
                                            margin="normal"
                                            required={
                                                formUserType !== 'student'
                                            }
                                            validate={{
                                                validate: {
                                                    unique: (
                                                        userName: string,
                                                    ) =>
                                                        !isDuplicateUsername(
                                                            userName,
                                                        ) || 'Already in use.',
                                                },
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        <FormField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            margin="normal"
                                            required={
                                                formUserType !== 'student'
                                            }
                                            disabled={!!trainerId}
                                            fullWidth
                                        />
                                    </div>
                                </>
                            )}
                            {formUserType !== 'METAStaff' && (
                                <>
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        <FormField
                                            label="Florida License #"
                                            name="fl_license_number"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        <FormField
                                            label="Driver's License #"
                                            name="DL_license"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        <FormAutocomplete
                                            options={addressSelectorOptions}
                                            label="Choose an address"
                                            control={control}
                                            name="address_id"
                                            width="100%"
                                            fieldSize="medium"
                                            margin="normal"
                                        />
                                        <div
                                            className={
                                                styleClasses.alignButtonRight
                                            }
                                        >
                                            <AddAddressDialog
                                                mode="address"
                                                openDialogButtonText="Add Address"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {formUserType !== 'METAStaff' &&
                                formUserType !== 'student' && (
                                    <div
                                        className={styleClasses.formItemColumn}
                                    >
                                        {signatureField()}
                                    </div>
                                )}
                        </div>
                        {!isLoading && (
                            <div className={styleClasses.submitContainer}>
                                <div className={styleClasses.submit}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={styleClasses.button}
                                        disabled={!isDirty}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div className={styleClasses.submit}>
                                    <Tooltip title="Cancel editing trainer">
                                        <Button
                                            type="reset"
                                            variant="outlined"
                                            color="secondary"
                                            className={styleClasses.button}
                                            onClick={() => {
                                                form.reset({});
                                                setSignature('');
                                                setSignatureError('');
                                                form.clearErrors();
                                                setIsDuplicate(false);
                                                // extra behavior for editing an existing trainer
                                                if (
                                                    trainerId &&
                                                    !!setItemSelected &&
                                                    !!setEditDisabled
                                                ) {
                                                    setItemSelected(0);
                                                    setEditDisabled(false);
                                                }
                                                if (onCancel) {
                                                    onCancel();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {isLoading && (
                            <div className={styleClasses.submitContainer}>
                                <div className={styleClasses.submit}>
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </form>
                    {/* eslint-enable  react/jsx-props-no-spreading */}
                </FormProvider>
            </Paper>
        </div>
    );
}
/* eslint-enable @typescript-eslint/no-unused-vars */

TrainerInputForm.defaultProps = {
    trainerId: 0,
    setItemSelected: undefined,
    setEditDisabled: undefined,
    oldTrainer: undefined,
    onConfirm: () => {},
    onCancel: () => {},
    userFilters: undefined,
    setUserFilters: undefined,
};
