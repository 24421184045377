import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import Language from './type';

const languageAdapter = createEntityAdapter<Language>();

const slice = createSlice({
    name: 'language',
    initialState: languageAdapter.getInitialState(),
    reducers: {
        languageAddOne: languageAdapter.addOne,
        languageAddMany: languageAdapter.addMany,
        languageUpdate: languageAdapter.updateOne,
        languageRemove: languageAdapter.removeOne,
    },
});

export const languageSelectors = languageAdapter.getSelectors(
    (state: any) => state.languages,
);

export const { actions, reducer } = slice;
