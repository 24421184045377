import React from 'react';
import { useDispatch } from 'react-redux';

import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';

import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

import Client from '../../client';
import User from '../../store/entities/user/type';
import { actions as trainerActions } from '../../store/entities/trainer';

export interface TrainerListItemProps extends React.HTMLProps<HTMLDivElement> {
    trainer: User;
    trainerId: number;
    editDisabled: boolean;
    handleEditClick: (given: string, surname: string, id: number) => void;
}

export default function TrainerListItem(props: TrainerListItemProps) {
    const { trainer, trainerId, editDisabled, handleEditClick } = props;
    const dispatch = useDispatch();
    const inactiveTrainer = Boolean(trainer.deactivated_at);

    function getUserType(user: User) {
        if (user.is_staff_trainer) {
            return 'Staff Trainer';
        }
        if (user.is_contract_trainer) {
            return 'Contract Trainer';
        }
        if (user.is_superadmin) {
            return 'META Admin';
        }
        return 'Student';
    }

    function userDisplayName(user: User) {
        const userType = getUserType(user);
        if (userType === 'Student') {
            return inactiveTrainer
                ? `${user.first_name} ${user.last_name} [${user.username}] (Inactive)`
                : `${user.first_name} ${user.last_name} [${user.username}]`;
        }
        return inactiveTrainer
            ? `${user.first_name} ${user.last_name} (Inactive)`
            : `${user.first_name} ${user.last_name}`;
    }

    return (
        <>
            <ListItemAvatar>
                <Avatar
                    alt={`signature of ${trainer.first_name} ${trainer.last_name}`}
                    src={trainer.headshot}
                    variant="square"
                />
            </ListItemAvatar>
            <ListItemText
                primary={userDisplayName(trainer)}
                secondary={
                    <>
                        <Typography
                            component="span"
                            variant="body1"
                            style={{ color: '#212121', fontWeight: 400 }}
                        >
                            {getUserType(trainer)}
                        </Typography>
                        <Typography component="span" variant="body2">
                            &mdash; Email: {trainer.email}
                        </Typography>
                    </>
                }
            />
            {!inactiveTrainer && (
                <>
                    <Tooltip title="Edit Trainer">
                        <IconButton
                            onClick={() => {
                                handleEditClick(
                                    trainer.first_name,
                                    trainer.last_name,
                                    trainerId,
                                );
                            }}
                            disabled={editDisabled}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <ConfirmDeleteDialog
                        title="Deactivate Trainer"
                        content={`Do you want to deactivate trainer:\n ${trainer.first_name} ${trainer.last_name}?`}
                        onConfirm={async () => {
                            await Client.put(`/api/users/${trainer.id}`, {
                                deactivate: true,
                            }).then(async (resp: any) => {
                                if (resp.data) {
                                    const updatedUser = await Client.get(
                                        `/api/users/${trainer.id}`,
                                    );
                                    dispatch(
                                        trainerActions.trainerUpdate({
                                            id: trainerId,
                                            changes: {
                                                user: updatedUser.data,
                                            },
                                        }),
                                    );
                                }
                            });
                        }}
                    />
                </>
            )}
        </>
    );
}
