import React from 'react';
import { Link } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { Tooltip, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    OpenInNew as OpenInNewIcon,
    SwapVert as SwapVertIcon,
} from '@material-ui/icons';

import DateFilterDisplay, {
    dateFilterList,
    dateFilterLogic,
} from '../../ClassTable/utils/DateFilterDisplay';
import AutocompleteFilterDisplay from './AutocompleteFilterDisplay';
import VoidedFilterDisplay, {
    VoidedFilterList,
    VoidedFilterLogic,
} from './VoidedFilterDisplay';
import DownloadInvoice from '../../DownloadInvoice';
import MoveInvoiceDialog from '../../MoveInvoiceDialog';
import VoidInvoiceDialog from '../../VoidInvoiceDialog';

import User from '../../../store/entities/user/type';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '80px',
            bottom: '70px',
            right: '20px',
            left: '20px',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        containerMR: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
    }),
);

export function getCustomColumns() {
    const styleClasses = useStyles();

    // https://github.com/gregnb/mui-datatables/issues/43#issuecomment-595214127
    const columnDefinitions: MUIDataTableColumnDef[] = [
        {
            name: 'date_filter',
            label: 'Date Filter',
            options: {
                display: 'false',
                filter: true,
                filterType: 'custom',
                filterList: [], // defaults to current week
                customFilterListOptions: {
                    render: dateFilterList,
                },
                filterOptions: {
                    logic: dateFilterLogic,
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <DateFilterDisplay
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={column}
                        />
                    ),
                },
            },
        },
        {
            name: 'dateSort',
            label: 'Date Sorting',
            options: {
                display: 'false',
                sort: false,
                filter: false,
            },
        },
        {
            name: 'classStart',
            label: 'Class Start',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value: string) => {
                    const startDate = new Date(value).toLocaleDateString();
                    return (
                        <div style={{ width: '50px' }}>
                            <strong>{startDate}</strong>
                        </div>
                    );
                },
            },
        },
        {
            name: 'invoiceNumber',
            label: 'Invoice #',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: (v: string) => `Invoice #${v}`,
                },
                filterOptions: {
                    logic: (dataArray, filters: string[]) =>
                        !!filters.length && !dataArray.includes(filters[0]),
                    display: (
                        filterList,
                        onChange,
                        index,
                        column,
                        filterData,
                    ) => (
                        <AutocompleteFilterDisplay
                            filterList={filterList[index]}
                            onChange={onChange}
                            index={index}
                            column={column}
                            label="Invoice Number"
                            listOptions={filterData[index]}
                        />
                    ),
                },
                customBodyRender: (invoiceNumber: string) => (
                    <div style={{ width: '100px' }}>
                        <strong>{invoiceNumber}</strong>
                    </div>
                ),
            },
        },
        {
            name: 'client',
            label: 'Client',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: (v: string) => `Client: ${v}`,
                },
                filterOptions: {
                    logic: (dataArray, filters: string[]) =>
                        !!filters.length && !dataArray.includes(filters[0]),
                    display: (
                        filterList,
                        onChange,
                        index,
                        column,
                        filterData,
                    ) => (
                        <AutocompleteFilterDisplay
                            filterList={filterList[index]}
                            onChange={onChange}
                            index={index}
                            column={column}
                            label="Client"
                            listOptions={filterData[index]}
                        />
                    ),
                },
                customBodyRender: (clientName: string) => (
                    <div style={{ width: '200px' }}>
                        <strong>{clientName}</strong>
                    </div>
                ),
            },
        },
        {
            name: 'invoiceAmounts',
            label: 'Amount',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (invoiceAmounts: {
                    paidAmount: string;
                    totalDue: string;
                }) => (
                    <div style={{ width: '180px' }}>
                        <strong>Invoice total: </strong>
                        {invoiceAmounts.totalDue}
                        <br />
                        <strong>Paid amount: </strong>
                        {invoiceAmounts.paidAmount}
                    </div>
                ),
            },
        },
        {
            name: 'courseName',
            label: 'Course Name',
            options: {
                sort: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: (v: string) => `Course Name: ${v}`,
                },
                filterOptions: {
                    logic: (dataArray, filters: string[]) =>
                        !!filters.length && !dataArray.includes(filters[0]),
                    display: (
                        filterList,
                        onChange,
                        index,
                        column,
                        filterData,
                    ) => (
                        <AutocompleteFilterDisplay
                            filterList={filterList[index]}
                            onChange={onChange}
                            index={index}
                            column={column}
                            label="Course"
                            listOptions={filterData[index]}
                        />
                    ),
                },
                customBodyRender: (courseName: string) => (
                    <div style={{ width: '300px' }}>
                        <strong>{courseName}</strong>
                    </div>
                ),
            },
        },
        {
            name: 'classLocation',
            label: 'Class Locations',
            options: {
                display: 'false',
                filter: true,
                customFilterListOptions: {
                    render: (v: string) => `Class location: ${v}`,
                },
            },
        },
        {
            name: 'issuer',
            label: 'Invoice Issuer',
            options: {
                display: 'false',
                filter: true,
                customFilterListOptions: {
                    render: (v: User) =>
                        `Issued by: ${v.first_name} ${v.last_name}`,
                },
            },
        },
        {
            name: 'paidStatus',
            label: 'Paid Status',
            options: {
                display: 'false',
                filter: true,
                customFilterListOptions: {
                    render: (v: string) => `Paid Status: ${v}`,
                },
            },
        },
        {
            name: 'seatNames',
            label: 'Students',
            options: {
                display: 'false',
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: (v: string) => `Student: ${v}`,
                },
                filterOptions: {
                    logic: (dataArray, filters: string[]) =>
                        !!filters.length && !dataArray.includes(filters[0]),
                    display: (
                        filterList,
                        onChange,
                        index,
                        column,
                        filterData,
                    ) => (
                        <AutocompleteFilterDisplay
                            filterList={filterList[index]}
                            onChange={onChange}
                            index={index}
                            column={column}
                            label="Student"
                            listOptions={filterData[index]}
                        />
                    ),
                },
            },
        },
        {
            name: 'voidedDate',
            label: 'Status',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: VoidedFilterList,
                },
                filterOptions: {
                    logic: VoidedFilterLogic,
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <VoidedFilterDisplay
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={column}
                        />
                    ),
                },
                customBodyRender: (voidedDate: number) =>
                    voidedDate === 0 ? (
                        <div style={{ width: '150px' }}>
                            <strong>Active</strong>
                        </div>
                    ) : (
                        <div style={{ width: '150px' }}>
                            <strong>Voided on: </strong>
                            <br />
                            {new Date(voidedDate).toLocaleDateString()}
                        </div>
                    ),
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (id: number) => (
                    <div
                        className={styleClasses.container}
                        style={{ width: '150px' }}
                    >
                        <Link
                            to={{
                                pathname: '/seatsform',
                                search: `?invoice_id=${id}`,
                            }}
                        >
                            <Tooltip title="View Invoice">
                                <IconButton onClick={() => {}}>
                                    <OpenInNewIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <DownloadInvoice invoiceId={id} />
                        <VoidInvoiceDialog invoiceId={id} />
                        <MoveInvoiceDialog
                            invoiceId={id}
                            buttonRender={<SwapVertIcon />}
                        />
                    </div>
                ),
            },
        },
    ];
    return columnDefinitions;
}
