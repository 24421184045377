import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import DateFnsUtils from '@date-io/date-fns';

import { FormLabel, FormGroup, Button } from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const now = new Date();
const pastMonday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + 1,
);
const thisSunday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + 8,
);
if (!now.getDay()) {
    pastMonday.setDate(pastMonday.getDate() - 7);
    thisSunday.setDate(thisSunday.getDate() - 7);
}
thisSunday.setMinutes(-1);

export const defaultDateFilter = [
    `f:${pastMonday.toLocaleDateString()}`,
    `t:${thisSunday.toLocaleDateString()}`,
];

function parseDateFilter(dateFilter: string): Date {
    return new Date(dateFilter.substring(2));
}

export function dateFilterList(filter: string[]): string[] {
    if (filter[0] && filter[1]) {
        return [
            `From: ${parseDateFilter(filter[0]).toLocaleDateString()}`,
            `To: ${parseDateFilter(filter[1]).toLocaleDateString()}`,
        ];
    }
    if (filter[0] && filter[0][0] === 'f') {
        return [`From: ${parseDateFilter(filter[0]).toLocaleDateString()}`];
    }
    if (filter[0]) {
        return [`To: ${parseDateFilter(filter[0]).toLocaleDateString()}`];
    }
    return [];
}

export function dateFilterLogic(date: string, filters: string[]) {
    if (filters[0] && filters[1]) {
        // setHours method already returns valueOf for date
        return (
            date < `${parseDateFilter(filters[0]).valueOf()}` ||
            date > `${parseDateFilter(filters[1]).setHours(23, 59)}`
        );
    }
    if (filters[0] && filters[0][0] === 'f') {
        return date < `${parseDateFilter(filters[0]).valueOf()}`;
    }
    if (filters[0]) {
        return date > `${parseDateFilter(filters[0]).setHours(23, 59)}`;
    }
    return false;
}

export interface DateFilterDisplayProps
    extends React.HTMLProps<HTMLDivElement> {
    filterList: string[][]; // first array is for all filters, second is for options within each filter
    onChange: any;
    index: number; // use filterList[index] to access filter array for this particular filtering
    column: MUIDataTableColumn;
}

export default function DateFilterDisplay({
    filterList,
    onChange,
    index,
    column,
}: DateFilterDisplayProps) {
    // MUIDataTable incorrectly types filterList as string[] instead of string[][].
    const dateFilters: string[] = filterList[index] as any;

    const datePickers = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-from-inline1"
                label="From"
                value={dateFilters[0] || null}
                onChange={(date) => {
                    const newFilters = [...dateFilters];
                    if (newFilters[0] && newFilters[0][0] === 't') {
                        newFilters[1] = newFilters[0];
                    }
                    newFilters[0] = `f:${new Date(
                        date || '',
                    ).toLocaleDateString()}`;
                    onChange(newFilters, index, column);
                }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                style={{ marginRight: '2%' }}
            />
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-to-inline2"
                label="To"
                value={dateFilters[1] || null}
                onChange={(date) => {
                    if (!date) {
                        if (dateFilters[0] && dateFilters[0][0] === 'f') {
                            onChange([dateFilters[0]], index, column);
                        } else {
                            onChange([], index, column);
                        }
                    } else {
                        const value = `t:${new Date(
                            date.setHours(23, 59),
                        ).toLocaleDateString()}`;
                        const newFilters = [...dateFilters];
                        if (!newFilters[0] || newFilters[0][0] !== 'f') {
                            newFilters[0] = value;
                        } else {
                            newFilters[1] = value;
                        }
                        onChange(newFilters, index, column);
                    }
                }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                style={{ marginRight: '2%' }}
            />
        </MuiPickersUtilsProvider>
    );

    return (
        <div>
            <FormLabel>Date Filter</FormLabel>
            <FormGroup row>
                {datePickers}
                <Button
                    variant="outlined"
                    onClick={() => {
                        onChange(defaultDateFilter, index, column);
                    }}
                    style={{ marginRight: '2%' }}
                >
                    This Week
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onChange([], index, column);
                    }}
                >
                    Clear
                </Button>
            </FormGroup>
        </div>
    );
}
