import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';

import AddressVerificationForm from '../AddressVerificationForm';

interface AddAddressDialogProps extends React.HTMLProps<HTMLDivElement> {
    mode?: 'address' | 'location';
    openDialogButtonText?: string;
}

const AddAddressDialog: React.FunctionComponent<AddAddressDialogProps> = ({
    mode,
    openDialogButtonText,
}) => {
    const [open, setOpen] = React.useState(false);

    function handleClose(): void {
        setOpen(false);
    }

    return (
        <>
            {openDialogButtonText ? (
                <Button
                    onClick={() => setOpen(true)}
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    {openDialogButtonText}
                </Button>
            ) : (
                <Tooltip title="New Address">
                    <IconButton
                        edge="end"
                        aria-label="new-address"
                        size="small"
                        onClick={() => setOpen(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="add-address-dialog-title"
                aria-describedby="add-address-dialog-description"
                maxWidth="lg"
            >
                <DialogContent dividers>
                    <AddressVerificationForm
                        mode={mode}
                        dialogClose={() => {
                            handleClose();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

AddAddressDialog.defaultProps = {
    mode: undefined,
    openDialogButtonText: undefined,
};

export default AddAddressDialog;
