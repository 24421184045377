import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AlertModal from '../../AlertModal';
import CancelClassDialog from '../CancelClassDialog';
import FormSessions from '../FormSessions';
import SessionStateList from '../SessionStateList';
import FormField from '../../FormField';
import Client from '../../../client';
import FormPicker from '../../FormPicker';
import StateSelector from '../../StateSelector';
import useQuery from '../../../utils/useQuery';
import verifyAdmin from '../../../utils/verifyAdmin';
import { HybridSession } from '../utils';

import Class from '../../../store/entities/class/type';
import Session from '../../../store/entities/session/type';
import State from '../../../store/entities/state/type';
import User from '../../../store/entities/user/type';
import Accreditation from '../../../store/entities/accreditation/type';
import {
    actions as classActions,
    classSelectors,
} from '../../../store/entities/class';
import { courseSelectors } from '../../../store/entities/course';
import { invoiceSelectors } from '../../../store/entities/invoice';
import {
    actions as locationActions,
    locationSelectors,
} from '../../../store/entities/location';
import { stateSelectors } from '../../../store/entities/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomMargin: {
            'margin-bottom': theme.spacing(2),
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        componentTitle: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        cancel: {
            margin: theme.spacing(3, 2, 2),
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[800],
            },
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {}

export default function ClassForm(props: Props) {
    const { className, ...attrs } = props;
    const styleClasses = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [sessionsInfo, setSessionsInfo] = useState([] as HybridSession[]);
    const [principalTrainer, setPrincipalTrainer] = useState(0);
    const [classAccr, setClassAccr] = useState([] as Accreditation[]);
    const [closedClass, setClosedClass] = useState(false);

    const courses = useSelector(courseSelectors.selectAll);
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const locationList = useSelector(locationSelectors.selectAll);
    const stateList = useSelector(stateSelectors.selectAll);
    const courseSelectorOptions = courses.map((course) => ({
        id: course.id,
        label: course.abbreviations,
    }));
    const stateSelectorOptions = stateList
        .filter(({ is_state }) => is_state)
        .map((s) => ({
            label: s.name,
            id: s.abbreviation,
        }));

    const form = useForm();

    const classId = parseInt(query.get('class_id') || '0', 10);
    const classFetched = useSelector((state) =>
        classSelectors.selectById(state, classId),
    );
    const classInvoices = invoices.filter(
        (inv) => inv.seats?.[0]?.class_id === classId,
    );

    useEffect(() => {
        function loadClassToEdit() {
            if (classFetched) {
                form.reset({
                    // course_id: added to form picker default value,
                    max_seats: classFetched.max_seats,
                });
                const oldSessions = classFetched.sessions.map((session) => ({
                    location_id: session.location_id,
                    date_start: new Date(session.date_start || ''),
                    date_end: new Date(session.date_end || ''),
                    trainers: session.trainers.map((t) => t),
                    location: session.location,
                    location_confirmed: session.location_confirmed ?? false,
                }));
                const webinar = oldSessions[0].location.addressee
                    .toUpperCase()
                    .includes('ZOOM.US');
                const oldAccreds = classFetched.class_accreditations?.map(
                    (accr) =>
                        ({
                            state_id: accr.state_id,
                            code: accr.code,
                        } as Accreditation),
                );
                const newSessionsInfo: HybridSession[] = oldSessions.map(
                    (sessInf) => ({
                        webinar,
                        webinarUrl: webinar ? sessInf.location.addressee : '',
                        webinarMeetingId: webinar
                            ? sessInf.location.address_1
                            : '',
                        webinarPasscode: webinar
                            ? sessInf.location.address_2
                            : '',
                        session: sessInf,
                    }),
                );
                setClassAccr(oldAccreds || []);
                setSessionsInfo(newSessionsInfo);
                setPrincipalTrainer(classFetched.principal_trainer_id);
                setClosedClass(!classFetched.open_class);
            }
        }

        loadClassToEdit();
    }, []);

    const classStates = classAccr.map(({ state_id }) => {
        const stateFound = stateList.find(({ id }) => id === state_id);
        let result: State;
        if (stateFound) {
            result = stateFound;
        } else {
            result = {
                id: 0,
                name: 'State not found',
                abbreviation: 'SNF',
                is_state: true,
                is_territory: false,
            };
        }
        return result;
    });

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setClosedClass(e.target.checked);
    };

    function triggerAlert(errorText: string) {
        setAlertText(errorText);
        setAlertOpen(true);
    }

    function newClassAccreds(
        incomingAccreds: Accreditation[],
        existingAccreds: Accreditation[],
    ): Accreditation[] {
        const newAccreds: Accreditation[] = incomingAccreds.map((accr) => ({
            ...accr,
            class_id: classFetched?.id,
        }));

        const newAccredStateIds = newAccreds.map((accr) => accr.state_id);

        existingAccreds.forEach((accr) => {
            if (!newAccredStateIds.includes(accr.state_id)) {
                newAccreds.push({
                    state_id: accr.state_id,
                    code: accr.code,
                    class_id: accr.class_id,
                    delete: true,
                });
            }
        });

        return newAccreds;
    }

    function updateClass(classData: Class) {
        let changes: Partial<Class> = {
            max_seats: classData.max_seats,
            open_class: classData.open_class ?? false,
            principal_trainer_id: classData.principal_trainer_id,
            class_accreditations: classData.class_accreditations?.map(
                (accr: Accreditation) => ({
                    state_id: accr.state_id,
                    code: accr.code,
                    class_id: accr.class_id,
                }),
            ),
            sessions: classData.sessions.map((sess: Session) => ({
                id: sess.id,
                location_id: sess.location_id,
                date_end: new Date(sess.date_end),
                date_start: new Date(sess.date_start),
                trainers: sess.trainers,
                location: sess.location,
                location_confirmed: sess.location_confirmed ?? false,
            })),
        };

        if (classData.canceled) {
            changes = {
                ...changes,
                canceled: classData.canceled,
                canceled_by: classData.canceled_by,
            };
        }

        dispatch(
            classActions.classUpdate({
                id: classId,
                changes,
            }),
        );
    }

    async function cancelClass() {
        if (classFetched) {
            const sessionPayload: any = classFetched.sessions.map(
                (s: Session) => ({
                    // need to send UTC values of date
                    end_date: format(
                        new Date(
                            new Date(s.date_end).toUTCString().slice(0, -4),
                        ),
                        'y-MM-dd HH:mm:ss',
                    ),
                    start_date: format(
                        new Date(
                            new Date(s.date_start).toUTCString().slice(0, -4),
                        ),
                        'y-MM-dd HH:mm:ss',
                    ),
                    location_id: s.location_id,
                    trainers: s.trainers.map((t) => ({
                        trainer_id: t.id,
                    })),
                    location_confirmed: s.location_confirmed ?? false,
                }),
            );

            const classPayload = {
                class: {
                    canceled: true,
                    principal_trainer_id: classFetched.principal_trainer_id,
                    max_seats: classFetched.max_seats,
                    open_class: classFetched.open_class || false,
                    course_id: classFetched.course_id,
                    schedule: sessionPayload,
                },
            };

            const userResponse = await Client.get('/api/user');
            const userId: number = userResponse.data.id;

            await Client.put(`/api/classes/${classId}`, classPayload).then(
                (resp) => {
                    if (resp.data && resp.data.canceled) {
                        const classLocations: (string | undefined)[] =
                            resp.data.sessions.map(
                                (s: Session) =>
                                    locationList.find(
                                        ({ id }) => id === s.location_id,
                                    )?.state,
                            );
                        const stateIds: number[] =
                            resp.data.class_accreditations.map(
                                (accr: Accreditation) => accr.state_id,
                            );
                        const schedulerPayload = {
                            class_id: classId,
                            user_id: userId,
                            newStates: [] as number[],
                            oldStates: [] as number[],
                            // No notifs for Colorado unless teaching in CO (id: 6)
                            removedStates: classLocations.includes('CO')
                                ? stateIds
                                : stateIds.filter((id) => id !== 6),
                        };

                        try {
                            axios.post(
                                `${window.location.origin}/scheduler`,
                                schedulerPayload,
                            );
                        } catch (error) {
                            triggerAlert(
                                'Failed to schedule cancel notifications.',
                            );
                            throw new Error(
                                error instanceof Error
                                    ? error.message
                                    : `${error}`,
                            );
                        } finally {
                            updateClass(resp.data);
                        }
                    }
                },
            );
            setSessionsInfo([]);
            setClassAccr([]);
            setPrincipalTrainer(0);
            await form.reset({});
            await history.push('/classes');
        }
    }

    function cancelButton(): JSX.Element {
        // Disabled by default unless editing an existing class;
        const editing = Boolean(classFetched);
        const title = `Canceling class ID: ${classId}`;
        const content = classInvoices.length
            ? `Warning! This action will remove the class from the
            current class list and issue any appropriate cancel notifications.
            Please chose how you would like to resolve the invoices for
            this class, which are listed below.`
            : `Warning! This action will remove the class from the
            current class list and issue any appropriate cancel notifications.`;
        return (
            <CancelClassDialog
                title={title}
                content={content}
                invoiceList={classInvoices}
                className={styleClasses.cancel}
                disabled={!editing}
                onConfirm={() => {
                    cancelClass();
                }}
            />
        );
    }

    function checkChanges(
        newClass: Class,
        oldClass: Class,
    ): { change: boolean; reason: string } {
        const noChange = {
            change: false,
            reason: 'no change',
        };
        const newSessions = [...newClass.sessions];
        const oldSessions = [...oldClass.sessions];

        // Check if different session lengths
        if (newSessions.length !== oldSessions.length) {
            return { change: true, reason: 'different sessions' };
        }
        // Check if new trainers added, disregard removal of trainers
        const oldTrainerIds: Set<number> = new Set();
        const newTrainerIds: Set<number> = new Set();
        oldSessions.forEach((s) =>
            s.trainers.forEach((t) => oldTrainerIds.add(t.id)),
        );
        newSessions.forEach((s) =>
            s.trainers.forEach((t) => newTrainerIds.add(t.id)),
        );
        const newTrainer = Array.from(newTrainerIds)
            .map((newTrainerId) => oldTrainerIds.has(newTrainerId))
            .reduce((previous, current) => previous && current, true);
        if (!newTrainer) {
            return { change: true, reason: 'new trainer(s)' };
        }
        // Check if all locations match
        const locMatch = newSessions
            .map(
                (nSession, i) =>
                    nSession.location_id === oldSessions[i].location_id,
            )
            .reduce((previous, current) => previous && current, true);
        if (!locMatch) {
            return { change: true, reason: 'location change' };
        }
        // check if all session dates match
        const dateMatch = newSessions
            .map(
                (nSession, i) =>
                    new Date(nSession.date_start).valueOf() ===
                        new Date(oldSessions[i].date_start).valueOf() &&
                    new Date(nSession.date_end).valueOf() ===
                        new Date(oldSessions[i].date_end).valueOf(),
            )
            .reduce((previous, current) => previous && current, true);
        if (!dateMatch) {
            return { change: true, reason: 'date change' };
        }
        // All checks passed
        return noChange;
    }

    function splitStates(
        newClass: Class,
        oldClass: Class,
    ): { new: number[]; old: number[]; removed: number[] } {
        const states = {
            new: [] as number[],
            old: [] as number[],
            removed: [] as number[],
        };
        const classLocations = newClass.sessions.map(
            (s) => locationList.find(({ id }) => id === s.location_id)?.state,
        );
        const oldClassStates =
            oldClass.class_accreditations?.map((accr) => accr.state_id) || [];
        const newClassStates =
            newClass.class_accreditations?.map((accr) => accr.state_id) || [];
        // For states in new class, check if its an old state or new state
        newClassStates.forEach((newStateId) =>
            oldClassStates.includes(newStateId)
                ? states.old.push(newStateId)
                : states.new.push(newStateId),
        );
        // For states in old class, check if its in new class or removed
        oldClassStates.forEach((oldStateId) => {
            if (!newClassStates.includes(oldStateId)) {
                states.removed.push(oldStateId);
            }
        });

        // No notifs for Colorado unless teaching in CO (id: 6)
        // So if classLoc does not have 'CO' -> filter out all instances of CO
        if (!classLocations.includes('CO')) {
            const filteredStates = {
                new: states.new.filter((id) => id !== 6),
                old: states.old.filter((id) => id !== 6),
                removed: states.removed.filter((id) => id !== 6),
            };
            return filteredStates;
        }
        return states;
    }

    function confirmButton(): JSX.Element {
        const disabled = !principalTrainer || !sessionsInfo.length;
        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styleClasses.submit}
                disabled={disabled}
            >
                Submit
            </Button>
        );
    }

    function timeRequirement(
        sess: Session[],
        accr: Accreditation[],
        newClass: boolean = false,
    ): string {
        let errorText = '';

        const daysToClassStart = sess.map(
            ({ date_start }) =>
                (new Date(date_start).valueOf() - Date.now()) /
                (24 * 60 * 60 * 1000),
        );
        const stateAbbrevs = accr.map(
            ({ state_id }) =>
                stateList.find(({ id }) => id === state_id)?.abbreviation,
        );

        // META time rules
        // No classes allowed in less than X days for the following:
        // case 'NE': (min = 5 days)
        // case 'MO': (min = 2 days)
        // case 'TX': (min = 3 days)
        // case 'IN': (min = 30 days)
        if (daysToClassStart.some((val) => val < 1) && newClass) {
            // No new classes allowed in less than 24 hours (Maybe KS?)
            errorText = 'Classes are not allowed to start within 24 hours';
        } else if (
            stateAbbrevs.includes('MO') &&
            daysToClassStart.some((val) => val < 2)
        ) {
            // No new MO classes allowed in less than 2 days
            errorText =
                'Missouri classes are not allowed to start within 2 days';
        } else if (
            stateAbbrevs.includes('TX') &&
            daysToClassStart.some((val) => val < 3)
        ) {
            // No new TX classes allowed in less than 3 days
            errorText = 'Texas classes are not allowed to start within 3 days';
        } else if (
            stateAbbrevs.includes('NE') &&
            daysToClassStart.some((val) => val < 5)
        ) {
            // No new NE classes allowed in less than 5 days
            errorText =
                'Nebraska classes are not allowed to start within 5 days';
        } else if (
            stateAbbrevs.includes('IN') &&
            daysToClassStart.some((val) => val < 30)
        ) {
            // No new IN classes allowed in less than 30 days
            errorText =
                'Indiana classes are not allowed to start within 30 days';
        }

        return errorText;
    }

    function findWebinarLocation() {
        // Webinar/hybrid classes need their location posted to db before class creation.
        // must wait for database confirmation that location was created for class to submit
        let payload = {
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zip_code: '',
            addressee: '',
        };
        const hybridSess = sessionsInfo[0];
        // only trigger if new class and its a webinar.
        if (hybridSess.webinar && !classFetched) {
            payload = {
                addressee: hybridSess.webinarUrl,
                address_1: hybridSess.webinarMeetingId,
                address_2: hybridSess.webinarPasscode,
                city: hybridSess.webinarMeetingId,
                state: hybridSess.webinarUrl.toUpperCase().includes('ZOOM.US')
                    ? 'www.zoom.us'
                    : hybridSess.webinarUrl,
                zip_code: '00000',
            };
        }

        return payload;
    }

    async function onSubmit(data: any) {
        if (classId && !classFetched) {
            triggerAlert(`Class ${classId} not found.`);
            return;
        }

        const userResponse: User = (await Client.get('/api/user')).data;
        const userId = userResponse.id;
        const override = verifyAdmin(userResponse);

        if (classFetched) {
            const accredPayload = newClassAccreds(
                classAccr,
                classFetched.class_accreditations || [],
            );

            // Need to throw error when MO, TX, NE, IN are added as new states and class starts within their time constraints
            // Note: splitStates will filter out CO if class location not in CO
            const accredStateIds = splitStates(
                {
                    ...data,
                    sessions: sessionsInfo.map(({ session }) => session),
                    class_accreditations: accredPayload,
                },
                classFetched,
            );
            const newAccreds = accredStateIds.new.map(
                (id) => ({ state_id: id, code: '' } as Accreditation),
            );
            const errorTxt = timeRequirement(
                sessionsInfo.map(({ session }) => session),
                newAccreds,
            );

            if (errorTxt.length && !override) {
                triggerAlert(errorTxt);
                throw new Error(errorTxt);
            }

            const webinarLocation = findWebinarLocation();

            if (webinarLocation.address_1) {
                Client.post('/api/locations', webinarLocation).then(
                    async (locResp) => {
                        const sessionPayload = sessionsInfo.map(
                            ({ session }) => ({
                                // need to send UTC values of date
                                end_date: format(
                                    new Date(
                                        session.date_end
                                            .toUTCString()
                                            .slice(0, -4),
                                    ),
                                    'y-MM-dd HH:mm:ss',
                                ),
                                start_date: format(
                                    new Date(
                                        session.date_start
                                            .toUTCString()
                                            .slice(0, -4),
                                    ),
                                    'y-MM-dd HH:mm:ss',
                                ),
                                location_id:
                                    session.location_id ?? locResp.data.id,
                                trainers: session.trainers.map((t) => ({
                                    trainer_id: t.id,
                                })),
                                location_confirmed: session.location_confirmed,
                            }),
                        );

                        const classPayload = {
                            class: {
                                ...data,
                                canceled: false,
                                principal_trainer_id: principalTrainer,
                                open_class: !closedClass,
                                accreditations: accredPayload,
                                schedule: sessionPayload,
                            },
                        };

                        Client.put(
                            `/api/classes/${classId}`,
                            classPayload,
                        ).then(async (resp) => {
                            if (resp.data) {
                                const changes = checkChanges(
                                    resp.data,
                                    classFetched,
                                );
                                // splitStates() filters out CO notifications if warranted
                                const states = splitStates(
                                    resp.data,
                                    classFetched,
                                );
                                const schedulerPayload = {
                                    class_id: classId,
                                    user_id: userId,
                                    newStates: states.new,
                                    oldStates: [] as number[],
                                    removedStates: states.removed,
                                };
                                // If notif warrants changes, send old states scheduler
                                if (changes.change) {
                                    schedulerPayload.oldStates = states.old;
                                }
                                try {
                                    axios.post(
                                        `${window.location.origin}/scheduler`,
                                        schedulerPayload,
                                    );
                                } catch (error) {
                                    triggerAlert(
                                        'Failed to schedule notifications.',
                                    );
                                    throw new Error(
                                        error instanceof Error
                                            ? error.message
                                            : `${error}`,
                                    );
                                } finally {
                                    updateClass(resp.data);
                                }
                            }
                        });

                        const locationInfo = await Client.get(
                            `/api/locations/${locResp.data.id}`,
                        );
                        dispatch(
                            locationActions.locationAddOne(locationInfo.data),
                        );
                    },
                );

                setSessionsInfo([]);
                setClassAccr([]);
                setPrincipalTrainer(0);
                await form.reset({});
                await history.push('/classes');
                return;
            }

            const sessionPayload = sessionsInfo.map(({ session }) => ({
                // need to send UTC values of date
                end_date: format(
                    new Date(session.date_end.toUTCString().slice(0, -4)),
                    'y-MM-dd HH:mm:ss',
                ),
                start_date: format(
                    new Date(session.date_start.toUTCString().slice(0, -4)),
                    'y-MM-dd HH:mm:ss',
                ),
                location_id: session.location_id,
                trainers: session.trainers.map((t) => ({
                    trainer_id: t.id,
                })),
                location_confirmed: session.location_confirmed,
            }));

            const classPayload = {
                class: {
                    ...data,
                    canceled: false,
                    principal_trainer_id: principalTrainer,
                    open_class: !closedClass,
                    accreditations: accredPayload,
                    schedule: sessionPayload,
                },
            };

            await Client.put(`/api/classes/${classId}`, classPayload).then(
                (resp) => {
                    if (resp.data) {
                        const changes = checkChanges(resp.data, classFetched);
                        // splitStates() filters out CO notifications if warranted
                        const states = splitStates(resp.data, classFetched);
                        const schedulerPayload = {
                            class_id: classId,
                            user_id: userId,
                            newStates: states.new,
                            oldStates: [] as number[],
                            removedStates: states.removed,
                        };
                        // If notif warrants changes, send old states scheduler
                        if (changes.change) {
                            schedulerPayload.oldStates = states.old;
                        }
                        try {
                            axios.post(
                                `${window.location.origin}/scheduler`,
                                schedulerPayload,
                            );
                        } catch (error) {
                            triggerAlert('Failed to schedule notifications.');
                            throw new Error(
                                error instanceof Error
                                    ? error.message
                                    : `${error}`,
                            );
                        } finally {
                            updateClass(resp.data);
                        }
                    }
                },
            );

            setSessionsInfo([]);
            setClassAccr([]);
            setPrincipalTrainer(0);
            await form.reset({});
            await history.push('/classes');
            return;
        }

        const errorTxt = timeRequirement(
            sessionsInfo.map(({ session }) => session),
            classAccr,
            true,
        );

        if (errorTxt.length && !override) {
            triggerAlert(errorTxt);
            throw new Error(errorTxt);
        }

        const webinarLocation = await findWebinarLocation();

        if (webinarLocation.address_1) {
            Client.post('/api/locations', webinarLocation).then(
                async (locResp) => {
                    const sessionPayload = sessionsInfo.map(({ session }) => ({
                        // need to send UTC values of date
                        end_date: format(
                            new Date(
                                session.date_end.toUTCString().slice(0, -4),
                            ),
                            'y-MM-dd HH:mm:ss',
                        ),
                        start_date: format(
                            new Date(
                                session.date_start.toUTCString().slice(0, -4),
                            ),
                            'y-MM-dd HH:mm:ss',
                        ),
                        location_id: session.location_id ?? locResp.data.id,
                        trainers: session.trainers.map((t) => ({
                            trainer_id: t.id,
                        })),
                        location_confirmed: session.location_confirmed,
                    }));

                    const classPayload = {
                        class: {
                            ...data,
                            canceled: false,
                            principal_trainer_id: principalTrainer,
                            open_class: !closedClass,
                            accreditations: classAccr,
                            schedule: sessionPayload,
                        },
                    };

                    Client.post('/api/classes', classPayload).then(
                        async (resp) => {
                            if (resp.data) {
                                const newClassId: number = resp.data.id;
                                const newClass: Class = (
                                    await Client.get(
                                        `/api/classes/${newClassId}`,
                                    )
                                ).data;
                                const newStates =
                                    newClass.class_accreditations?.map(
                                        (accr) => accr.state_id,
                                    );
                                const newClassLocations: (
                                    | string
                                    | undefined
                                )[] = newClass.sessions.map(
                                    (s: Session) =>
                                        locationList.find(
                                            ({ id }) => id === s.location_id,
                                        )?.state,
                                );
                                const schedulerPayload = {
                                    class_id: newClassId,
                                    user_id: userId,
                                    // No notifs for Colorado unless teaching in CO (id: 6)
                                    newStates: newClassLocations.includes('CO')
                                        ? newStates
                                        : newStates?.filter((id) => id !== 6),
                                    oldStates: [] as number[],
                                    removedStates: [] as number[],
                                    scheduleOverride: override,
                                };
                                try {
                                    axios.post(
                                        `${window.location.origin}/scheduler`,
                                        schedulerPayload,
                                    );
                                } catch (error) {
                                    triggerAlert(
                                        'Failed to schedule initial notifications.',
                                    );
                                    throw new Error(
                                        error instanceof Error
                                            ? error.message
                                            : `${error}`,
                                    );
                                }
                            }
                        },
                    );

                    const locationInfo = await Client.get(
                        `/api/locations/${locResp.data.id}`,
                    );
                    dispatch(locationActions.locationAddOne(locationInfo.data));
                },
            );

            setSessionsInfo([]);
            setClassAccr([]);
            setPrincipalTrainer(0);
            await form.reset({});
            await history.push('/classes');
            return;
        }

        const sessionPayload = sessionsInfo.map(({ session }) => ({
            // need to send UTC values of date
            end_date: format(
                new Date(session.date_end.toUTCString().slice(0, -4)),
                'y-MM-dd HH:mm:ss',
            ),
            start_date: format(
                new Date(session.date_start.toUTCString().slice(0, -4)),
                'y-MM-dd HH:mm:ss',
            ),
            location_id: session.location_id,
            trainers: session.trainers.map((t) => ({
                trainer_id: t.id,
            })),
            location_confirmed: session.location_confirmed,
        }));

        const classPayload = {
            class: {
                ...data,
                canceled: false,
                principal_trainer_id: principalTrainer,
                open_class: !closedClass,
                accreditations: classAccr,
                schedule: sessionPayload,
            },
        };

        Client.post('/api/classes', classPayload).then(async (resp) => {
            if (resp.data) {
                const newClassId: number = resp.data.id;
                const newClass: Class = (
                    await Client.get(`/api/classes/${newClassId}`)
                ).data;
                const newStates = newClass.class_accreditations?.map(
                    (accr) => accr.state_id,
                );
                const newClassLocations: (string | undefined)[] =
                    newClass.sessions.map(
                        (s: Session) =>
                            locationList.find(({ id }) => id === s.location_id)
                                ?.state,
                    );
                const schedulerPayload = {
                    class_id: newClassId,
                    user_id: userId,
                    // No notifs for Colorado unless teaching in CO (id: 6)
                    newStates: newClassLocations.includes('CO')
                        ? newStates
                        : newStates?.filter((id) => id !== 6),
                    oldStates: [] as number[],
                    removedStates: [] as number[],
                    scheduleOverride: override,
                };
                try {
                    axios.post(
                        `${window.location.origin}/scheduler`,
                        schedulerPayload,
                    );
                } catch (error) {
                    triggerAlert('Failed to schedule initial notifications.');
                    throw new Error(
                        error instanceof Error ? error.message : `${error}`,
                    );
                }
            }
        });

        setSessionsInfo([]);
        setClassAccr([]);
        setPrincipalTrainer(0);
        await form.reset({});
        await history.push('/classes');
    }

    return (
        <div {...attrs} className={className}>
            <AlertModal
                open={alertOpen}
                setOpen={setAlertOpen}
                dialogContent={alertText}
            />
            <FormProvider {...form}>
                <form
                    className={styleClasses.form}
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Grid item xs={2}>
                            <FormPicker
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                id="course-select-input"
                                name="course_id"
                                label="Select a Course"
                                choices={courseSelectorOptions}
                                defaultInputValue={classFetched?.course_id}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={closedClass}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                }
                                label="Closed Class"
                                name="closed"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="class-max_seats-input"
                                name="max_seats"
                                label="Number of seats"
                                defaultValue={25}
                                autoComplete="auto"
                                validate={{
                                    valueAsNumber: true,
                                    validate: {
                                        isNumber: (value: any) =>
                                            Number.isInteger(value),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSessions
                                principalTrainer={principalTrainer}
                                setPrincipalTrainer={setPrincipalTrainer}
                                sessionsInfo={sessionsInfo}
                                setSessionsInfo={setSessionsInfo}
                            />
                        </Grid>
                    </Grid>
                    {!!sessionsInfo.length && (
                        <Paper>
                            <div className={styleClasses.titleContainer}>
                                <Typography
                                    variant="h6"
                                    className={styleClasses.componentTitle}
                                >
                                    Choose States to Notify
                                </Typography>
                            </div>
                            <Divider className={styleClasses.bottomMargin} />
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <SessionStateList
                                        classAccr={classAccr}
                                        setClassAccr={setClassAccr}
                                        classId={classId}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <StateSelector
                                        options={stateSelectorOptions}
                                        label="Select states to notify"
                                        makeOptionLabel={(option) => option.id}
                                        makeOptionDisabled={(option) =>
                                            classStates.some(
                                                (s) =>
                                                    s.abbreviation ===
                                                    option.id,
                                            )
                                        }
                                        values={Object.values(classStates).map(
                                            (s) => ({
                                                label: s.name,
                                                id: s.abbreviation,
                                            }),
                                        )}
                                        updateData={(stateData: any) => {
                                            const stateFound = stateList.find(
                                                ({ abbreviation }) =>
                                                    abbreviation ===
                                                    stateData.abbreviation,
                                            );
                                            const newAccreditation = {
                                                code: '',
                                                state_id: stateFound?.id || 0,
                                            };
                                            setClassAccr([
                                                ...classAccr,
                                                newAccreditation,
                                            ]);
                                        }}
                                        placeholderText=""
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                    <Grid container justifyContent="center">
                        <Grid item xs={10}>
                            {confirmButton()}
                        </Grid>
                        <Grid item xs={2}>
                            {cancelButton()}
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
}
