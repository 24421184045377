import React from 'react';
import { useSelector } from 'react-redux';
import MUIDataTable, {
    MUIDataTableOptions,
    MUIDataTableIsRowCheck,
} from 'mui-datatables';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ClassDetails from '../ClassDetails';
import CustomToolbar from '../ClassTableToolbar';
import { getCustomColumns, useStyles } from '../utils';

import Session from '../../../store/entities/session/type';
import User from '../../../store/entities/user/type';
import { classSelectors } from '../../../store/entities/class';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { trainerSelectors } from '../../../store/entities/trainer';
import { studentSelectors } from '../../../store/entities/student';

export interface Props extends React.HTMLProps<HTMLDivElement> {
    user: User;
}

export default function DataTable(props: Props) {
    const { className, user, ...attrs } = props;
    const styleClasses = useStyles();
    const classData = useSelector(classSelectors.selectAll);
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const trainers = useSelector(trainerSelectors.selectAll);
    const students = useSelector(studentSelectors.selectAll);

    function getTrainerNames(
        sessions: Session[],
        principalId: number,
    ): string[] {
        const trainerIds = new Set<number>([principalId]);
        const trainerNames: string[] = [];

        sessions.forEach((s: any) => {
            s.trainers?.forEach((t: any) => {
                trainerIds.add(t.id);
            });
        });

        trainerIds.forEach((id: number) => {
            const trainerFound = trainers.find((t) => t.id === id);
            if (trainerFound && id === principalId) {
                trainerNames.unshift(trainerFound.user.full_name);
            } else if (trainerFound) {
                trainerNames.push(trainerFound.user.full_name);
            }
        });

        return trainerNames;
    }

    // Classname is supported by MUIIDataTable, but it isn't recognized as a valid property by the current TS definitions
    // so we have to trick the compiler into letting us use className.
    const specialProps = {
        className: styleClasses.root,
    };

    const columns = getCustomColumns(classData, invoices, students, user);

    const options: MUIDataTableOptions = {
        filter: true,
        filterType: 'dropdown',
        sortOrder: {
            name: 'dateSort',
            direction: 'asc',
        },
        print: false,
        search: false,
        download: false,
        viewColumns: false,
        responsive: 'vertical',
        selectableRowsHeader: false,
        selectableRows: 'none',
        expandableRows: true,
        expandableRowsOnClick: false,
        isRowExpandable: (
            dataIndex: number,
            expandedRows: MUIDataTableIsRowCheck | undefined,
        ) =>
            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            !!expandedRows &&
            (expandedRows.data.length < 4 ||
                expandedRows.data.filter((d) => d.dataIndex === dataIndex)
                    .length !== 0),
        rowsExpanded: [],
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <ClassDetails
                            classInstance={classData[rowMeta.dataIndex]}
                            trainerNames={getTrainerNames(
                                classData[rowMeta.dataIndex].sessions,
                                classData[rowMeta.dataIndex]
                                    .principal_trainer_id,
                            )}
                        />
                    </TableCell>
                </TableRow>
            );
        },
        // onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {return;},
        customToolbar: () => <CustomToolbar />,
    };

    return (
        <div {...attrs} className={className}>
            <MUIDataTable
                {...specialProps}
                title="META"
                data={classData.map((c) => ({
                    id: c.id,
                    openClass: c.open_class ? 'Open' : 'Closed',
                    classStart: c.sessions[0]?.date_start,
                    courseNumber: c.id,
                    courseName: c.course?.name,
                    certificationStates: '',
                    location: c.sessions[0]?.location?.addressee.includes(
                        'www.zoom',
                    )
                        ? c.sessions[0]?.location?.state
                        : `${c.sessions[0]?.location?.city}, ${c.sessions[0]?.location?.state}`,
                    trainers: getTrainerNames(
                        c.sessions,
                        c.principal_trainer_id,
                    ),
                    dateSort: new Date(c.sessions[0]?.date_start).valueOf(),
                    date_filter: new Date(c.sessions[0]?.date_start).valueOf(),
                    canceled: c.canceled,
                }))}
                columns={columns}
                options={options}
            />
        </div>
    );
}
