import React from 'react';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

interface Props {}

const Component: React.FC<Props> = function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Meta Environmental
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
};

export default Component;
