import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        title: {
            flexGrow: 1,
        },
    }),
);

export interface MenuElement {
    name: string;
    path: string;
    display: boolean;
    component: null | JSX.Element;
}

export interface Props extends React.HTMLProps<HTMLDivElement> {
    menu: MenuElement[];
}

export default function MetaAppBar({ menu, ...props }: Props) {
    const classes = useStyles();
    const { className } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <AppBar position="static">
                <Toolbar>
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            {menu
                                .filter(({ display }) => display)
                                .map((item: MenuElement) => (
                                    <div>
                                        <MenuItem
                                            onClick={handleClose}
                                            component={Link}
                                            to={item.path}
                                        >
                                            <Typography variant="button">
                                                {item.name}
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                ))}
                        </Menu>
                    </div>
                    <Typography variant="h6" className={clsx(classes.title)}>
                        META Environmental Admin Panel
                    </Typography>
                    <Button color="inherit" href="/">
                        <Typography variant="h6">Home</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}
