import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';

import TrainerInputForm from '../../components/TrainerInputForm';
import TrainerList from '../../components/TrainerList';
import Copyright from '../../components/Copyright';

import Client from '../../client';
import User from '../../store/entities/user/type';
import { actions as addressActions } from '../../store/entities/address';
import { actions as adminActions } from '../../store/entities/admin';
import { actions as studentActions } from '../../store/entities/student';
import { actions as trainerActions } from '../../store/entities/trainer';

export default function TrainersPage() {
    const dispatch = useDispatch();
    const [userFilters, setUserFilters] = React.useState({
        type: 'none',
        fName: '',
        lName: '',
    });

    useEffect(() => {
        async function loadAddresses() {
            const response = await Client.get('/api/addresses');
            await dispatch(
                addressActions.addressAddMany(response.data.addresses),
            );
        }

        async function loadTrainers() {
            const response = await Client.get('/api/trainers');
            await dispatch(trainerActions.trainersAddMany(response.data));
        }

        async function loadStudentsAndAdmins() {
            const response = await Client.get('/api/users');
            await dispatch(
                studentActions.studentAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 0,
                    ),
                ),
            );
            await dispatch(
                adminActions.adminAddMany(
                    response.data.filter(
                        (data: User) => data.is_superadmin === 1,
                    ),
                ),
            );
        }

        loadAddresses();
        loadStudentsAndAdmins();
        loadTrainers();
    }, []);

    return (
        <div>
            <Container maxWidth="lg">
                <TrainerInputForm
                    userFilters={userFilters}
                    setUserFilters={setUserFilters}
                />
                <TrainerList userFilters={userFilters} />
                <Copyright />
            </Container>
        </div>
    );
}
