import axios from 'axios';
import cookie from 'js-cookie';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://meta.active.dev';
axios.interceptors.request.use((config) => {
    const token = cookie.get('XSRF-TOKEN');
    /* eslint-disable no-param-reassign */
    config.headers['X-XSRF-TOKEN'] = token;
    config.headers['XSRF-TOKEN'] = token;
    /* eslint-enable no-param-reassign */
    return config;
});

export default axios;
